import React, { useState, useEffect } from 'react';
import cross from '../../../assets/icons/cross-icon.svg';
import main from '../../../assets/images/logo-main.png';
import { config } from '../../../config';
import axiosApi from '../../../core/interceptor/interceptor';
import { messages } from '../../../messages';
import ErrorInput from '../../common/ErrorInput';
import "react-datetime/css/react-datetime.css";
import './Classifieds.css';
import DatePicker from 'react-date-picker';
import TimeKeeper from 'react-timekeeper';
import moment from 'moment';

export default function CreateClassified(props) {

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        display_until: '',
        time: ''
    });

    const [time, setTime] = useState('12:00 am');
    const [showTime, setShowTime] = useState(false);
    const [errors, setErrors] = useState({
        title: '',
        description: '',
        display_until: '',
        time: ''
    });

    useEffect(() => {
        handleValidation();
        //eslint-disable-next-line
    }, [
        formData,
    ])

    const handleFormChange = (e) => {
        setFormData((previousFormData) => ({
            ...previousFormData,
            [e.target.name]: e.target.value,
        }))
    }

    const handleValidation = (e) => {
        let errArray = {};
        let formIsValid = true;
        if (!(formData['title'] || '').trim()) {
            formIsValid = false;
            errArray['title'] = messages && messages.classifiedTitle;
        }
        if (!(formData['description'] || '').trim()) {
            formIsValid = false;
            errArray['description'] = messages && messages.classifiedDescription;
        }
        if (!formData['display_until']) {
            formIsValid = false;
            errArray['display_until'] = messages && messages.classfiedLastDate;
        }
        if (!formData['time']) {
            formIsValid = false;
            errArray['time'] = messages && messages.classfiedLastTime;
        }


        setErrors(errArray);
        return formIsValid;
    }
    const submitHandler = (event) => {
        setFormSubmitted(true);
        event.preventDefault();
        if (handleValidation()) {
            axiosApi.post(`${config.apiUrl}/classifieds`, {
                title: (formData.title || '').trim(),
                description: (formData.description || '').trim(),
                display_until: `${moment(formData.display_until).format('YYYY-MM-DD')} ${moment(time, "h:mm A").format("HH:mm:ss")}`
            })
                .then((res) => {
                    props.closeCreateClassified && props.closeCreateClassified(true);
                    props.onClose && props.onClose();
                })
        }
    }

    const handleTime = () => {
        setFormData((previousFormData) => ({
            ...previousFormData,
            time: time,
        }))
    };

    return <>
        <form onSubmit={submitHandler} autoComplete="off">
            <div className="login-popup">
                <div className="model-popup-box">
                    <div className="modal-content">
                        {/* <div className="dismiss-popup">
                        <div className="close" ><img  src={cross} alt="cross-sign" /></div>
                    </div> */}
                        <div class="ns-popup-header">
                            <div class="ns-tabs full-w-tabs">
                                <ul>
                                    <li class="active-tab">ADD CLASSIFIEDS</li>
                                </ul>
                            </div>
                        </div>
                        <div className="model-popup-inner">
                            <div className="field-block-outer">
                                <div className="field-block">
                                    <label>Title of Classified Post <span>*</span></label>
                                    <input className="form-control" type="text" name='title' value={formData['title']} onChange={(e) => { handleFormChange(e) }} />
                                    <ErrorInput formSubmitted={formSubmitted} error={errors['title']} />
                                </div>
                                <div className="field-block textarea">
                                    <label>Body of Classified Post <span>*</span></label>
                                    <textarea className="form-control" rows="4" cols="50" name='description' value={formData['description']} onChange={(e) => { handleFormChange(e) }}></textarea>
                                    <ErrorInput formSubmitted={formSubmitted} error={errors['description']} />
                                </div>
                                <div className="field-block calendar-blk">
                                    <label>Display Until Date <span>*</span></label>
                                    <DatePicker minDate={new Date()} onChange={(e) => handleFormChange({ target: { value: e, name: 'display_until' } })}
                                        value={formData.display_until} />
                                    <ErrorInput formSubmitted={formSubmitted} error={errors['display_until']} />
                                </div>
                                <div className="field-block calendar-blk">
                                    <label>Display Until Time <span>*</span></label>
                                    <input className="form-control" onFocus={() => setShowTime(true)} type="text" name='time' placeholder="Select Time" value={formData.time} />
                                    {
                                        showTime &&
                                        <div className="timepicker-modal">
                                            <TimeKeeper
                                                time={time ? moment(time, 'hh:mm A').format('h:mm a') : '12:00 am'}
                                                onChange={(newTime) => setTime(newTime.formatted12)}
                                                doneButton={(newTime) => {
                                                    return <div className="modal-footer">
                                                        <button type='button'
                                                            className='btn'
                                                            onClick={() => {
                                                                setShowTime(false)
                                                            }
                                                            }
                                                        >Close</button>
                                                        <button type='button'
                                                            className='btn btn-green'
                                                            onClick={() => {
                                                                setShowTime(false); handleTime();
                                                            }
                                                            }>
                                                            Done
                                                        </button>
                                                    </div>
                                                }}
                                            />
                                        </div>
                                    }
                                    <ErrorInput formSubmitted={formSubmitted} error={errors['time']} />
                                </div>
                                <div className="login-filed-btn">
                                    <button type='button' className='btn btn-secondary' onClick={() => props.onClose()}>Close</button>
                                    <button className="btn btn-primary">Submit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </>
}