import axios from "axios";
import { toast } from "react-toast";
import { setLoader } from "../../store/reducer/LoaderReducer";
import { store } from "../../store/Store";
import { config } from "../../config";

const axiosApi = axios.create();

axiosApi.interceptors.request.use(
    (req) => {
        const exemptedRequest = [`${config.apiUrl}/misc/check-email-existance`, `${config.apiUrl}/misc/check-team-duplicate`]; 
        const stateListRequest = [`${config.apiUrl}/misc/states`];
        if (req.url.indexOf(`${config.apiUrl}/leagues/confirm-payment`) === -1 && req.url.indexOf('leagues/generate-client-secret') === -1) {
            if(exemptedRequest.includes(req.url) || stateListRequest.includes(req.url)){
                store.dispatch(setLoader(false));
            } else {
                store.dispatch(setLoader(true));
            }
        }
        req.headers.Authorization = `Bearer ${localStorage.getItem('token') ? localStorage.getItem('token') : ''}`;
        return req;
    }
);

/****** Intercepts Response ********/
axiosApi.interceptors.response.use(function (response) {
    if (response?.config?.url.indexOf(`${config.apiUrl}/leagues/generate-client-secret`) === -1) {
        console.log('response?.config?.url', response?.config?.url);
        store.dispatch(setLoader(false))
    }
    if (response && response.data && response.data.status === 'success') {
        if (response.data.message) {
            toast.hideAll();
            toast.success(response.data.message);
        }
    }
    return response;
}, function (error) {
    store.dispatch(setLoader(false));
    if (error && error.response && error.response.status) {
        if (error.response.status === 401) {
            toast.hideAll();
            toast.error('Unauthorized');
            localStorage.removeItem('token')
            window.location.href = '/';
        } else {
            if (error.response.data && error.response.data.message) {
                toast.hideAll();
                toast.error(error.response.data.message);
            } else {
                toast.hideAll();
                toast.error(error.response.data.message);
            }
        }
    }

    // do what you want to do when error happens
    return Promise.reject(error);
});

export default axiosApi;