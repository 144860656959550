import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import axiosApi from "../../../core/interceptor/interceptor";
import { toTitleCase } from "../../../utils/Utils";
import { config, HOSTED_BY } from "../../../config";
import CustomSelect from "../../../utils/CustomSelect";
import arrowRight from "../../../assets/icons/arrowRight.svg";
import searchIcon from '../../../assets/images/search-icon.png';
import moment from 'moment';
import Popup from "reactjs-popup";
import Login from "../../main/login/Login";
import Signup from "../../main/signup/signup";
import { userDetails } from "../../../store/reducer/LoggedInUserDetails";
import WarningDialogue from "../../../utils/WarningDialogue";
import debounce from "lodash.debounce";

const ActiveLeagueList = (props) => {
    const [text, setText] = useState({
        title: '',
		from: '',
    });

	
    const [filter, setFilter] = useState({
		status: '',
		limit: 21,
		page: 1,
		search: ''
	});

    useEffect(() => {
        
        if(props.type === 'archive') {
            setText({ title: 'Archive Leagues', from: 'archive' })
            setFilter((prev) => ({
                ...prev,
                status: 'archive'
            }));
        } else {
            setText({ title: 'Active Leagues', from: 'active' });
            setFilter((prev) => ({
                ...prev,
                status: 'active'
            }));
        }
    }, []);
	const sportId = useParams().id;
	const [leagues, setLeagues] = useState([]);
	
	const [count, setCount] = useState(0);
	const [regType, setRegType] = useState('team');
	const loader = useSelector((state) => state.loader.value);

	/****** Leagues related to sports **********/
	const getLeagues = (params) => {
		axiosApi.get(`${config.apiUrl}/leagues/all-leagues/${sportId}`, { params }).then((response) => {
			if (response && response.data && response.data.data && response.data.data.rows) {
				setCount(response.data.data?.count);
				if (filter.page > 1) {
					setLeagues((prev) => ([...prev, ...response.data.data?.rows]));
				} else {
					setLeagues([...response?.data?.data?.rows]);
				}
			}
		}).catch(error => {
			console.error(error);
		});
	}

	/******* Leagues for Individual Registration ****/
	const getIndividualLeagues = (params) => {
		axiosApi.get(`${config.apiUrl}/leagues/leagues-list`, { params }).then((response) => {
			if (response && response.data && response.data.data && response.data.data.rows) {
				setCount(response.data.data?.count);
				if (filter.page > 1) {
					setLeagues((prev) => ([...prev, ...response.data.data?.rows]));
				} else {
					setLeagues([...response?.data?.data?.rows]);
				}
			}
		}).catch(error => {
			console.error(error);
		});
	}
	
    useEffect(() => {
        if (filter.status) {
            getIndividualLeagues(filter);
        }
		//eslint-disable-next-line
	}, [filter.page, filter.status, filter.search, sportId]);

	const searchLeague = (e) => {
		filter.page = 1;
		filter.search = e.target.value;
        getIndividualLeagues(filter);
	}

	const debouncedSearch = debounce(searchLeague, 1000);
	return <section className={`main-side ${sportId ? '' : 'no-sidebar'}`}>
		<div className="main-side-container">
			<div className={`main-top-headings p-10 ${sportId ? '' : 'detail-page-headings'}`}>
			<h3>
				{text.title}
			</h3>
				<div className="right-side-filters without-btns">
				<div className={`fleids-block input-field-search ${sportId ? '' : 'no-bar-tabs'}`}>
					<div className="search-input-box">
						<input id="searchBox" type="text" autocomplete="off" className="form-control" placeholder="Search..." onChange={(e) => debouncedSearch(e)}  />
						<button disabled={true} className="search-btn" >
							<img src={searchIcon} alt="" />
						</button>
					</div>
					<button className="leagues-show-btn btn-primary" onClick={() => props.showSportList(prev => !prev)}>Available Sports</button>
				</div>
				</div>
			</div>
			<div className="main-side-body">
				<div className={`league-cards ${leagues.length > 0 ? '' : 'no-records-in-table'}`}>
					{
						Array.isArray(leagues) && leagues.length ?
							leagues.map((league, i) => {
								return <div className="league-card" key={`league-list-@#$-${i + 1}`}>
									<div className="league-card-header">
										<h3>{league.name}</h3>
									</div>
									<div className="league-card-body">
										<div className="league-card-items">
											<div className="league-card-item">
												<p className="legue-detail-title">Sport:</p>
												<p className="league-detail-info">{toTitleCase(league?.sport?.title)}</p>
											</div>
											<div className="league-card-item">
												<p className="legue-detail-title">Level of play:</p>
												<p className="league-detail-info">{toTitleCase(league?.play_level)}</p>
											</div>
											<div className="league-card-item">
												<p className="legue-detail-title">
													Registration deadline:
												</p>
												<p className="league-detail-info">{league?.reg_deadline ? moment(league?.reg_deadline, 'YYYY-MM-DD').format('MM/DD/YYYY') : 'N/A'}</p>
											</div>
											<div className="league-card-item">
												<p className="legue-detail-title">Start Date:</p>
												<p className="league-detail-info">{league?.start_date ? moment(league?.start_date, 'YYYY-MM-DD').format('MM/DD/YYYY') : 'N/A'}</p>
											</div>
											<div className="league-card-item">
												<p className="legue-detail-title">League Length:</p>
												<p className="league-detail-info">{league?.no_of_weeks || 0} Week{league?.no_of_weeks > 1 ? 's' : ''}</p>
											</div>
											<div className="league-card-item">
												<p className="legue-detail-title">Hosted by:</p>
												<p className="league-detail-info">{league?.hosted_by === HOSTED_BY ? 'Cleveland' : 'Mulberry'}</p>
											</div>
										</div>
									</div>
									<div className="league-card-actions">
										<Link className="btn btn-dark-gray" to={{
											pathname: `/league/league-details/${league.id}`,
											state: {
												from: text.from,
											}
										}}>
											League Info
											<img src={arrowRight} alt="" />
										</Link>
									</div>
								</div>
							})
							:
							!loader && <div className="noRecord-found">
								<p>No Record Found</p>
							</div>
					}
				</div>
				{leagues.length && leagues.length < count ? <div className="card-more-btn">
					<a href="#" onClick={(e) => {
						e.preventDefault();
						if (count > leagues.length) {
							setFilter((prev) => ({
								...prev,
								page: prev.page + 1,
							}));
						}
					}}>View More</a>
				</div> : <></>}
			</div>
		</div>
	</section>
}

export default withRouter(ActiveLeagueList);