import React, { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { config } from "../../config";
import axiosApi from "../interceptor/interceptor";

export default function PaymentGuard({ Component }) {
  const history = useHistory();
  const id = useParams().id;
  const token = localStorage.getItem('token');
  useEffect(() => {
    if(token){
        axiosApi.get(`${config.apiUrl}/leagues/league-status/${id}`).then((resp) => {
            if (!resp.data.data) {
              history.push('/');
            }
          }).catch((err) => {
            history.push('/');
            console.error(err);
          });
    }else {
        history.push('/');
    }
    
    //eslint-disable-next-line
  }, []);

  return <Component />
}