import "./completePayment.css";
import {
  useStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  Elements,
  useElements,
} from "@stripe/react-stripe-js";
import { config } from "../../config";
import { loadStripe } from "@stripe/stripe-js";
import { useState, useEffect } from "react";
import axiosApi from "../../core/interceptor/interceptor";
import { useHistory, useParams } from "react-router-dom";
import powered from "../../assets/images/poweredBy-stripe.png";
import successCheck from "../../assets/images/success-check.svg";
import visa from "../../assets/images/visa-img.png";
import { toast } from "react-toast";
import { setLoader } from "../../store/reducer/LoaderReducer";
import { store } from "../../store/Store";
const styles = {
  base: {
    color: "#000",
    fontWeight: 400,
    // fontFamily: 'Quicksand, Open Sans, Segoe UI, sans-serif',
    fontSize: "13px",
    fontSmoothing: "antialiased",
    ":focus": {
      color: "#000",
    },
    "::placeholder": {
      color: "#44",
    },
    ":focus::placeholder": {
      color: "#1e1e1e",
    },
  },
  invalid: {
    color: "#f93737",
    ":focus": {
      color: "#f00",
    },
    "::placeholder": {
      color: "#ff6b6b",
    },
  },
};

const elementClasses = {
  focus: "focus",
  empty: "empty",
  invalid: "invalid",
};

const options = {
  style: styles,
  classes: elementClasses,
};

function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const [submitted, setSubmitted] = useState(false);
  const history = useHistory();

  const handleSubmit = async (event) => {
    setSubmitted(true);
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    store.dispatch(setLoader(true));
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
      billing_details: {
        address: {
          city: props?.info?.user?.city,
          line1: props?.info?.user?.address,
          postal_code: props?.info?.user?.zip,
        },
        email: props?.info?.user?.email,
        name: `${props?.info?.user?.first_name} ${props?.info?.user?.last_name}`,
        phone: props?.info?.user?.cell_phone,
      },
    });

    if (!error) {
      console.log("---11---");
      axiosApi
        .post(`${config.apiUrl}/leagues/generate-client-secret`, {
          id: props?.info.id,
          type: props?.type,
          paymentMethodId: paymentMethod.id,
        })
        .then(async (res) => {
          if (res?.data?.data?.client_secret) {
            await stripe
              .confirmCardPayment(res?.data?.data?.client_secret, {
                payment_method: paymentMethod.id,
              })
              .then((res) => {
                if (res.paymentIntent?.status === "succeeded") {
                  // Mark team paid
                  axiosApi
                    .post(`${config.apiUrl}/leagues/confirm-payment`, {
                      type: props?.type,
                      id: props?.info.id,
                      paymentIntentId: res.paymentIntent?.id,
                    })
                    .then((res) => {
                      store.dispatch(setLoader(false));
                      props?.confirmPayment();
                    });
                } else {
                  let message = "Payment Failed";
                  if (res.error) {
                    message = res?.error?.message
                      ? res?.error?.message
                      : "Payment Failed";
                    setSubmitted(false);
                  }
                  elements.getElement(CardNumberElement).clear();
                  elements.getElement(CardExpiryElement).clear();
                  elements.getElement(CardCvcElement).clear();
                  toast.error(message);
                  store.dispatch(setLoader(false));
                }
              })
              .catch((e) => {
                elements.clear();
                setSubmitted(false);
                toast.error(e.message);
                store.dispatch(setLoader(false));
              });
          }
        })
        .catch((err) => {
          setSubmitted(false);
          store.dispatch(setLoader(false));
        });
    } else {
      store.dispatch(setLoader(false));
      setSubmitted(false);
    }
  };

  const [errors, setErrors] = useState({
    cardNumber: "",
    cardExpiry: "",
    cardCvc: "",
  });

  const onChange = (ev) => {
    setErrors((prevState) => {
      return {
        ...prevState,
        [ev.elementType]:
          ev && ev.error && ev.error.message ? ev.error.message : "",
      };
    });
  };

  return (
    <div className="complete-payment">
      <form onSubmit={handleSubmit} className="example example3">
        <div class="payment-heading">
          <h2>Payment</h2>
        </div>
        <div className="fieldset">
          <input
            id="example3-name"
            data-tid="elements_examples.form.name_label"
            className="field"
            type="text"
            name="full_name"
            value={`${props?.info?.user?.first_name || ""} ${
              props?.info?.user?.last_name || ""
            }`}
            readOnly={true}
            autoComplete="name"
          />
          <input
            id="example3-email"
            data-tid="elements_examples.form.email_label"
            className="field half-width"
            name="full_name"
            value={props?.info?.user?.email}
            readOnly={true}
          />
          <input
            id="example3-phone"
            data-tid="elements_examples.form.phone_label"
            className="field half-width"
            type="tel"
            value={props?.info?.user?.cell_phone}
            readOnly={true}
          />
        </div>
        <div className="fieldset">
          <CardNumberElement
            className="field empty"
            onChange={(e) => onChange(e)}
            options={options}
          />
          <CardExpiryElement
            className="field empty third-width"
            onChange={(e) => onChange(e)}
            options={options}
          />
          <CardCvcElement
            className="field empty third-width"
            onChange={(e) => onChange(e)}
            options={options}
          />
        </div>
        <button
          disabled={!stripe || submitted}
          className="btn-primary full"
          data-tid="elements_examples.form.pay_button"
          type="submit"
        >
          Pay Now
        </button>
        {errors.cardNumber || errors.cardExpiry || errors.cardCvc ? (
          <div className="complete-pay-error">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="17"
              viewBox="0 0 17 17"
            >
              <path
                className="base"
                fill="#f97171"
                d="M8.5,17 C3.80557963,17 0,13.1944204 0,8.5 C0,3.80557963 3.80557963,0 8.5,0 C13.1944204,0 17,3.80557963 17,8.5 C17,13.1944204 13.1944204,17 8.5,17 Z"
              ></path>
              <path
                className="glyph"
                fill="#fff"
                d="M8.5,7.29791847 L6.12604076,4.92395924 C5.79409512,4.59201359 5.25590488,4.59201359 4.92395924,4.92395924 C4.59201359,5.25590488 4.59201359,5.79409512 4.92395924,6.12604076 L7.29791847,8.5 L4.92395924,10.8739592 C4.59201359,11.2059049 4.59201359,11.7440951 4.92395924,12.0760408 C5.25590488,12.4079864 5.79409512,12.4079864 6.12604076,12.0760408 L8.5,9.70208153 L10.8739592,12.0760408 C11.2059049,12.4079864 11.7440951,12.4079864 12.0760408,12.0760408 C12.4079864,11.7440951 12.4079864,11.2059049 12.0760408,10.8739592 L9.70208153,8.5 L12.0760408,6.12604076 C12.4079864,5.79409512 12.4079864,5.25590488 12.0760408,4.92395924 C11.7440951,4.59201359 11.2059049,4.59201359 10.8739592,4.92395924 L8.5,7.29791847 L8.5,7.29791847 Z"
              ></path>
            </svg>
            <span className="message">
              {errors.cardNumber || errors.cardExpiry || errors.cardCvc}
            </span>
          </div>
        ) : (
          ""
        )}
        <div className="img-blk">
          <img className="powered-img" src={powered} alt="stripe" />
          <img className="visa-img" src={visa} alt="visa" />
        </div>
      </form>
    </div>
  );
}

const stripePromise = loadStripe(config.stripePublicKey);

function CompletePayment(props) {
  const [valid, setValid] = useState(false);
  const params = useParams();
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const history = useHistory();
  const [info, setInfo] = useState({});

  useEffect(() => {
    if (params.token && params.type) {
      axiosApi
        .get(
          `${config.apiUrl}/leagues/verify-payment-hash/${params.token}/${params.type}`
        )
        .then((res) => {
          setValid(true);
          setInfo(res.data.data);
        })
        .catch((e) => {
          history.push(`/`);
        });
    } else {
      history.push(`/`);
    }
    return () => {};
  }, []);

  useEffect(() => {}, []);

  const confirmPayment = () => {
    setPaymentCompleted(true);
    setTimeout(() => {
      history.push("/");
    }, 10000);
  };

  return (
    <>
      {!paymentCompleted && (
        <>
          {valid ? (
            <main className="wrapper-main">
              <div className="payment-wrapper">
                <div className="container">
                  <div className="menu left">
                    <div className="order-info">
                      <div className="item-label">
                        <h2>
                          {params?.type === "team" ? (
                            <div>Team Registration {info?.league?.name}</div>
                          ) : (
                            <div>
                              Individual Registration for{" "}
                              {info?.assign_league?.name}
                            </div>
                          )}{" "}
                        </h2>
                      </div>
                      <div className="team-info item">
                        <div className="sub-heading">Team Information</div>
                        <div className="team-details">
                          <ul>
                            {params?.type === "individual" ? (
                              <>
                                <li>
                                  <label> Team Name:</label>
                                  <span>{info?.assign_teams?.name || ""}</span>
                                </li>
                                <li>
                                  <label> Caption Name:</label>
                                  <span>
                                    {" "}
                                    {`${info?.captain?.first_name || "-"} ${
                                      info?.captain?.last_name || ""
                                    }`}
                                  </span>
                                </li>
                                <li>
                                  <label> Caption Email:</label>
                                  <span>{info?.captain?.email || "-"}</span>
                                </li>
                                <li>
                                  <label> Caption Phone:</label>
                                  <span>
                                    {info?.captain?.cell_phone || "-"}
                                  </span>
                                </li>
                              </>
                            ) : (
                              <>
                                <li>
                                  <label> Caption Name:</label>
                                  <span>
                                    {" "}
                                    {`${info?.user?.first_name || "-"} ${
                                      info?.user?.last_name || ""
                                    }`}
                                  </span>
                                </li>
                                <li>
                                  <label> Caption Email:</label>
                                  <span>{info?.user?.email || "-"}</span>
                                </li>
                                <li>
                                  <label> Caption Phone:</label>
                                  <span>
                                    {info?.user?.cell_phone || "-"}
                                  </span>
                                </li>
                              </>
                            )}
                          </ul>
                        </div>
                      </div>

                      <div className="team-info item">
                        <div className="payment-details">
                          <div className="sub-heading">Payment Information</div>
                          <div className="team-details">
                            <ul>
                              <li>
                                <label>League Fees Due:</label>
                                <span>${info?.registration_fees || 0}</span>
                              </li>
                              {params?.type === "team" && (
                                <li>
                                  <label>Refundable Forfeit Fee Due:</label>
                                  <span>${info?.for_feit_fees || 0}</span>
                                </li>
                              )}

                              <li>
                                <label>Stripe Fee Due:</label>
                                <span>${info?.stripe_fees || 0}</span>
                              </li>
                            </ul>
                            <span className="total-fee">
                              <strong>Total Fee Due:</strong> $
                              {info?.total_fees || 0}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="menu right">
                    <Elements stripe={stripePromise}>
                      <CheckoutForm
                        info={info}
                        type={params?.type}
                        confirmPayment={confirmPayment}
                      />
                    </Elements>
                  </div>
                </div>
              </div>
            </main>
          ) : (
            <main className="wrapper-main">
              <div className="payment-wrapper"></div>
            </main>
          )}
        </>
      )}

      {paymentCompleted && (
        <>
          <div className="success-message">
            <div className="success-message-content">
              <img src={successCheck} alt="success" />
              <h2>Transaction Completed Successfully</h2>
              {params.type === "team" && (
                <span>You will receive a confirmation email shortly.</span>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
}
export default CompletePayment;
