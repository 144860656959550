import React from 'react';
import { Route, withRouter } from 'react-router';
import { Redirect } from 'react-router-dom';
import LeagueDetails from '../../league/LeagueDetails/LeagueDetails';
import Classifieds from '../Classifieds/Classifieds';
import LeagueUpcomingHistory from '../LeagueUpcomingHistory/LeagueUpcomingHistory';
import PlayOffMatches from '../LeagueUpcomingHistory/PlayOffMatches';
import "../events.css";
import ModifyAccount from '../modifyAccount/ModifyAccount';
import TeamInviteDetails from '../myTeams/TeamInvite/TeamInviteDetails';
import TeamInvites from '../myTeams/TeamInvite/TeamInvites';
import TeamsLayout from '../myTeams/TeamsLayout';

function AccountLayout(props) {
  return <>
    <section className='main-side '>
      <div className='main-side-container'>
        <Route exact={true} path={`${props.match.path}`}>
          <Redirect to={`${props.match.path}/upcoming-matches`} />
        </Route>
        <Route exact={true} path={`${props.match.path}/upcoming-matches`}>
          <LeagueUpcomingHistory {...props} />
        </Route>
        <Route exact={true} path={`${props.match.path}/league-history`}>
          <LeagueUpcomingHistory isHistory={true} {...props} />
        </Route>
        <Route exact={true} path={`${props.match.path}/league-details/:id`}>
          <LeagueDetails />
        </Route>
        <Route exact={true} path={`${props.match.path}/profile`}>
          <ModifyAccount />
        </Route>
        <Route path={`${props.match.path}/teams`}>
          <TeamsLayout {...props} />
        </Route>
        <Route path={`${props.match.path}/team-invites`}>
          <Route exact={true} path={`${props.match.path}/team-invites`}>
            <TeamInvites {...props} />
          </Route>
          <Route exact={true} path={`${props.match.path}/team-invites/:id`}>
            <TeamInviteDetails {...props} />
          </Route>
        </Route>
        <Route path={`${props.match.path}/my-classifieds`}>
          <Classifieds isHistory={true} {...props} />
        </Route>
        <Route exact={true} path={`${props.match.path}/playoff-matches/:id`}>
          <PlayOffMatches />
        </Route>
      </div>
    </section>
    {/* <Route exact={true} path={`${props.match.path}`}>
      <MyAccount />
    </Route>
    <Route exact={true} path={`${props.match.path}/team-details/:id`}>
      <TeamDetails />
    </Route>
    <Route exact={true} path={`${props.match.path}/modify-account`}>
      <ModifyAccount />
    </Route>
    <Route exact={true} path={`${props.match.path}/view-invite/:id`}>
      <ViewInvite />
    </Route> */}
  </>
}

export default withRouter(AccountLayout);