import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Popup from "reactjs-popup";
import backArrowGreen from "../../../assets/icons/backArrowGreen.png";
import crown from "../../../assets/images/king-sign.svg";
import { REGISTERED_THROUGH, config } from "../../../config";
import axiosApi from "../../../core/interceptor/interceptor";
import { messages } from "../../../messages";
import { validateEmail } from "../../../utils/Utils";
import ErrorInput from "../../common/ErrorInput";
import "../../league/League.css";
import "../../league/LeagueDetails/LeagueDetails.css";

function TeamDetails(props) {
	const sliderRef = useRef(null);
	const teamId = useParams().id;
	const history = useHistory();
	const [team, setTeam] = useState("");
	const [invite, setInvite] = useState(false);
	const [email, setEmail] = useState();
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [error, setError] = useState("");
	const [scheduleMatch, setScheduleMatch] = useState([]);
	const [teams, setTeams] = useState([]);
	useEffect(() => {
		axiosApi.get(`${config.apiUrl}/team/Player`).then((res) => {
			setTeams(res.data.data);
		});
	}, []);

	useEffect(() => {
		if (teamId) {
			getTeamDetails();
		}
	}, [teamId]);

	useEffect(() => {
		if (teamId && Array.isArray(teams)) {
			const matches = [];
			let tmp_upcoming = [];
			if (Array.isArray(teams)) {
				teams.forEach((team) => {
					if (team?.league?.status === "active" && +teamId === +team.id && Array.isArray(team.league?.league_schedules)) {
						team.league.league_schedules.forEach((schedule, cc) => {
							if (!schedule.winner_id && schedule?.field) {
								if (schedule?.field && schedule?.field?.id) {
									tmp_upcoming.push({
										name: team.league.name,
										key: cc,
										leagueId: team.league.id,
										team1: team.name,
										team2: schedule?.team1?.id === team.id ? schedule?.team2?.name : schedule?.team1?.name,
										location: schedule?.field?.location?.title,
										field: schedule?.field?.title,
										time: schedule.time,
										date: schedule.date,
										notification: team?.league?.sport?.notification,
									});
								}
							}
						});
					}

					if (team?.league?.status === "active" && +teamId === +team.id && Array.isArray(team.league?.league_playoffs)) {
						team.league.league_playoffs.forEach((schedule, cc) => {
							if (!schedule.winner_id && schedule?.field) {
								if (schedule?.field && schedule?.field?.id) {
									tmp_upcoming.push({
										name: team.league.name,
										key: cc,
										leagueId: team.league.id,
										team1: team.name,
										team2: schedule?.team1?.id === team.id ? schedule?.team2?.name : schedule?.team1?.name,
										location: schedule?.field?.location?.title,
										field: schedule?.field?.title,
										time: schedule.time,
										date: schedule.date,
										notification: team?.league?.sport?.notification,
										playoff: true,
									});
								}
							}
						});
					}
				});
			}
			tmp_upcoming = tmp_upcoming.sort((a, b) => {
				return a.date && a.time && moment(`${a.date} ${a.time}`, "YYYY-MM-DD HH:mm:ss").isBefore(moment(`${b.date} ${b.time}`, "YYYY-MM-DD HH:mm:ss")) ? -1 : 1;
			});
			tmp_upcoming.forEach((up, index) => {
				matches.push(
					<div className={`league-card um-cards league-um-cards-rs ${up && up.playoff ? "playoff-match" : ""}`} key={`upcoming-matches-${index}-${up.key}`}>
						<div className='league-card-header '>
							{up?.playoff && <div className='play-off'>Playoff Match</div>}
							<h3>{up?.name}</h3>
						</div>
						<div className='league-card-body'>
							<div className='league-card-items'>
								<div className='league-card-item card-vs-item'>
									<div className='card-team-item'>{up?.team1 || "TBD"}</div>
									<div className='vs-box'>
										<p>VS</p>
									</div>
									<div className='card-team-item'>{up?.team2 || "TBD"}</div>
								</div>
								<div className='league-card-item'>
									<p className='legue-detail-title'>Location:</p>
									<p className='league-detail-info'>{up.location}</p>
								</div>
								<div className='league-card-item'>
									<p className='legue-detail-title'>When</p>
									<p className='league-detail-info'>{up.date && up.time ? `${moment(up.date).format("MMMM Do")} at ${moment(up.time, "HH:mm:ss").format("h:mm A")}` : `${up.playoff ? "Playoff TBD" : "TBD"}`}</p>
								</div>
								<div className='league-card-item'>
									<p className='legue-detail-title'>Note</p>
									<p className='league-detail-info normal'>{up?.notification?.replace(/(<([^>]+)>)/gi, "")?.trim() ? <span dangerouslySetInnerHTML={{ __html: up?.notification }}></span> : "N/A"}</p>
								</div>
							</div>
						</div>
					</div>
				);
			});
			setScheduleMatch(matches);
		}
	}, [teamId, teams]);

	useEffect(() => {
		if (!(email || "").trim()) {
			setError(messages.emptyEmail);
		} else if (!validateEmail(email)) {
			setError(messages.invalidEmail);
		} else {
			setError("");
		}
	}, [email]);

	useEffect(() => {
		if (sliderRef.current) {
			sliderRef.current.slickGoTo(0);
		}
	}, [sliderRef]);

	const getTeamDetails = () => {
		axiosApi.get(`${config.apiUrl}/team/team-detail/${teamId}`).then(
			(res) => {
				setTeam(res.data.data);
			},
			(err) => {
				console.error(err);
			}
		);
	};

	const handleInvite = () => {
		setFormSubmitted(true);
		if (!validateEmail(email)) {
			return;
		}
		axiosApi
			.post(`${config.apiUrl}/team/invite-player/${REGISTERED_THROUGH}`, {
				email: email,
				team_id: Number(teamId),
				league_id: team.league_id,
			})
			.then(() => {
				setEmail("");
				getTeamDetails();
				setFormSubmitted(false);
				setInvite(false);
			})
			.catch((err) => {
				setFormSubmitted(false);
				setInvite(false);
			});
	};

	useEffect(() => {
		console.log("---- Team --->", props);
	}, []);
	return (
		<>
			<main className='wrapper-main'>
				<section className='main-side no-sidebar'>
					<div className='main-side-container'>
						<div className='main-top-headings top-head-1'>
							<div className='league-back-link'>
								<a
									href='#'
									onClick={(e) => {
										e.preventDefault();
										history.push(`/my-account/teams`);
									}}>
									<img src={backArrowGreen} alt='' />
									Back
								</a>
							</div>
							{team && team.league && team.league.status && !["complete"].includes(team.league.status) && (
								<div className='breadcrumbs'>
									<button
										className='btn'
										onClick={(e) => {
											e.preventDefault();
											setInvite(true);
										}}>
										Send Invite
									</button>
								</div>
							)}
						</div>
						{team ? (
							<div className='main-side-body'>
								<div className='league-detail-header'>
									<h2 className='league-detail-heading'>
										{team.name} | {team.league.name}
									</h2>
								</div>
								<div className='league-cards league-detail-cards myteam-cards'>
									<div className='league-card' key='team-members'>
										<div className='card-division-name'>
											<h4>Team Members</h4>
										</div>
										<div className='league-card-header'>
											<div className='card-th cell-team'>Name</div>
											<div className='card-th cell-team'>Email</div>
											<div className='card-th cell-team'>Phone</div>
										</div>
										<div className='league-card-body'>
											<div className='league-card-items'>
												{team.players && Array.isArray(team.players) ? (
													team.players.map((p, index) => (
														<div className='league-card-item' key={`player-item-${index}`}>
															<div className='card-td cell-team'>
																{p?.user?.full_name || "N/A"} {team.captain_id === p.user_id ? <img className='king-sign' src={crown} alt='' /> : ""}
															</div>
															<div className='card-td cell-team'>{p?.user?.email || "N/A"}</div>
															<div className='card-td cell-team'>{p?.user?.cell_phone || "N/A"}</div>
														</div>
													))
												) : (
													<div className='noRecord-block'>
														<p>No Team Memebers Found.</p>
													</div>
												)}
											</div>
										</div>
									</div>
									<div className='league-card' key='team-sent-invite'>
										<div className='card-division-name'>
											<h4>Sent Invites</h4>
										</div>
										<div className='league-card-header'>
											<div className='card-th cell-team'>Invitee</div>
											<div className='card-th cell-team'>Inviter</div>
											<div className='card-th cell-team'>Status</div>
										</div>
										<div className='league-card-body'>
											<div className='league-card-items'>
												{team.invite_players && team.invite_players.length ? (
													team.invite_players.map((p, index) => (
														<div className='league-card-item' key={`sent-invites-${index}`}>
															<div className='card-td cell-team'>{p.email}</div>
															<div className='card-td cell-team'>{p?.Inviter?.email}</div>
															<div className='card-td cell-team'>{p?.status ? (p?.accepted ? "Accepted" : "Rejected") : "Pending"}</div>
														</div>
													))
												) : (
													<div className='noRecord-block'>
														<p>No Invites Found.</p>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
								<div className='league-schedule'>
									<div className='league-sch-select'>
										<h4>Team Schedule</h4>
									</div>
									<div className='league-sc-slider'>
										{scheduleMatch.length > 0 ? (
											<div className='league-cards'>{scheduleMatch}</div>
										) : (
											<div className='noRecord-block'>
												<p>No Team Schedule Found.</p>
											</div>
										)}
									</div>
								</div>
							</div>
						) : (
							<></>
						)}
					</div>
				</section>
			</main>
			<Popup
				open={invite}
				onClose={() => {
					setInvite(false);
				}}
				closeOnDocumentClick={true}
				className='ns-popup sm-popup inv-popup'>
				<div className='my-account-inner invite-block-modal'>
					<div className='matches-updates personal-details-popup'>
						<div className='ns-popup-header'>
							<div className='ns-tabs full-w-tabs'>
								<ul>
									<li className='active-tab'>Invite A Player</li>
								</ul>
							</div>
						</div>
						<div className='model-popup-inner'>
							<div className='invite-flied-block'>
								<div className='cs-row'>
									<div className='c-9'>
										<div className='fleids-block'>
											<label>Email Address</label>
											<input className='form-control' type='email' name='email' value={email} onChange={(e) => setEmail(e.target.value)} />
											<ErrorInput formSubmitted={formSubmitted} error={error} />
										</div>
									</div>
									{/* <div className="c-3">
                                    <div className="header-btn-block invite-btn">
                                        <span className="btn btn-primary" onMouseDown={() => handleInvite()}>Invite Player</span>
                                    </div>
                                </div> */}
								</div>
								<div class='cs-row ns-popup-action justify-right p-t-20'>
									<div class='login-filed-btn'>
										<button type='button' class='btn btn-secondary' onMouseDown={() => setInvite(false)}>
											Close
										</button>
										<button class='btn btn-primary' onMouseDown={() => handleInvite()}>
											Submit
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Popup>
		</>
	);
}

export default TeamDetails;
