import React, { useState, useEffect } from "react";
import { config, REGISTERED_THROUGH } from "../../config";
import axiosApi from "../../core/interceptor/interceptor";
import { messages } from "../../messages";
import CustomSelect from "../../utils/CustomSelect";
import { validateEmail, validateRequiredFields } from "../../utils/Utils";
import ErrorInput from "../common/ErrorInput";
import "./Contact.css";
import { maskingToPlainNumber, phoneNumberMask } from "../../utils/Utils";
import InputMask from "react-input-mask";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { withRouter } from "react-router-dom";

function Contact() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: config.googleApi,
  });

  const [formData, setFormData] = useState({
    email: "",
    full_name: "",
    phone: "",
    address: "", 
    city: "",
    state_id: null,
    zip: "",
    message: "",
    state_name: "",
    platform: REGISTERED_THROUGH,
  });

  const [errors, setErrors] = useState({
    email: "",
    full_name: "",
    message: "",
    phone: "",
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [isStateList, setIsStateList] = useState(false);

  useEffect(() => {
    getStateList();
  }, []);

  useEffect(() => {
    handleValidation();
    //eslint-disable-next-line
  }, [
    formData["email"],
    formData["full_name"],
    formData["message"],
    formData["phone"],
  ]);

  const center = {
    lat: 41.49487,
    lng: -81.70813,
  };

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  const getStateList = () => {
    axiosApi.get(`${config.apiUrl}/misc/states`).then(
      (res) => {
        setStateList(res?.data?.data);
        setIsStateList(true);
      },
      (err) => {
        setIsStateList(true);
        console.error(err);
      }
    );
  };

  const handleValidation = () => {
    let errArray = {};
    let formIsValid = true;
    if (validateRequiredFields(formData)) {
      if (!(formData.email || "").trim()) {
        errArray.email = messages && messages.emptyEmail;
        formIsValid = false;
      }
      if (!(formData.full_name || "").trim()) {
        errArray.full_name = messages && messages.fullName;
        formIsValid = false;
      }
      if (!(formData.message || "").trim()) {
        errArray.message = messages && messages.message;
        formIsValid = false;
      }
      setErrors(errArray);
    }
    if (formData.phone && formData.phone.replace(/\D/g, "").length < 10) {
      errArray.phone = messages && messages.phoneLength;
      formIsValid = false;
    }
    if (formData.email && !validateEmail(formData.email)) {
      errArray.email = messages && messages.invalidEmail;
      formIsValid = false;
    }
    setErrors(errArray);
    return formIsValid;
  };

  const handleFormChange = (e) => {
    if (e.target.name === "phone") {
      setFormData((previousFormData) => ({
        ...previousFormData,
        [e.target.name]: maskingToPlainNumber(e.target.value),
      }));
    }
    setFormData((previousFormData) => ({
      ...previousFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const submitHandler = () => {
    const data = { ...formData };
    if (data.phone) {
      data.phone = data.phone.replace(/\D/g, "");
    }
    const keys = Object.keys(data);
    if (keys.length) {
      keys.forEach((key) => {
        if (typeof data[key] === "string") {
          data[key] = data[key].trim();
        }
      });
    }
    setFormSubmitted(true);
    if (handleValidation()) {
      axiosApi.post(`${config.apiUrl}/contact-us`, data).then((res) => {
        const data_ = {
          email: "",
          full_name: "",
          phone: "",
          address: "",
          city: "",
          state_id: null,
          zip: "",
          message: "",
          platform: REGISTERED_THROUGH,
        };
        setFormData(data_);
        setFormSubmitted(false);
      });
    }
  };

  const getPlace = (place) => {
    place?.address_components?.forEach((element) => {
      if (element?.types.includes("postal_code")) {
        setFormData((prev) => ({
          ...prev,
          zip: element.long_name,
        }));
      }
      if (element?.types.includes("administrative_area_level_2")) {
        setFormData((prev) => ({
          ...prev,
          city: element.long_name,
        }));
      }
      if (
        element?.types.includes("administrative_area_level_1") &&
        isStateList
      ) {
        const state = stateList?.find(
          (val) => val.name.toLowerCase() === element.long_name.toLowerCase()
        );
        setFormData((prev) => ({
          ...prev,
          state_id: state?.id,
          state_name: element.long_name,
        }));
      }
    });
    if (place.addressVal) {
      setFormData((prev) => ({
        ...prev,
        address: place.addressVal,
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        address: place,
      }));
    }
  };

  const mapOptions = {
    panControl: true,
    zoomControl: false,
    mapTypeControl: false,
    streetViewControl: false,
    overviewMapControl: true,
  };

  return (
    <>
      <main className="wrapper-main">
        <section className="content-as-block contact-us-wrapper">
          <div className="cs-container">
            <div className="contact-us-inner">
              <div className="contact-updates-inner left">
                <div className="con-info">
                  <h3>Contact Info</h3>
                </div>
                <div className="con-info meets-hours">
                  <h3>Hours</h3>
                  <span>Phone: 9:00 AM - 5:00 PM EST</span>
                  <span>Text(SMS): After 5:00 PM EST</span>
                </div>
                <div className="con-info meets-hours">
                  <h3>Rain</h3>
                  <span>
                    <strong>Mark</strong>:{" "}
                    <a href="tel:216-856-0718">(216) 856-0718</a>
                  </span>
                </div>
                <div className="con-info meets-hours">
                  <h3>Contacts</h3>
                  <span>
                    <strong>Keith</strong>:{" "}
                    <a href="tel:216-410-2470">(216) 410-2470</a>
                  </span>
                </div>
              </div>
              <div className="right">
                {/* <h2>Contact Us</h2> */}
                <div className="field-block-outer">
                  <div className="field-blocks">
                    <label>
                      Email Address<span> *</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={formData["email"]}
                      onChange={(e) => handleFormChange(e)}
                      autoComplete="off"
                    />
                    <ErrorInput
                      formSubmitted={formSubmitted}
                      error={errors["email"]}
                    />
                  </div>
                  <div className="field-blocks">
                    <label>
                      Full Name <span>*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="full_name"
                      value={formData["full_name"]}
                      onChange={(e) => handleFormChange(e)}
                      autoComplete="off"
                    />
                    <ErrorInput
                      formSubmitted={formSubmitted}
                      error={errors["full_name"]}
                    />
                  </div>
                  <div className="field-blocks">
                    <label>Phone Number</label>
                    <InputMask
                      type="text"
                      mask={phoneNumberMask}
                      className="form-control"
                      name="phone"
                      value={formData["phone"]}
                      onChange={(e) => handleFormChange(e)}
                      autoComplete="off"
                    />
                    <ErrorInput
                      formSubmitted={formSubmitted}
                      error={errors["phone"]}
                    />
                  </div>
                  <div className="field-blocks">
                    <label>Address</label>
                    {<input
                      type="text"
                      className="form-control"
                      name="address"
                      value={formData["address"]}
                      onChange={(e) => handleFormChange(e)}
                      autoComplete="none"
                    />}
                  </div>
                  <div className="three-col">
                    <div className="select-block">
                      <CustomSelect
                        required={false}
                        label={"State"}
                        data={stateList}
                        placeholder={
                          formData.state_name ? formData.state_name : "Select State"
                        }
                        onSelect={(e) =>
                          e &&
                          handleFormChange({
                            target: {
                              name: "state_id",
                              value: formData.state_id ? formData.state_id : e.id,
                            },
                          })
                        }
                        selector={"name"}
                      />
                    </div>
                    <div className="field-blocks">
                      <label>City</label>
                      <input
                        type="text"
                        className="form-control"
                        name="city"
                        value={formData["city"]}
                        onChange={(e) => handleFormChange(e)}
                        autoComplete="none"
                      />
                    </div>
                    <div className="field-blocks">
                      <label>Zip Code</label>
                      <input
                        type="text"
                        className="form-control"
                        name="zip"
                        value={formData["zip"]}
                        onChange={(e) => handleFormChange(e)}
                        autoComplete="none"
                      />
                    </div>
                  </div>
                  <div className="field-blocks messages">
                    <label>
                      Message <span>*</span>
                    </label>
                    <textarea
                      rows="4"
                      cols="50"
                      className="message-textarea"
                      name="message"
                      value={formData["message"]}
                      onChange={(e) => handleFormChange(e)}
                      autoComplete="none"
                    ></textarea>
                    <ErrorInput
                      formSubmitted={formSubmitted}
                      error={errors["message"]}
                    />
                  </div>
                  <div className="send-message">
                    <button
                      className="btn btn-primary"
                      onMouseDown={() => submitHandler()}
                    >
                      Send Message
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-updates">
            <div className="cs-row">
              
              <div className="current-location direction-wrapper">
                {/* <LoadScript googleMapsApiKey="enter api key here"> */}
                {isLoaded && (
                  <GoogleMap
                    options={mapOptions}
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={15}
                  >
                    {/* Child components, such as markers, info windows, etc. */}
                    <Marker
                      position={center}
                      label={"2316 Mulberry Ave, Cleveland OH, 44113"}
                    />
                  </GoogleMap>
                )}

                {/* </LoadScript> */}
                <a
                  href={(() => {
                    if (
                      navigator &&
                      navigator.userAgentData &&
                      navigator.userAgentData.platform &&
                      (navigator.userAgentData.platform.indexOf("iPhone") !==
                        -1 ||
                        navigator.userAgentData.platform.indexOf("iPod") !==
                          -1 ||
                        navigator.userAgentData.platform.indexOf("iPad") !== -1)
                    )
                      return `maps://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${center.lat},${center.lng}`;
                    else
                      return `https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${center.lat},${center.lng}`;
                  })()}
                  target="_blank"
                  rel="noreferrer"
                  className="direction-link"
                >
                  <button className="btn btn-primary">Get Directions</button>
                </a>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}

export default withRouter(Contact);
