import './Home.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import fig from '../../assets/images/sponsor-fig.jpg';
import advImg from '../../assets/images/advt.jpg'
import advImg2 from '../../assets/images/advt-2.jpg'
import figure from '../../assets/images/group-figure.jpg';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, withRouter } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import axiosApi from '../../core/interceptor/interceptor';
import { config, HOSTED_BY, REGISTERED_THROUGH, Disclaimers } from '../../config';
import moment from 'moment';
import { toTitleCase } from '../../utils/Utils';
import Popup from 'reactjs-popup';
import Signup from '../main/signup/signup';
import Login from '../main/login/Login';
import { useSelector } from 'react-redux';
import { sportsListDetails } from "../../store/reducer/SportsList";
import arrowRight from "../../assets/icons/arrowRight.svg";

function Home(props) {
	const sportsList = useSelector(sportsListDetails);
	const [leagues, setLeagues] = useState([]);
	const [login, setLogIn] = useState(false);
	const search = useLocation().search;
	const [signup, setSignUp] = useState(new URLSearchParams(search).get('signup'));
	const [advertisements, setAdvertisements] = useState([]);
	const [sponsors, setSponsors] = useState([]);
	const loader = useSelector((state) => state.loader.value);
	const [email, setEmail] = useState('');
	const [headline, setHeadline] = useState('')
	useEffect(() => {
		const params = {
			status: 'sign_up',
			limit: 4,
			page: 1,
			current_date: moment().format('MM-DD-YYYY')
		};
		axiosApi.get(`${config.apiUrl}/leagues`, { params })
			.then((response) => {
				if (response && response.data && response.data.data) {
					setLeagues(response.data.data);
				}
			})
	}, []);
	const sponsorCarousel = () => {
		return <>
			{
				sponsors && sponsors.length > 0 ?
					<div className="sponsors-block in-cards">
						<div className="cs-row align-middle">
							<div className="c-12">
								<Carousel
									autoPlay={true}
									emulateTouch={false}
									className="advt-carousel"
									infiniteLoop={true}
									interval={4603}
									showThumbs={false}
									showArrows={false}
									showStatus={false}>
									{
										sponsors && sponsors.length &&
										sponsors.map((ad, idx) => (
											<div key={ad.id} className="slider-sponsor" onClick={() => handleAdClick(ad)}>
												<img src={`${config.api}/${config.imageFolder}/sponsors/${ad.image}`} alt="" />
											</div>
										))
									}
								</Carousel>
							</div>
						</div>
					</div>
					: null
			}
		</>
	}
	useEffect(() => {
		if (props && props.location && props.location.state && props.location.state.status) {
			if (props.location.state.status === 'signup') {
				setSignUp(true);
			} else if (props.location.state.status === 'login') {
				setLogIn(true);
			}
			setEmail(props.location?.state?.email || '');
		}
		// eslint-disable-next-line
	}, [props?.location?.state?.status]);


	useEffect(() => {
		axiosApi.get(`${config.apiUrl}/misc/disclaimer/${REGISTERED_THROUGH}/${Disclaimers.site_headline}`)
			.then((res) => {
				if (res && res.data && res.data.data) {
					setHeadline(res.data.data.disclaimer);
				}
			})
	}, [])

	useEffect(() => {
		axiosApi.get(`${config.apiUrl}/misc/advertisements`)
			.then((res) => {
				setAdvertisements(res?.data?.data);
			})
	}, [])

	useEffect(() => {
		axiosApi.get(`${config.apiUrl}/misc/sponsors/cleveland`)
			.then((res) => {
				setSponsors(res?.data?.data);
			})
	}, [])

	const handleAdClick = (ad) => {
		if (ad && ad.hyper_link) {
			window.open(`${ad.hyper_link}`, '_blank')
		}
	}

	return (
		<>
			<main className="wrapper-main">
				<section className="hero-block">
					<div className="cs-container">
						<div className="content-hero">
							<h1>Join Cleveland's <span className="block"><span className="green">Favorite</span> Sports Leagues</span></h1>
							<p>The #1 spot for Cleveland Sports and recreation</p>
							<div className="btn-block btn-group">
								<Link to={`/league/team/${sportsList && sportsList.individual && sportsList.individual.length ? sportsList.individual[0].id : 0}/individual`} className="btn btn-primary">Individual Registration</Link>
								<Link to={`/league/team/${sportsList && sportsList.sign_up && sportsList.sign_up.length ? sportsList.sign_up[0].id : 0}/sign_up`} className="btn btn-primary btn-secondary">Team Registration</Link>
								<Link to={`/today-schedule`} className="btn btn-primary">Today's Schedule</Link>
							</div>
						</div>
					</div>
				</section>
				<section className="sponsors-block our-sponsors">
					<div className="cs-container">
						<div className="sponsors-with-ad">
							<div className="cs-row cs-sponsors-row">
								<div className="c-12">
									<div className="content-sponsor">
										<h3 className="title-main">Our <span>Sponsors</span></h3>
										<p>Sponsors help keep us going and bring great deals and benefits to our customers. Check them out for some discounts or exciting new experiences for the new friends you meet at Cleveland Plays!</p>
										<p>Want to sponsor us? Contact us for more information!</p>
										<div className="btn-block">
											<Link to='/contact' className="btn-primary">Contact Us</Link>
										</div>
									</div>
								</div>
								<div className="c-12 home-page-ads">
									<Carousel
										autoPlay={true}
										emulateTouch={false}
										className="advt-carousel"
										infiniteLoop={true}
										interval={4000}
										showThumbs={false}
										showArrows={false}
										showStatus={false}>
										{
											advertisements && advertisements.length &&
											advertisements.map((ad, idx) => (
												<div key={ad && ad.id ? ad.id : idx} className="slider-sponsor" onClick={() => handleAdClick(ad)}>
													<img src={`${config.api}/${config.imageFolder}/advertisements/${ad.image}`} alt="" />
												</div>
											))
										}
									</Carousel>
								</div>
							</div>
							<div className="ads-section">
								{sponsorCarousel()}
							</div>
						</div>
					</div>
				</section>
				<section className="leagues-block">
					<div className="cs-container">
						<div className="head-leagues-block">
							<h3 className="title-main">Available <span>Leagues</span></h3>
							<p>Sign up today to join the action</p>
							<div className="btn-block">
								<Link to={`/league/team/${sportsList && sportsList.sign_up && sportsList.sign_up.length ? sportsList.sign_up[0].id : 0}/sign_up`} className="btn-primary">Register Now</Link>
							</div>
						</div>
						{<div className="league-cards  home-available-cards">

							{Array.isArray(leagues) && leagues.length ?
								leagues.map((league, idx) => {
									return <div className="league-card" key={idx}>
										<div className="league-card-header">
											<h3>{league.sport && league.sport.title ? toTitleCase(league.sport.title) : 'N/A'} </h3>
										</div>
										<div className="league-card-body">
											<div className="league-card-items">
												{league.name ? (<div className="league-card-item">
													<p className="league-detail-info text-left">{league.name ? toTitleCase(league.name) : 'N/A'}</p>
												</div>) : ""}
												<div className="league-card-item">
													<p className="legue-detail-title">Days of week</p>
													<p className="league-detail-info">{
														Array.isArray((league.days_played)) ? (league.days_played).map((obj, idx) => (
															obj.day + (idx < league.days_played.length - 1 ? ', ' : '')
														)) : 'N/A'
													}</p>
												</div>
												<div className="league-card-item">
													<p className="legue-detail-title">Registration Deadline</p>
													<p className="league-detail-info">{league.reg_deadline ? moment(league.reg_deadline).format('L') : 'N/A'}</p>
												</div>
												<div className="league-card-item">
													<p className="legue-detail-title">Level of Play</p>
													<p className="league-detail-info">{league.play_level ? toTitleCase(league.play_level) : 'N/A'}</p>
												</div>
												<div className="league-card-item">
													<p className="legue-detail-title">Season Length</p>
													<p className="league-detail-info">{league.no_of_weeks ? `${league.no_of_weeks} Week${league.no_of_weeks > 1 ? 's' : ''}` : 'N/A'}</p>
												</div>
												<div className="league-card-item">
													<p className="legue-detail-title">Times</p>
													<p className="league-detail-info">{league.time_slots ? league.time_slots.split(',').join(', ') : 'N/A'}</p>
												</div>
												<div className="league-card-item">
													<p className="legue-detail-title">Hosted by:</p>
													<p className="league-detail-info">{league.hosted_by ? league.hosted_by === HOSTED_BY ? 'Cleveland' : 'Mulberry' : 'N/A'}</p>
												</div>
											</div>
										</div>
										<div className="league-card-actions">
											<Link className="btn btn-dark-gray" to={{
												pathname: `/league/league-details/${league.id}`,
												state: {
													from: 'home',
												},
											}}>
												League Info
												<img src={arrowRight} alt="" />
											</Link>
										</div>
									</div>
								})
								:
								!loader && <div className="noRecord-found">
									<p>No Active Leagues.</p>
								</div>
							}
						</div>
						}
						{/* <div className="cs-row">
							{Array.isArray(leagues) && leagues.length ?
								leagues.map((league, idx) => {
									return <div className="c-3" key={idx}>
										<div className="ui-card-block">
											<div className="ui-content-block">
												<h4>{league.sport && league.sport.title ? toTitleCase(league.sport.title) : 'N/A'}</h4>
												<p>{league.name ? toTitleCase(league.name) : 'N/A'}</p>
												<ul>
													<li>
														<span>Days of week</span>
														<label>
															{
																Array.isArray((league.days_played)) ? (league.days_played).map((obj, idx) => (
																	obj.day + (idx < league.days_played.length - 1 ? ', ' : '')
																)) : 'N/A'
															}
														</label>
													</li>
													<li>
														<span>Registration Deadline</span>
														<label>{league.reg_deadline ? moment(league.reg_deadline).format('L') : 'N/A'}</label>
													</li>
													<li>
														<span>Level of Play</span>
														<label>{league.play_level ? toTitleCase(league.play_level) : 'N/A'}</label>
													</li>
													<li>
														<span>Season Length</span>
														<label>{league.no_of_weeks ? league.no_of_weeks : 'N/A'} {league.no_of_weeks === 1 ? 'Week' : 'Weeks'}</label>
													</li>
													<li>
														<span>Times</span>
														<label>{league.time_slots ? league.time_slots : 'N/A'}</label>
													</li>


													<li>
														<span>Hosted By</span>
														<label>{league.hosted_by ? league.hosted_by === HOSTED_BY ? 'Cleveland' : 'Mulberry' : 'N/A'}</label>
													</li>
												</ul>
											</div>
											<div className="btn-block">
												<Link to={{
													pathname: `/league/league-details/${league.id}`,
													state: {
														from: 'home',
													},
												}} className="btn-primary">View League</Link>
											</div>
										</div>
									</div>
								})
								:
								!loader && <div className="noRecord-found">
									<p>No Active Leagues.</p>
								</div>
							}
						</div> */}
					</div>
				</section>
				<section className="network-block">
					<div className="cs-container">
						<div className="cs-row align-middle">
							<div className="c-6">
								<div className="content-net-block">
									<h3 className="title-main">Grow Your <span>Network</span></h3>
									<div
										dangerouslySetInnerHTML={{
											__html: headline
										}}
									></div>
									<div className="btn-block">
										<Link to='/classified' className="btn-primary">View Classifieds</Link>
									</div>
								</div>
							</div>
							<div className="c-6">
								<figure>
									<img src={figure} alt="" />
								</figure>
							</div>
						</div>
					</div>
				</section>
			</main>
			<Popup open={login} onClose={() => {
				setLogIn(false);
			}} closeOnDocumentClick={true}>
				<Login loginEmail={email} closeLoginModal={() => setLogIn(false)} login={true} openSignUp={() => setSignUp(true)}></Login>
			</Popup>
			<Popup open={signup} onClose={() => {
				setSignUp(false);
			}} closeOnDocumentClick={false}>
				<Signup signupEmail={email} closeSignupModal={() => setSignUp(false)} openLogin={() => setLogIn(true)}></Signup>
			</Popup>
		</>
	);
}
export default withRouter(Home);