import React, { useEffect, useState } from "react";
import { useHistory, withRouter, useLocation } from "react-router-dom";
import "../Account.css";

import beachBallIcon from "../../../assets/icons/beach-ball.png";
import myTeamIcon from "../../../assets/icons/myTeam.svg";
import teamInvitesIcon from "../../../assets/icons/teamInvites.svg";
import profileIcon from "../../../assets/icons/profile.svg";
import leagueHistory from "../../../assets/icons/leagueHistory.svg";
import { useDispatch } from "react-redux";
import { setUser } from "../../../store/reducer/LoggedInUserDetails";
import { ReponsiveSidebarToggle } from "../../../App";
// import classifieds from '../../../assets/icons/phone-call.svg'

const menuItems = [
  {
    label: "Upcoming Matches",
    icon: beachBallIcon,
    url: "upcoming-matches",
  },
  {
    label: "My Teams",
    icon: myTeamIcon,
    url: "teams",
  },
  {
    label: "Team Invites",
    icon: teamInvitesIcon,
    url: "team-invites",
  },
  {
    label: "My Classifieds",
    icon: teamInvitesIcon,
    url: "my-classifieds",
  },
  {
    label: "League History",
    icon: leagueHistory,
    url: "league-history",
  },
  {
    label: "My Profile",
    icon: profileIcon,
    url: "profile",
  },
];

function AccountSidebar(props) {
  const dispatch = useDispatch();
  const search = useLocation();
  const history = useHistory();
  const [menu, setMenu] = useState(menuItems);
  const [toggleClass, setToggleClass] = useState(false);
  const [selectedMenuIndex, setSelectedMenuIndex] = useState(-1);
  const [hide, setHide] = useState(false);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname && location.pathname.indexOf('/my-account') !== -1) {
        setHide(false);
      } else {
        setHide(true);
        if (window.innerWidth && window.innerWidth > 992) {
          setSelectedMenuIndex(0);
        } else {
          setSelectedMenuIndex(-1);
        }
      }
    })
  }, [history]);

  useEffect(() => {
    if (history?.location?.pathname && history?.location?.pathname.indexOf('/my-account') !== -1) {
      setHide(false);
    } else {
      setHide(true);
    }
    if (window.innerWidth && window.innerWidth > 992) {
      setSelectedMenuIndex(0);
    }
  }, [])

  const logout = () => {
    localStorage.clear();
    dispatch(setUser(null));
    history.push("/");
  };
  return (
    <>
      <section className={`gen-sidebar${props?.open ? ' open' : hide ? ' hide-menu' : ''}`}>
        <div className="gen-sidebar-container">
          <div className="gen-sb-header">
            <h3>My Account</h3>
            <span className="close" onClick={() => props?.close(false)}>
              &times;
            </span>
          </div>
          <div className="gen-sb-body">
            <ul className="gen-sb-nav-links">
              {menu.map((m, index) => (
                <li
                  key={`account-sidebar-${index}`}
                  onClick={() => {
                    setSelectedMenuIndex(index);
                    history.push(`/my-account/${m.url}`);
                    if (props.close)
                    props?.close(false);
                  }}
                  className={`sb-nav-link${(search?.pathname.split('/')).includes(m.url) ? ' active' : ''}`}
                >
                  <div className="sb-icon">
                    <img src={m.icon} alt="" />
                  </div>
                  <p className="sb-text">{m.label}</p>
                </li>
              ))}
            </ul>
          </div>
          <div className="gen-sidebar-footer">
            <button onClick={() => {logout(); props?.close(false)}} className="btn">
              Sign Out
            </button>
          </div>
        </div>
      </section>
      <div className={`sidebar-overlay ${props?.open ? ' open' : ''}`} onClick={() => props?.close(false)}></div>
    </>
  );
}
export default withRouter(AccountSidebar);
