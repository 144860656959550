import _ from 'lodash';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router';
import SimpleBarReact from 'simplebar-react';
import { config } from '../../../config';
import axiosApi from '../../../core/interceptor/interceptor';
import './LeagueDetails.css';

export default function LeagueDivision(props) {
  const id = useParams().id;
  const [divisions, setDivisions] = useState([]);
  const [teams, setTeams] = useState([]);
  const [teamsSorted, setTeamsSorted] = useState([]);
  useEffect(() => {
    getLeagueDivision();
    // eslint-disable-next-line
  }, []);

  function getLeagueDivision() {
    axiosApi.get(`${config.apiUrl}/leagues/league-division/${id}`).then((resp) => {
      if (resp.data?.data) {
        setDivisions(resp.data.data?.divisions || []);
        setTeams(resp.data.data?.teams || []);
        if(props?.isDivision) {
          props?.isDivision(resp.data.data);
        }
      }
    }).catch((err) => {
      console.error(err);
    });
  }

  const getWinPercentage = (team) => {
    if (!isNaN(team.win_count) && !isNaN(team.loss_count) && !isNaN(team.tie_count)) {
      const totalMatch = +team.win_count + +team.loss_count + +team.tie_count;
      if (totalMatch) {
        const percentage = (team.win_count / totalMatch).toFixed(2);
        return +percentage;
      }
    }
    return 0;
  }

  const getDivisionByOrder = (division) => {
    const results = Array.isArray(teams) && teams.length ?
      teams.filter((team) => (division?.team_ids || '').split(',')
        .map(teams_id => Number(teams_id)).includes(team.id)) : [];
    if (results && results.length) {
      // for (const iterator of results) {
      //   iterator.win_percentage = getWinPercentage(iterator);
      // }

      // results.sort((a, b) => {
      //   return b.win_percentage - a.win_percentage;
      // });

      return (
        results.map((team_, i) => (
          <div className="league-card-item" key={`division-item-${i + 1}`}>
            <div className="card-td cell-pos">{
              (function (rank) {
                rank = (i + 1).toString();
                const idx = (rank.length - 1);
                if (idx >= 0) {
                  if (Number(rank[idx]) === 1) {
                    return rank + 'st';
                  } else if (Number(rank[idx]) === 2) {
                    return rank + 'nd';
                  } else if (Number(rank[idx]) === 3) {
                    return rank + 'rd';
                  } else {
                    return rank + 'th';
                  }
                }
              }(i))
            }</div>
            <div className="card-td cell-team">{team_?.name}</div>
            <div className="card-td cell-win">{(team_?.win_count || 0)}</div>
            <div className="card-td cell-loss">{(team_?.loss_count || 0)}</div>
            <div className="card-td cell-ties">{(team_?.tie_count || 0)}</div>
            <div className="card-td cell-win-per">{getWinPercentage(team_)}</div>
          </div>
        ))

      );
    } else {
      return (
        <tr>
          <td colSpan={6}></td>
        </tr>
      )
    }
  }

  const getLeagueStandings = () => {
    const results = teams;
    if (results && results.length) {
      return (
        results.map((team_, i) => (
          <div className="league-card-item" key={`division-item-${i + 1}`}>
            <div className="card-td cell-pos">{
              (function (rank) {
                rank = (i + 1).toString();
                const idx = (rank.length - 1);
                if (idx >= 0) {
                  if (Number(rank[idx]) === 1) {
                    return rank + 'st';
                  } else if (Number(rank[idx]) === 2) {
                    return rank + 'nd';
                  } else if (Number(rank[idx]) === 3) {
                    return rank + 'rd';
                  } else {
                    return rank + 'th';
                  }
                }
              }(i))
            }</div>
            <div className="card-td cell-team">{team_?.name}</div>
            <div className="card-td cell-win">{(team_?.win_count || 0)}</div>
            <div className="card-td cell-loss">{(team_?.loss_count || 0)}</div>
            <div className="card-td cell-ties">{(team_?.tie_count || 0)}</div>
            <div className="card-td cell-win-per">{getWinPercentage(team_)}</div>
          </div>
        ))
  
      );
    } else {
      return (
        <tr>
          <td colSpan={6}></td>
        </tr>
      )
    }
  }

  useEffect(() => {
    if (_.isEmpty(divisions)) {
      // for (const iterator of teams) {
      //   iterator.win_percentage = getWinPercentage(iterator);
      // }

      // teams.sort((a, b) => {
      //   return b.win_percentage - a.win_percentage;
      // });
      setTeamsSorted(teams);
    }
  }, [teams, divisions])

  return <>
    <div className="league-cards league-detail-cards">
      {
        Array.isArray(divisions) && divisions.length ?
          divisions.map((division, key) => <div className="league-card" key={`division-league-${key + 1}`}>
            <div className="card-division-name">
              <h4>
                Division - {division?.name || 'N/A'}
              </h4>
            </div>
            <div className="league-card-header">
              <div className="card-th cell-pos">Position</div>
              <div className="card-th cell-team">Team</div>
              <div className="card-th cell-win">Win</div>
              <div className="card-th cell-loss">Loss</div>
              <div className="card-th cell-ties">Ties</div>
              <div className="card-th cell-win-per">Win %</div>
            </div>
            <div className="league-card-body">
              <div className="league-card-items">{getDivisionByOrder(division)}</div>
            </div>
          </div>) 
          :
          <>
            <div className="league-card" key={``}>
              <div className="card-division-name">
                <h4>
                  League Standings
                </h4>
              </div>
              <div className="league-card-header">
                <div className="card-th cell-pos">Position</div>
                <div className="card-th cell-team">Team</div>
                <div className="card-th cell-win">Win</div>
                <div className="card-th cell-loss">Loss</div>
                <div className="card-th cell-ties">Ties</div>
                <div className="card-th cell-win-per">Win %</div>
              </div>
              <div className="league-card-body">
                <div className="league-card-items">{getLeagueStandings()}</div>
              </div>
            </div>
          </>
      }
    </div>
  </>
}