import React, { useEffect, useState } from "react";
import './TeamInviteDetails.css';
import { useHistory, useParams } from "react-router";
import backArrowGreen from "../../../../assets/icons/backArrowGreen.png"
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import { useDispatch, useSelector } from "react-redux";
import { config, REGISTERED_THROUGH } from "../../../../config";
import axiosApi from "../../../../core/interceptor/interceptor";
import { messages } from "../../../../messages";
import { setUser } from "../../../../store/reducer/LoggedInUserDetails";
import { validateRequiredFields } from "../../../../utils/Utils";
import WarningDialogue from "../../../../utils/WarningDialogue";
import ErrorInput from "../../../common/ErrorInput";
import Policy from "../../../common/Policy/Policy";

export default function ViewInvite(props) {

  const [invitation, setInvitation] = useState([]);
  const [formData, setFormData] = useState({
    policies: false,
    covid_policies: false,
  });
  const [errors, setErrors] = useState({ policies: '', covid_policies: '' });
  const [openWarningPopup, setOpenWarningPopup] = useState(false);
  const [popupContent, setPopupContent] = useState({});
  const id = useParams().id;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [policy, setPolicy] = useState({ open: false, type: '' });
  const dispatch = useDispatch();
  const loader = useSelector((state) => state.loader.value);
  useEffect(() => {
    getInvitation();
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    handleValidation();
    //eslint-disable-next-line
  }, [formData]);

  const history = useHistory();
  const getInvitation = () => {
    axiosApi.get(`${config.apiUrl}/team/invitations/${id}`)
      .then((res) => {
        setInvitation(res.data.data);
      }, (err) => {
        console.error(err);
      })
  }

  const handleCheckBox = (e) => {
    setFormData((previousFormData) => ({
      ...previousFormData,
      [e.target.name]: !formData[e.target.name],
    }))
  }

  const handleAcceptence = () => {
    setFormSubmitted(true);
    if (handleValidation()) {
      axiosApi.put(`${config.apiUrl}/misc/validate-token/${invitation.token}`)
        .then((res) => {
          axiosApi.post(`${config.apiUrl}/misc/add-player/${res.data.data.id}`, {
            team_id: res.data.data.team_id,
            user_id: res.data.data.player_id,
            email: res.data.data.email,
            policies: formData.policies,
            covid_policies: formData.covid_policies,
            registered_through: REGISTERED_THROUGH,
          }).then((res) => {
            getUserDetails().then(() => {
              history.push('/my-account');
            });
          }, (err) => {
            console.error(err);
          })
        }, (err) => {
          console.error(err);
        })
    }
  }

  const handleDecline = () => {
    setOpenWarningPopup(true);
    setPopupContent({
      header: 'Decline Invitation?',
      description: 'Are you sure you want to decline invitation?',
      action: () => {
        axiosApi.put(`${config.apiUrl}/misc/validate-token/${invitation.token}`)
          .then(() => {
            axiosApi.delete(`${config.apiUrl}/misc/delete-invitation/${invitation.id}`)
              .then(() => {
                setOpenWarningPopup(false);
                history.push('/my-account');
              }).catch(() => {
                setOpenWarningPopup(false);
              })
          }).catch(() => {
            setOpenWarningPopup(false);
          })
      }
    })
  }

  const handleValidation = () => {
    const errArray = {};
    let isFormValid = true;
    if (validateRequiredFields(formData)) {
      if (!formData.policies || !formData.covid_policies) {
        isFormValid = false;
        errArray['policies'] = messages && messages.selectPolicies;
      }
      setErrors(errArray);
    }
    setErrors(errArray);
    return isFormValid;
  }

  const getUserDetails = () => {
    if (localStorage.getItem('token')) {
      return axiosApi.get(`${config.apiUrl}/user/me`)
        .then((res) => {
          dispatch(setUser(res.data.data));
        });
    }
  }


  return <>
    <main className="wrapper-main">
      <section className="main-side no-sidebar">
        <div className="main-side-container">
          <div className="main-top-headings">
            <div className="league-back-link">
              <a href="#" onClick={(e) => {
                e.preventDefault(); history.push(`/my-account/team-invites`);
              }}>
                <img src={backArrowGreen} alt="" />
                Back
              </a>
            </div>
          </div>
          <div className="main-side-body">
            <div className="cs-row">
              <div className="c-12">
                <h4>Team</h4>
                <div className="team-deatils-inner my-account-inner">
                  <div className="matches-updates">
                    <div className="upcoming-updates">
                      {!loader && <div className="athlete-league">
                        <div className="upcoming-updates-inner">
                          <p>{invitation?.team?.name || 'N/A'}</p>
                        </div>
                      </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h4>Invite Details</h4>
            <div className="my-account-inner invite-blk">
              <div className="matches-updates">
                <p style={{marginTop: 0}}>You've been invited to join the team " {invitation && invitation.team && invitation.team.name} ". Please review our policies and either accept or decline the invitation.</p>
                <div className="feature-check-block account-checked">
                  <div className="checkbox-policy-blk">
                    <label className="custom-checkbox">
                      <input type="checkbox" name='policies' value={formData.policies} onChange={(e) => handleCheckBox(e)} />
                      <span>I have read and fully understand the</span>
                    </label>
                    <span className='link-xJDAFDGADF' onClick={() => setPolicy({
                      open: true,
                      type: 'cancelation',
                    })}>Cancellation and Registration Policies</span>
                  </div>
                  <div className="checkbox-policy-blk">
                    <label className="custom-checkbox">
                      <input type="checkbox" name='covid_policies' value={formData.covid_policies} onChange={(e) => { handleCheckBox(e) }} />
                      <span>I have read and fully understand the</span>
                    </label>
                    <span className='link-xJDAFDGADF' onClick={() => setPolicy({
                      open: true,
                      type: 'covid19',
                    })}>Covid-19 Policies</span>
                    <ErrorInput formSubmitted={formSubmitted} error={errors['policies']} />
                  </div>
                </div>
                <div className="inviting-btn">
                  <span className="btn-secondary" onMouseDown={() => { handleAcceptence() }}>Accept Invitation</span>
                  <span className="btn-primary" onMouseDown={() => { handleDecline() }}>Decline Invitation</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
    <WarningDialogue
      isOpen={openWarningPopup}
      header={popupContent.header}
      description={popupContent.description}
      action={popupContent.action}
      onClose={() => setOpenWarningPopup(false)}
    />
    <Popup open={policy?.open} onClose={() => {
      setPolicy({ open: false, type: '' });
    }} closeOnDocumentClick={true}>
      <Policy type={policy?.type} closeModal={() => setPolicy({ open: false, type: '' })} />
    </Popup>
  </>
}
