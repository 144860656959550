import { Suspense, useEffect, useRef, useState, createContext } from 'react';
import './App.css';
import Header from './component/main/header/Header';
import Footer from './component/main/footer/Footer';
import ScrollToTop from './component/basic/ScrollToTop';
import { LazyLoader } from './utils/Utils';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toast';
import Loader from './utils/Loader';
import { useDispatch, useSelector } from 'react-redux';
import axiosApi from './core/interceptor/interceptor';
import { config } from './config';
import { setUser, userDetails } from './store/reducer/LoggedInUserDetails';
import LoginGuard from './core/guard/LoginGuard';
import LeagueLayOutComponent from './component/league/LeagueLayout';
import Home from './component/home/Home';
import About from './component/about/About';
import Classifieds from './component/classified/Classified';
import Contact from './component/contact/Contact';
import AccountLayoutComponent from './component/account/AccountLayout/AccountLayout';
import ResetPassword from './component/main/resetPassword/ResetPassword';
import InvitePlayer from './component/invitePlayer/InvitePlayer';
import CompletePayment from './component/complete-payment/completePayment';
import Disclaimer from './component/main/signup/disclaimer';
import AccountMenuSidebar from './component/account/AccountLayout/AccountSidebar';
import { useIdleTimer } from 'react-idle-timer';
import WarningDialogue from './utils/WarningDialogue';
import TodaySchedule from './component/today-schedule/today-schedule';

export const ReponsiveSidebarToggle = createContext(false);

function App() {
  const refHeader = useRef(null);
  const [marginTop, setMarginTop] = useState(0);
  const loader = useSelector((state) => state.loader.value);
  const dispatch = useDispatch();
  const [openMenu, setOpenMenu] = useState(false);
  const [isMarquee, setIsMarquee] = useState(false);
  const user = useSelector(userDetails);
  const [showTimeoutPopup, setShowTimeoutPopup] = useState(false);
  const [popupContent, setPopupContent] = useState({});

  useEffect(() => {
    setTimeout(() => {
      refHeader && refHeader.current && setMarginTop(refHeader.current.clientHeight)
    }, 100)
  }, [refHeader, isMarquee]);
  useEffect(() => {
    getUserDetails();
    //eslint-disable-next-line
  }, []);

  const getUserDetails = () => {
    if (localStorage.getItem('token')) {
      axiosApi.get(`${config.apiUrl}/user/me`)
        .then((res) => {
          dispatch(setUser(res.data.data));
        })
    }
  }

  const toggleMenu = (flag) => {
    switch (flag) {
      case undefined:
        setOpenMenu(!openMenu);
        break;

      default:
        setOpenMenu(flag);
        break;
    }
  }

  const isNotificationHeader = (cb) => {
    setIsMarquee(cb);
  }

  /**IdleTimer */
  const handleOnIdle = event => {
    if (user) {
      localStorage.clear();
      dispatch(setUser(null));
      handleSessionLogout();
    } else {
      window.location.reload();
    }
  }

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    debounce: 500
  });

  const handleSessionLogout = () => {
    setShowTimeoutPopup(true);
    setPopupContent({
      header: 'Session Expired',
      description: 'Your session has been expired due to inactivity, please login to continue.',
      action: () => {
        setShowTimeoutPopup(false);
        window.location.reload();
      }
    })
  }

  return (
    <>
      <Router>
        <Header ref={refHeader} notification={isNotificationHeader} openMenu={() => toggleMenu()} />
        <AccountMenuSidebar open={openMenu} close={() => toggleMenu(false)} />
        {
          <div style={{
            marginTop,
          }}>
            <ScrollToTop>
              <Suspense fallback={< LazyLoader />}>
                <Switch>
                  <Route exact={true} path={`/`}>
                    <Home />
                  </Route>
                  <Route exact={true} path={`/about`}>
                    <About />
                  </Route>
                  <Route exact={true} path={`/today-schedule`}>
                    <TodaySchedule />
                  </Route>
                  <Route path={`/league`}>
                    <LeagueLayOutComponent />
                  </Route>
                  <Route exact={true} path={`/classified`}>
                    <Classifieds />
                  </Route>
                  <Route exact={true} path={`/contact`}>
                    <Contact />
                  </Route>
                  <Route path={`/my-account`}>
                    <LoginGuard Component={AccountLayoutComponent} />
                  </Route>
                  <Route exact={true} path={`/reset-password/:token`}>
                    <ResetPassword />
                  </Route>
                  <Route exact={true} path={`/disclaimer`}>
                    <Disclaimer />
                  </Route>
                  <Route exact={true} path={'/player-invitation/:accepted/:token'}>
                    <InvitePlayer />
                  </Route>
                  <Route exact={true} path={'/complete-payment/:token/:type'}>
                    <CompletePayment />
                  </Route>
                </Switch>
              </Suspense >
            </ScrollToTop>
          </div>
        }
        <ToastContainer
          position={'top-right'}
          delay={8000}
        />
        {loader && <Loader />}
        <Footer />
      </Router>
      <WarningDialogue
        isOpen={showTimeoutPopup}
        type={'session'}
        header={popupContent.header}
        description={popupContent.description}
        action={popupContent.action}
        onClose={() => setShowTimeoutPopup(false)}
        closeOnDocumentClick={true}
      />
    </>
  );
}

export default App;
