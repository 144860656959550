import React, { useEffect, useState } from 'react';
import cross from '../../../assets/icons/cross-icon.svg';
import axiosApi from '../../../core/interceptor/interceptor';
import './Policy.css';
import { config, Disclaimers, REGISTERED_THROUGH } from '../../../config';

export default function Policy(props) {
	const [disclaimerData, setDisclaimerData] = useState([]);
	const [type, setType] = useState(props?.type === 'cancelation' ? Disclaimers.team_reg_policy : Disclaimers.covid_policy);

	useEffect(() => {
		setType(props?.type === 'cancelation' ? Disclaimers.team_reg_policy : Disclaimers.covid_policy);
	}, [props?.type]);

	useEffect(() => {
		getDisclaimerInfo();
		// eslint-disable-next-line
	}, [type])


	const getDisclaimerInfo = () => {

		axiosApi.get(`${config.apiUrl}/misc/disclaimer/${REGISTERED_THROUGH}/${type}`)
			.then((res) => {
				if (res && res.data && res.data.data) {
					setDisclaimerData(res.data.data.disclaimer);
				}
			}, (err) => {

			})
	}

	return <>
		<div className="policy-modal">
			<div className="model-popup-box">
				<div className="modal-content">
					<div className="dismiss-popup">
						<div className="close" style={{ cursor: 'pointer' }} onClick={() => props?.closeModal()}><img src={cross} alt="cross-sign" /></div>
					</div>
					<div className="policy-body">
						<p dangerouslySetInnerHTML={{ __html: disclaimerData }}></p>
					</div>
				</div>
			</div>
		</div>
	</>
}