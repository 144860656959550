import { Route } from 'react-router-dom';
import logo from '../assets/images/logo-footer.png';

export function LazyLoader() {
  return (
    <div className="loading-wrapper">
      <img src={logo} alt="logo" />
      <span>Loading...</span>
    </div>
  );
}

export function RouterView({ routes }) {
  console.log('-->', routes);
  return (
    Array.isArray(routes) && routes.map((route) => (
      <Route exact={route.exact} path={route.path} component={route.component} />
    ))
  );
}

export const phoneNumberMask = '999-999-9999';

export function maskingToPlainNumber(number) {
  if (!number) {
    return '';
  }
  number = (number).toString().replace(/[- )(]/g, '');
  return number;
}

export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ('' + (phoneNumberString || '').toString()).replace(/[- )(\D]/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return match[1] + '-' + match[2] + '-' + match[3]
  }
  return null
}

export function validateEmail(email) {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function validateRequiredFields(fieldsObject) {
  const keys = Object.keys(fieldsObject);
  return !!keys.length && keys.some(key => !(fieldsObject[key] || '').toString().trim());
}

export function toTitleCase(str) {
  return str ? str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  ) : '';
}

export const PaymentTeamCreate = {
  save_card: 'card',
  cheque_cash: 'cheque'
}

export function capitaLizeString(str) {
  var i, frags = str.split('_');
  for (i = 0; i < frags.length; i++) {
    frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
  }
  return frags.join(' ');
}