import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import "./PlayOffBracket.css";
import axiosApi from "../../../core/interceptor/interceptor";
import { config } from "../../../config";
import winner from "../../../assets/images/trophy.png";
import { setLoader } from "../../../store/reducer/LoaderReducer";
import { store } from "../../../store/Store";
import { jsPDF } from "jspdf";
import print from "../../../assets/images/print-icon.svg";
import domtoimage from "dom-to-image";
const TBD = {
  name: "TBD",
  value: [null, undefined, "Invalid date", "Invalid Date", "invalid date"],
};

function PlayOffBracket(props) {
  const [leagueId, setLeagueId] = useState(0);
  const [leagueData, setLeagueData] = useState({ teamData: [], leagueInfo: {} });
  const [roundData, setRoundData] = useState([]);
  const [finalRound, setFinalRound] = useState({});
  const [semiFinalRound, setSemiFinalRound] = useState({});
  const [locationField, setLocationField] = useState([]);
  const [isTBD, setIsTBD] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const leagueId_ = props.leagueId;
  const [groupCode, setGroupCode] = useState([]);
  const [images, setImages] = useState([]);
  const [printStart, setPrintingStart] = useState(false);
  useEffect(() => {
    if (leagueId_ > 0) {
      setLeagueId(leagueId_);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (leagueId > 0) {
      getPlayoffBracket();
    }
    //eslint-disable-next-line
  }, [leagueId]);

  const getPlayoffBracket = () => {
    axiosApi.get(`${config.apiUrl}/leagues/schedule/playoff/bracket/${leagueId}`).then((response) => {
      setErrorMessage(response.data.message);
      const tempData = {
        teamData: response.data.teamData,
        leagueInfo: response.data.leagueInfo,
        leagueDivision: response.data.leagueDivision,
        validLeagues: response.data.validLeagues,
      };
      setLeagueData(tempData);
      setIsTBD(response.data.isTBD);
      const data = response.data && response.data.data && response.data.data.divisionData ? response.data.data.divisionData : [];
      data.forEach((division) => {
        if (division.bracketData) {
          division.bracketData.forEach((element, index) => {
            if (element.bracket) {
              let keyIndex = 1;
              if (element.bracket.length <= 2) {
                const bracketElement = [...element.bracket.filter((br) => br.key === "winner")];
                if (element.byes && +element.byes > 0) {
                  while (keyIndex <= +element.byes && keyIndex <= element.bracket.length) {
                    element["newBracket"] = {
                      ...element["newBracket"],
                      ...{
                        [`key${keyIndex}`]: [...[bracketElement.shift()]].filter((item) => item),
                      },
                    };
                    keyIndex++;
                  }
                }
                if (bracketElement.length) {
                  element["newBracket"] = {
                    ...element["newBracket"],
                    ...{
                      [`key${keyIndex}`]: [...bracketElement].filter((item) => item),
                    },
                  };
                }
                // handling 10 team case
                if (+element.byes === 6) {
                  const tempKey2 = element["newBracket"].key2;
                  element["newBracket"] = {
                    ...element["newBracket"],
                    ...{ key2: [], key3: [...tempKey2] },
                  };
                }
              } else {
                const bracketElement = [...element.bracket.filter((br) => br.key === "winner")];
                const lBracketElement = [...element.bracket.filter((br) => br.key === "looser")];
                // adding new checks

                let modifiedBracket = [];
                if (element.byes && +element.byes > 0) {
                  while (keyIndex <= +element.byes && keyIndex <= element.bracket.length) {
                    if ((element.byes === 3 || element.byes === 2) && element.bracketSize === 16) {
                      if (keyIndex > 1) {
                        modifiedBracket = [...[bracketElement.pop()], ...modifiedBracket];
                      } else {
                        element["newBracket"] = {
                          ...element["newBracket"],
                          ...{
                            [`key${keyIndex}`]: [...[bracketElement.shift()]].filter((item) => item),
                          },
                        };
                      }
                    } else {
                      element["newBracket"] = {
                        ...element["newBracket"],
                        ...{
                          [`key${keyIndex}`]: [...[bracketElement.shift()]].filter((item) => item),
                        },
                      };
                    }
                    keyIndex++;
                  }
                }

                do {
                  if (bracketElement.length) {
                    if ((tempData?.validLeagues?.inSignLeague.includes(tempData?.leagueInfo.id) || tempData?.leagueInfo.id > tempData?.validLeagues?.newLeagueId) && element.bracketSize >= 15) {
                      if (!keyIndex && element.bracketSize === 15) {
                        element["newBracket"] = { ...element["newBracket"], ...{ [`key${keyIndex}`]: [...[bracketElement.shift()]].filter((item) => item) } };
                      } else {
                        const first = bracketElement.shift();
                        const second = bracketElement.shift();
                        element["newBracket"] = {
                          ...element["newBracket"],
                          ...{
                            [`key${keyIndex}`]: [...[first], ...[second]].filter((item) => item),
                          },
                        };
                      }
                    } else {
                      if (!index) {
                        if ((element.byes === 2 || element.byes === 1) && element.bracketSize === 16) {
                          element["newBracket"] = {
                            ...element["newBracket"],
                            ...{
                              [`key${keyIndex}`]: [...[bracketElement.shift()], ...[bracketElement.shift()]].filter((item) => item),
                            },
                          };
                        } else {
                          element["newBracket"] = {
                            ...element["newBracket"],
                            ...{
                              [`key${keyIndex}`]: [...[bracketElement.shift()], ...[bracketElement.pop()]].filter((item) => item),
                            },
                          };
                        }
                      } else {
                        element["newBracket"] = {
                          ...element["newBracket"],
                          ...{
                            [`key${keyIndex}`]: [...[bracketElement.shift()], ...[bracketElement.shift()]].filter((item) => item),
                          },
                        };
                      }
                    }
                    keyIndex++;
                  }
                } while (bracketElement.length > 0);

                // adding some more extra checks
                while (modifiedBracket.length > 0) {
                  element["newBracket"] = {
                    ...element["newBracket"],
                    ...{
                      [`key${keyIndex}`]: [...[modifiedBracket.shift()]].filter((item) => item),
                    },
                  };
                  keyIndex++;
                }

                // looser
                do {
                  if (lBracketElement.length) {
                    if (!index) {
                      element["newBracket"] = {
                        ...element["newBracket"],
                        ...{
                          [`key${keyIndex}`]: [...[lBracketElement.shift()], ...[lBracketElement.pop()]].filter((item) => item),
                        },
                      };
                    } else {
                      element["newBracket"] = {
                        ...element["newBracket"],
                        ...{
                          [`key${keyIndex}`]: [...[lBracketElement.shift()], ...[lBracketElement.shift()]].filter((item) => item),
                        },
                      };
                    }
                    keyIndex++;
                  }
                } while (lBracketElement.length > 0);

                // handling 11 team case
                if (+element.byes === 5) {
                  const tempKey2 = element["newBracket"].key2;
                  const tempKey3 = element["newBracket"].key3;
                  element["newBracket"] = {
                    ...element["newBracket"],
                    ...{ key2: [], key3: [...tempKey2], key4: [...tempKey3] },
                  };
                }

                if (element.round > 1) {
                  element["newBracket"] = Object.fromEntries(Object.entries(element["newBracket"]).filter(([_, v]) => v && v.length));
                }
              }
            }
          });
        }
      });
      setRoundData(data);
      setGroupCode(response.data.GroupName);
      setFinalRound(response.data.data && response.data.data.final ? response.data.data.final : []);
      setSemiFinalRound(response.data.data && response.data.data.semiFinal ? response.data.data.semiFinal : []);
      setLocationField(response.data && response.data.data && response.data.data.locations ? response.data.data.locations : []);
    });
  };

  const teamName = (id) => {
    let name = "--";
    if (id && leagueData && leagueData.teamData) {
      if (leagueData && leagueData.leagueDivision && leagueData.leagueDivision.length) {
        let found = false;
        for (const division of leagueData.leagueDivision) {
          const teamIds = division.team_ids.split(",").map((id) => +id);
          if (teamIds && teamIds.length) {
            const filterTeam = leagueData.teamData.filter((x) => teamIds.includes(x.id));
            if (filterTeam && filterTeam.length) {
              const findTeam = filterTeam.find((x) => +x.id === +id);
              if (findTeam) {
                name = divisionTeamName(filterTeam, id);
                found = true;
                break;
              }
            }
          }
        }
        if (!found) {
          name = divisionTeamName(leagueData.teamData, id);
        }
      } else {
        name = divisionTeamName(leagueData.teamData, id);
      }
    }
    return name;
  };

  const divisionTeamName = (teamData, id) => {
    let name = "";
    const findTeam = teamData.find((x) => +x.id === +id);
    if (findTeam) {
      const teamIndex = teamData.indexOf(findTeam);
      name = "TBD";
      if (findTeam.score || findTeam.score === 0) {
        name = `${teamIndex + 1} ${findTeam.name}`;
      }
    }
    return name;
  };

  const locationName = (id) => {
    let name = "";
    if (id && locationField) {
      name = locationField.find((x) => +x.location_id === +id) ? locationField.find((x) => +x.location_id === +id).location_name : "";
    }
    return name;
  };

  const fieldName = (id) => {
    let name = "";
    if (id && locationField) {
      const fields = [].concat.apply(
        [],
        locationField.map((x) => x.fields)
      );
      name = fields.find((x) => +x.field_id === +id) ? fields.find((x) => +x.field_id === +id).field_name : "";
    }
    return name;
  };

  const getDivisionName = (e, team, match) => {
    const code = (string) => {
      const stringBreak = string.split(" ");
      const code = stringBreak[stringBreak.length - 1].replace(/[0-9]/g, "");
      const index = groupCode.findIndex((x) => x === code);
      if (index !== -1) {
        return roundData[index]?.name;
      } else {
        return "";
      }
    };

    if (team === 1) {
      if (e.team1_id > 0) {
        const division = leagueData.leagueDivision.find((x) => x.team_ids.includes(e.team1_id));
        return division?.name || "";
      } else if (match !== "final") {
        return code(e.note1);
      }
    } else {
      if (e.team2_id > 0) {
        const division = leagueData.leagueDivision.find((x) => x.team_ids.includes(e.team2_id));
        return division?.name || "";
      } else if (match !== "final") {
        return code(e.note2);
      }
    }
  };

  const getImagaeDataUrl = async (element) => {
    return await domtoimage.toPng(element, {
      quality: 1,
      width: 1200,
      height: 900,
      canvasWidth: 1200,
      backgroundColor: "#fff",
      skipFonts: true,
      fontEmbedCSS: "",
      style: "",
    });
  };

  const convertImage = async () => {
    setPrintingStart(true);
    store.dispatch(setLoader(true));
    setTimeout(async () => {
      const images = [];
      const firstDivision = document.getElementById("first-division");
      const secondDivision = document.getElementById("second-division");
      const thirdDivision = document.getElementById("third-division");
      const fourthDivision = document.getElementById("forth-division");
      const semi1 = document.getElementById("semi-final-first");
      const final = document.getElementById("final-first");

      try {
        const img = await getImagaeDataUrl(firstDivision);
        images.push({
          src: img,
        });

        if (secondDivision) {
          const img = await getImagaeDataUrl(secondDivision);
          images.push({
            src: img,
          });
        }
        if (thirdDivision) {
          const img = await getImagaeDataUrl(thirdDivision);
          images.push({
            src: img,
          });
        }

        if (fourthDivision) {
          const img = await getImagaeDataUrl(fourthDivision);
          images.push({
            src: img,
          });
        }

        if (semi1) {
          const img = await getImagaeDataUrl(semi1);
          images.push({
            src: img,
          });
        }

        if (final) {
          const img = await getImagaeDataUrl(final);
          images.push({
            src: img,
          });
        }
        const doc = new jsPDF("l", "mm", "a4");
        doc.deletePage(1);
        images.forEach((image) => {
          doc.addPage();
          if (images.semi) {
            doc.addImage(image.src, "PNG", 10, 10, 250, 190);
          } else {
            doc.addImage(image.src, "PNG", 10, 10, 250, 190);
          }
        });

        const pdfURL = doc.output("bloburl");
        window.open(pdfURL, "_blank");
        setImages(images);
        setPrintingStart(false);
        store.dispatch(setLoader(false));
      } catch (e) {
        setPrintingStart(false);
        store.dispatch(setLoader(false));
        console.log("-error-", e);
      }
    }, 500);
  };

  const getDateFormat = (date) => {
    return date && date !== "Invalid date" ? date : "";
  };

  const getExtraClass = () => {
    const getDivision = (l, r, index) => {
      const d = l.leagueDivision.find((d) => d.name === r[index].name);
      if (d && d.team_ids.split(",").length < l.leagueInfo.per_division_teams) {
        return d.team_ids.split(",").length;
      }
      return false;
    };
    if (leagueData.leagueInfo.per_division_teams > 0) {
      if (roundData && roundData.length === 2) {
        const result = getDivision(leagueData, roundData, 1);
        return result ? `d-tr-${result}` : "";
      } else if (roundData && roundData.length === 3) {
        const result = getDivision(leagueData, roundData, 2);
        return result ? `d-bl-${result}` : "";
      }
    }
    return "";
  };

  const getExtraClassNoSetting = () => {
    const getDivision = (l, r, index) => {
      const temps = l.leagueDivision[0].team_ids.split(",").length;
      const d = l.leagueDivision.find((d) => d.name === r[index].name);
      if (d && d.team_ids.split(",").length < temps) {
        return d.team_ids.split(",").length;
      }
      return false;
    };
    if (!leagueData?.leagueInfo?.per_division_teams) {
      if (leagueData?.leagueDivision?.length) {
        const divisionClass = `division-${leagueData.leagueDivision[0].team_ids.split(",").length}`;
        if (roundData && roundData.length === 2) {
          const result = getDivision(leagueData, roundData, 1);
          return result ? `${divisionClass} d-tr-${result}` : "";
        } else if (roundData && roundData.length === 3) {
          const result = getDivision(leagueData, roundData, 2);
          return result ? `${divisionClass} d-bl-${result}` : "";
        }
      }
    }
    return "";
  };
  return (
    <>
      {leagueData && leagueData.leagueInfo && (
        <div className={`playoff-bracket schedule-create-block ${leagueData.leagueInfo.per_division_teams > 0 ? `division-${leagueData.leagueInfo.per_division_teams}` : ""} ${getExtraClass()} ${getExtraClassNoSetting()}`}>
          <div className='league-brackets-heading'>
            {props && !props.noHeading && <h4>League Playoffs</h4>}
            <button className='btn print-btn' onClick={() => convertImage()}>
              <img src={print} alt='print-img' />
            </button>
          </div>
          <div className={`outer-wrapper ${roundData.length === 3 ? "final-3" : `${roundData.length === 2 ? "final-2" : roundData.length === 4 ? "final-4" : ""}`} ${leagueData.leagueInfo?.elimination_type === "Double Elimination" ? "double-bracket-block" : ""}`}>
            <div className={`inner-wrapper ${roundData.length === 1 ? "center-playoff" : ""}`}>
              <div className='leftBrackets-blk' id='first-division'>
                {roundData.length > 0 && roundData[0] && (
                  <>
                    {roundData[0].name && (
                      <div className='division-label'>
                        <strong>Division - {roundData[0].name}</strong>
                      </div>
                    )}
                    {roundData[0].bracketData.length > 0 &&
                      roundData[0].bracketData.map((roundD, i) => (
                        <>
                          {i !== roundData[0].bracketData.length - 1 ? (
                            <div className={`col-blk level-${i + 1}`} key={`${roundD.round}_${i}`}>
                              {roundD.newBracket &&
                                Object.keys(roundD.newBracket).map((key, index) => (
                                  <div className={`inner-blk  ${roundD.newBracket[key].length === 1 ? "single-blk single-cell" : `${!roundD.newBracket[key].length ? "empty-cell" : ""}`}`} key={`${key}-${index}`}>
                                    {roundD.newBracket[key].map((bracketD) => (
                                      <div className={`content-blk ${i && (bracketD.schedule.note1 || bracketD.schedule.note2) ? "before" : ""}`} key={bracketD.name}>
                                        <div className='date-blk'>
                                          <span className='date'>{i > 0 && !bracketD?.show_tbd_date ? (!TBD.value.includes(bracketD?.time) ? TBD.name : "") : getDateFormat(bracketD?.date)}</span>
                                          <span className='time'>{TBD.value.includes(bracketD?.time) ? TBD.name : bracketD?.time}</span>
                                        </div>
                                        <div className={`name-blk ${!isTBD ? "cursor-pointer" : ""}`}>
                                          <div className='row'>
                                            {bracketD?.schedule &&
                                              ((e) => {
                                                if (e.team1_id) {
                                                  return (
                                                    <div className='name'>
                                                      <span>{teamName(e.team1_id)}</span>
                                                    </div>
                                                  );
                                                } else {
                                                  return (
                                                    <div className='name'>
                                                      <span>{e.note1 ? e.note1 : "--"}</span>
                                                    </div>
                                                  );
                                                }
                                              })(bracketD.schedule)}
                                            <div className='grey-blk'>
                                              <span>{bracketD?.schedule?.team1_score}</span>
                                            </div>
                                          </div>
                                          <div className='row'>
                                            {bracketD?.schedule &&
                                              ((e) => {
                                                if (e.team2_id) {
                                                  return (
                                                    <div className='name'>
                                                      <span>{teamName(e.team2_id)}</span>
                                                    </div>
                                                  );
                                                } else {
                                                  return (
                                                    <div className='name'>
                                                      <span>{e.note2 ? e.note2 : "--"}</span>
                                                    </div>
                                                  );
                                                }
                                              })(bracketD.schedule)}
                                            <div className='grey-blk'>
                                              <span>{bracketD?.schedule?.team2_score}</span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className='team-name'>
                                          <span>
                                            {bracketD?.name} - {fieldName(bracketD?.field_id)} - {locationName(bracketD?.location_id)}
                                          </span>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ))}
                            </div>
                          ) : (
                            // single-champion-blk
                            <div className={`col-blk level-${(i === 1 || i === 2) && Object.keys(roundD.newBracket).length === 1 && roundData.length === 1 ? `${i}` : `${i + 1}`} ${roundData.length === 1 ? "single-champion-blk" : ""}`} key={`${roundD.round}_${i}`}>
                              {roundD.newBracket &&
                                Object.keys(roundD.newBracket).map((key, index) => (
                                  <div className={`inner-blk no-after  ${roundD.newBracket[key].length === 1 ? "single-blk final-blk" : ""}`} key={`${key}-${index}-${roundD.round}`}>
                                    {roundD.newBracket[key].map((last) => (
                                      <>
                                        {roundData.length === 1 && <span className='left-final'>Final</span>}
                                        <div className={`content-blk before ${roundData.length > 1 ? "after" : "no-after"}`} key={last.name}>
                                          <div className='date-blk'>
                                            <span className='date'>{!last?.show_tbd_date ? (!TBD.value.includes(last?.time) ? TBD.name : "") : getDateFormat(last?.date)}</span>
                                            <span className='time'>{TBD.value.includes(last?.time) ? TBD.name : last?.time}</span>
                                          </div>
                                          <div className={`name-blk ${!isTBD ? "cursor-pointer" : ""}`}>
                                            <div className='row'>
                                              {last?.schedule &&
                                                ((e) => {
                                                  if (e.team1_id) {
                                                    return (
                                                      <div className='name'>
                                                        <span>{teamName(e.team1_id)}</span>
                                                      </div>
                                                    );
                                                  } else {
                                                    return (
                                                      <div className='name'>
                                                        <span>{e.note1 ? e.note1 : "--"}</span>
                                                      </div>
                                                    );
                                                  }
                                                })(last.schedule)}
                                              <div className='grey-blk'>
                                                <span>{last?.schedule?.team1_score}</span>
                                              </div>
                                            </div>
                                            <div className='row'>
                                              {last?.schedule &&
                                                ((e) => {
                                                  if (e.team2_id) {
                                                    return (
                                                      <div className='name'>
                                                        <span>{teamName(e.team2_id)}</span>
                                                      </div>
                                                    );
                                                  } else {
                                                    return (
                                                      <div className='name'>
                                                        <span>{e.note2 ? e.note2 : "--"}</span>
                                                      </div>
                                                    );
                                                  }
                                                })(last.schedule)}
                                              <div className='grey-blk'>
                                                <span>{last?.schedule?.team2_score}</span>
                                              </div>
                                            </div>
                                          </div>
                                          <div className='team-name'>
                                            <span>
                                              {last?.name} - {fieldName(last?.field_id)} - {locationName(last?.location_id)}
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    ))}
                                  </div>
                                ))}
                              {leagueData.leagueInfo && leagueData.leagueInfo.Champion && roundData.length === 1 && (
                                <div className='champion-league'>
                                  <div className='champion-blk-inner'>
                                    <h2>CHAMPION</h2>
                                    <div className='winner-blk-inner'>
                                      <img src={winner} alt='trophy' />
                                      <span>{leagueData.leagueInfo.Champion?.name}</span>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      ))}
                  </>
                )}
              </div>
              {roundData && roundData.length > 1 && finalRound.length > 0 && (
                <div className={`champion-blk ${leagueData.leagueInfo && leagueData.leagueInfo.Champion ? "winner-exist" : ""}`}>
                  {roundData.length === 2 && (
                    <div className='semiFinal-wrapper' id='final-first' style={{ paddingTop: `${printStart ? "25px" : ""}` }}>
                      {finalRound.map((finalP, index) => (
                        <div className='Final-blk-wrapper' key={`${finalP.roundD}-${index}`}>
                          <span>Final</span>
                          {finalP.bracket &&
                            finalP.bracket.map((finalC, i) => (
                              <div className='content-blk no-after' key={`${finalC.name}-${i}`}>
                                <div className='date-blk'>
                                  <span className='date'>{!finalC?.show_tbd_date ? (!TBD.value.includes(finalC?.time) ? TBD.name : "") : getDateFormat(finalC?.date)}</span>
                                  <span className='time'>{TBD.value.includes(finalC?.time) ? TBD.name : finalC?.time}</span>
                                </div>
                                <div className={`name-blk ${!isTBD ? "cursor-pointer" : ""}`}>
                                  <div className='row'>
                                    {finalC?.schedule &&
                                      ((e) => {
                                        if (e.team1_id) {
                                          return (
                                            <div className='name'>
                                              <span>{teamName(e.team1_id)}</span>
                                              <span>
                                                <strong>({getDivisionName(e, 1)})</strong>
                                              </span>
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div className='name'>
                                              <span>{e.note1 ? e.note1 : "--"}</span>
                                              <span>
                                                <strong>({getDivisionName(e, 1)})</strong>
                                              </span>
                                            </div>
                                          );
                                        }
                                      })(finalC.schedule)}
                                    <div className='grey-blk'>
                                      <span>{finalC?.schedule?.team1_score}</span>
                                    </div>
                                  </div>
                                  <div className='row'>
                                    {finalC?.schedule &&
                                      ((e) => {
                                        if (e.team2_id) {
                                          return (
                                            <div className='name'>
                                              <span>{teamName(e.team2_id)}</span>
                                              <span>
                                                <strong>({getDivisionName(e, 2)})</strong>
                                              </span>
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div className='name'>
                                              <span>{e.note2 ? e.note2 : "--"}</span>
                                              <span>
                                                <strong>({getDivisionName(e, 2)})</strong>
                                              </span>
                                            </div>
                                          );
                                        }
                                      })(finalC.schedule)}
                                    <div className='grey-blk'>
                                      <span>{finalC?.schedule?.team2_score}</span>
                                    </div>
                                  </div>
                                </div>
                                <div className='team-name'>
                                  <span>
                                    {finalC?.name} - {fieldName(finalC?.field_id)} - {locationName(finalC?.location_id)}
                                  </span>
                                </div>
                              </div>
                            ))}

                          {leagueData.leagueInfo && leagueData.leagueInfo.Champion && (
                            <div className='winner-blk'>
                              <h2>CHAMPION</h2>
                              <div className='winner-blk-inner'>
                                <img src={winner} alt='trophy' />
                                <span>{leagueData.leagueInfo.Champion?.name}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}

              {roundData.length > 0 && roundData[1] && (
                <div className='rightBrackets-blk' id='second-division'>
                  <div className='division-label right'>
                    <strong>Division - {roundData[1].name}</strong>
                  </div>
                  {roundData[1].bracketData.length > 0 &&
                    roundData[1].bracketData
                      .slice(0)
                      .reverse()
                      .map((roundD, i) =>
                        i !== 0 ? (
                          <div className={`col-blk level-${roundData[1].bracketData.length - i}`} key={`${roundData[1].name}-${roundD.round}`}>
                            {roundD.newBracket &&
                              Object.keys(roundD.newBracket).map((key, index) => (
                                <div className={`inner-blk  ${roundD.newBracket[key].length === 1 ? "single-blk single-cell" : `${!roundD.newBracket[key].length ? "empty-cell" : ""}`}`} key={`${key}-${index}`}>
                                  {roundD.newBracket[key].map((bracketD) => (
                                    <div className={`content-blk ${i && (bracketD.schedule.note1 || bracketD.schedule.note2) ? "after" : ""}`} key={bracketD.name}>
                                      <div className='date-blk'>
                                        <span className='date'>{i < roundData[1].bracketData.length - 1 && !bracketD?.show_tbd_date ? (!TBD.value.includes(bracketD?.time) ? TBD.name : "") : getDateFormat(bracketD?.date)}</span>
                                        <span className='time'>{TBD.value.includes(bracketD?.time) ? TBD.name : bracketD?.time}</span>
                                      </div>
                                      <div className={`name-blk ${!isTBD ? "cursor-pointer" : ""}`}>
                                        <div className='row'>
                                          {bracketD?.schedule &&
                                            ((e) => {
                                              if (e.team1_id) {
                                                return (
                                                  <div className='name'>
                                                    <span>{teamName(e.team1_id)}</span>
                                                  </div>
                                                );
                                              } else {
                                                return (
                                                  <div className='name'>
                                                    <span>{e.note1 ? e.note1 : "--"}</span>
                                                  </div>
                                                );
                                              }
                                            })(bracketD.schedule)}
                                          <div className='grey-blk'>
                                            <span>{bracketD?.schedule?.team1_score}</span>
                                          </div>
                                        </div>
                                        <div className='row'>
                                          {bracketD?.schedule &&
                                            ((e) => {
                                              if (e.team2_id) {
                                                return (
                                                  <div className='name'>
                                                    <span>{teamName(e.team2_id)}</span>
                                                  </div>
                                                );
                                              } else {
                                                return (
                                                  <div className='name'>
                                                    <span>{e.note2 ? e.note2 : "--"}</span>
                                                  </div>
                                                );
                                              }
                                            })(bracketD.schedule)}
                                          <div className='grey-blk'>
                                            <span>{bracketD?.schedule?.team2_score}</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='team-name'>
                                        <span>
                                          {bracketD?.name} - {fieldName(bracketD?.field_id)} - {locationName(bracketD?.location_id)}
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ))}
                          </div>
                        ) : (
                          <div className={`col-blk level-${Object.keys(roundD.newBracket).length === 1 && roundData[1].bracketData.length === 3 ? `${roundData[1].bracketData.length - i} champion-before` : `${roundData[1].bracketData.length - i}`}`} key={`${roundD.round}_${i}`}>
                            {roundD.newBracket &&
                              Object.keys(roundD.newBracket).map((key, index) => (
                                <div className={`inner-blk no-before ${roundD.newBracket[key].length === 1 ? "single-blk" : ""}`} key={`${key}-${index}-${roundD.round}`}>
                                  {roundD.newBracket[key].map((last) => (
                                    <div className='content-blk after no-before' key={last.name}>
                                      <div className='date-blk'>
                                        <span className='date'>{!last?.show_tbd_date ? (!TBD.value.includes(last?.time) ? TBD.name : "") : getDateFormat(last?.date)}</span>
                                        <span className='time'>{TBD.value.includes(last?.time) ? TBD.name : last?.time}</span>
                                      </div>
                                      <div className={`name-blk ${!isTBD ? "cursor-pointer" : ""}`}>
                                        <div className='row'>
                                          {last?.schedule &&
                                            ((e) => {
                                              if (e.team1_id) {
                                                return (
                                                  <div className='name'>
                                                    <span>{teamName(e.team1_id)}</span>
                                                  </div>
                                                );
                                              } else {
                                                return (
                                                  <div className='name'>
                                                    <span>{e.note1 ? e.note1 : "--"}</span>
                                                  </div>
                                                );
                                              }
                                            })(last.schedule)}
                                          <div className='grey-blk'>
                                            <span>{last?.schedule?.team1_score}</span>
                                          </div>
                                        </div>
                                        <div className='row'>
                                          {last?.schedule &&
                                            ((e) => {
                                              if (e.team2_id) {
                                                return (
                                                  <div className='name'>
                                                    <span>{teamName(e.team2_id)}</span>
                                                  </div>
                                                );
                                              } else {
                                                return (
                                                  <div className='name'>
                                                    <span>{e.note2 ? e.note2 : "--"}</span>
                                                  </div>
                                                );
                                              }
                                            })(last.schedule)}
                                          <div className='grey-blk'>
                                            <span>{last?.schedule?.team2_score}</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='team-name'>
                                        <span>
                                          {last?.name} - {fieldName(last?.field_id)} - {locationName(last?.location_id)}
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ))}
                          </div>
                        )
                      )}
                </div>
              )}
            </div>

            {roundData && roundData.length > 1 && finalRound.length > 0 && roundData.length !== 2 && (
              <div className={`semiFinal-wrapper ${roundData.length === 3 ? "three-division" : `${roundData.length === 4 ? "four-division" : ""}`}`} id='semi-final-first' style={{ paddingTop: `${printStart ? "25px" : ""}` }}>
                {semiFinalRound.length > 0 &&
                  semiFinalRound[0] &&
                  semiFinalRound.map((semiF, index) => (
                    <div className='semiFinal-blk-wrapper' key={`${semiF.roundD}-${index}`}>
                      <span>Semifinal</span>
                      {semiF.bracket && semiF.bracket[0] && (
                        <div className='semiFinal-blk'>
                          <div className='content-blk no-after'>
                            <div className='date-blk'>
                              <span className='date'>{!semiF.bracket[0]?.show_tbd_date ? (!TBD.value.includes(semiF.bracket[0]?.time) ? TBD.name : "") : getDateFormat(semiF.bracket[0]?.date)}</span>
                              <span className='time'>{TBD.value.includes(semiF.bracket[0]?.time) ? TBD.name : semiF.bracket[0]?.time}</span>
                            </div>
                            <div className={`name-blk ${!isTBD ? "cursor-pointer" : ""}`}>
                              <div className='row'>
                                {semiF.bracket[0]?.schedule &&
                                  ((e) => {
                                    if (e.team1_id) {
                                      return (
                                        <div className='name'>
                                          <span>{teamName(e.team1_id)}</span>
                                          <span>
                                            <strong>({getDivisionName(e, 1)})</strong>
                                          </span>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div className='name'>
                                          <span>{e.note1 ? e.note1 : "--"}</span>
                                          <span>
                                            <strong>({getDivisionName(e, 1)})</strong>
                                          </span>
                                        </div>
                                      );
                                    }
                                  })(semiF.bracket[0].schedule)}
                                <div className='grey-blk'>
                                  <span>{semiF.bracket[0]?.schedule?.team1_score}</span>
                                </div>
                              </div>
                              <div className='row'>
                                {semiF.bracket[0]?.schedule &&
                                  ((e) => {
                                    if (e.team2_id) {
                                      return (
                                        <div className='name'>
                                          <span>{teamName(e.team2_id)}</span>
                                          <span>
                                            <strong>({getDivisionName(e, 2)})</strong>
                                          </span>
                                        </div>
                                      );
                                    } else {
                                      return (
                                        <div className='name'>
                                          <span>{e.note2 ? e.note2 : "--"}</span>
                                          <span>
                                            <strong>({getDivisionName(e, 2)})</strong>
                                          </span>
                                        </div>
                                      );
                                    }
                                  })(semiF.bracket[0].schedule)}
                                <div className='grey-blk'>
                                  <span>{semiF.bracket[0]?.schedule?.team2_score}</span>
                                </div>
                              </div>
                            </div>
                            <div className='team-name'>
                              <span>
                                {semiF.bracket[0]?.name} - {fieldName(semiF.bracket[0]?.field_id)} - {locationName(semiF.bracket[0]?.location_id)}
                              </span>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}

                {finalRound.map((finalP, index) => (
                  <div className='Final-blk-wrapper' key={`${finalP.roundD}-${index}`}>
                    <span>Final</span>
                    {finalP.bracket &&
                      finalP.bracket.map((finalC, i) => (
                        <div className='content-blk no-after' key={`${finalC.name}-${i}`}>
                          <div className='date-blk'>
                            <span className='date'>{finalC?.show_tbd_date ? (!TBD.value.includes(finalC?.time) ? TBD.name : "") : getDateFormat(finalC?.date)}</span>
                            <span className='time'>{TBD.value.includes(finalC?.time) ? TBD.name : finalC?.time}</span>
                          </div>
                          <div className={`name-blk ${!isTBD ? "cursor-pointer" : ""}`}>
                            <div className='row'>
                              {finalC?.schedule &&
                                ((e) => {
                                  if (e.team1_id) {
                                    return (
                                      <div className='name'>
                                        <span>{teamName(e.team1_id)}</span>
                                        <span>
                                          <strong>({getDivisionName(e, 1, "final")})</strong>
                                        </span>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div className='name'>
                                        <span>{e.note1 ? e.note1 : "--"}</span>
                                      </div>
                                    );
                                  }
                                })(finalC.schedule)}
                              <div className='grey-blk'>
                                <span>{finalC?.schedule?.team1_score}</span>
                              </div>
                            </div>
                            <div className='row'>
                              {finalC?.schedule &&
                                ((e) => {
                                  if (e.team2_id) {
                                    return (
                                      <div className='name'>
                                        <span>{teamName(e.team2_id)}</span>
                                        <span>
                                          <strong>({getDivisionName(e, 2, "final")})</strong>
                                        </span>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div className='name'>
                                        <span>{e.note2 ? e.note2 : "--"}</span>
                                      </div>
                                    );
                                  }
                                })(finalC.schedule)}
                              <div className='grey-blk'>
                                <span>{finalC?.schedule?.team2_score}</span>
                              </div>
                            </div>
                          </div>
                          <div className='team-name'>
                            <span>
                              {finalC?.name} - {fieldName(finalC?.field_id)} - {locationName(finalC?.location_id)}
                            </span>
                          </div>
                        </div>
                      ))}

                    {leagueData.leagueInfo && leagueData.leagueInfo.Champion && (
                      <div className='winner-blk'>
                        <h2>CHAMPION</h2>
                        <div className='winner-blk-inner'>
                          <img src={winner} alt='trophy' />
                          <span>{leagueData.leagueInfo.Champion?.name}</span>
                        </div>
                      </div>
                    )}
                  </div>
                ))}

                {semiFinalRound.length > 0 &&
                  semiFinalRound[0] &&
                  semiFinalRound[0].bracket.length > 1 &&
                  semiFinalRound.map((semiF, index) => (
                    <div className='semiFinal-blk-wrapper' key={`${semiF.roundD}-${index}`}>
                      <span>Semifinal</span>
                      {semiF.bracket && semiF.bracket[1] && (
                        <div className='content-blk no-after'>
                          <div className='date-blk'>
                            <span className='date'>{!semiF.bracket[1]?.show_tbd_date ? (!TBD.value.includes(semiF.bracket[1]?.time) ? TBD.name : "") : getDateFormat(semiF.bracket[1]?.date)}</span>
                            <span className='time'>{TBD.value.includes(semiF.bracket[1]?.time) ? TBD.name : semiF.bracket[1]?.time}</span>
                          </div>
                          <div className={`name-blk ${!isTBD ? "cursor-pointer" : ""}`}>
                            <div className='row'>
                              {semiF.bracket[1]?.schedule &&
                                ((e) => {
                                  if (e.team1_id) {
                                    return (
                                      <div className='name'>
                                        <span>{teamName(e.team1_id)}</span>
                                        <span>
                                          <strong>({getDivisionName(e, 1)})</strong>
                                        </span>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div className='name'>
                                        <span>{e.note1 ? e.note1 : "--"}</span>
                                        <span>
                                          <strong>({getDivisionName(e, 1)})</strong>
                                        </span>
                                      </div>
                                    );
                                  }
                                })(semiF.bracket[1].schedule)}
                              <div className='grey-blk'>
                                <span>{semiF.bracket[1]?.schedule?.team1_score}</span>
                              </div>
                            </div>
                            <div className='row'>
                              {semiF.bracket[1]?.schedule &&
                                ((e) => {
                                  if (e.team2_id) {
                                    return (
                                      <div className='name'>
                                        <span>{teamName(e.team2_id)}</span>
                                        <span>
                                          <strong>({getDivisionName(e, 2)})</strong>
                                        </span>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div className='name'>
                                        <span>{e.note2 ? e.note2 : "--"}</span>
                                        <span>
                                          <strong>({getDivisionName(e, 2)})</strong>
                                        </span>
                                      </div>
                                    );
                                  }
                                })(semiF.bracket[1].schedule)}
                              <div className='grey-blk'>
                                <span>{semiF.bracket[1]?.schedule?.team2_score}</span>
                              </div>
                            </div>
                          </div>
                          <div className='team-name'>
                            <span>
                              {semiF.bracket[1]?.name} - {fieldName(semiF.bracket[1]?.field_id)} - {locationName(semiF.bracket[1]?.location_id)}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            )}

            <div className='inner-wrapper inner-wrapper-bottom'>
              {roundData.length > 0 && roundData[2] && (
                <div className='leftBrackets-blk' id='third-division'>
                  <>
                    <div className='division-label'>
                      <strong>Division - {roundData[2].name}</strong>
                    </div>
                    {roundData[2].bracketData.length > 0 &&
                      roundData[2].bracketData.map((roundD, i) => (
                        <>
                          {i !== roundData[0].bracketData.length - 1 ? (
                            <div className={`col-blk level-${i + 1}`} key={`${roundD.round}_${i}`}>
                              {roundD.newBracket &&
                                Object.keys(roundD.newBracket).map((key, index) => (
                                  <div className={`inner-blk  ${roundD.newBracket[key].length === 1 ? "single-blk single-cell" : `${!roundD.newBracket[key].length ? "empty-cell" : ""}`}`} key={`${key}-${index}`}>
                                    {roundD.newBracket[key].map((bracketD) => (
                                      <div className={`content-blk ${i && (bracketD.schedule.note1 || bracketD.schedule.note2) ? "before" : ""}`} key={bracketD.name}>
                                        <div className='date-blk'>
                                          <span className='date'>{!bracketD?.show_tbd_date && i > 0 ? (!TBD.value.includes(bracketD?.time) ? TBD.name : "") : getDateFormat(bracketD?.date)}</span>
                                          <span className='time'>{TBD.value.includes(bracketD?.time) ? TBD.name : bracketD?.time}</span>
                                        </div>
                                        <div className={`name-blk ${!isTBD ? "cursor-pointer" : ""}`}>
                                          <div className='row'>
                                            {bracketD?.schedule &&
                                              ((e) => {
                                                if (e.team1_id) {
                                                  return (
                                                    <div className='name'>
                                                      <span>{teamName(e.team1_id)}</span>
                                                    </div>
                                                  );
                                                } else {
                                                  return (
                                                    <div className='name'>
                                                      <span>{e.note1 ? e.note1 : "--"}</span>
                                                    </div>
                                                  );
                                                }
                                              })(bracketD.schedule)}
                                            <div className='grey-blk'>
                                              <span>{bracketD?.schedule?.team1_score}</span>
                                            </div>
                                          </div>
                                          <div className='row'>
                                            {bracketD?.schedule &&
                                              ((e) => {
                                                if (e.team2_id) {
                                                  return (
                                                    <div className='name'>
                                                      <span>{teamName(e.team2_id)}</span>
                                                    </div>
                                                  );
                                                } else {
                                                  return (
                                                    <div className='name'>
                                                      <span>{e.note2 ? e.note2 : "--"}</span>
                                                    </div>
                                                  );
                                                }
                                              })(bracketD.schedule)}
                                            <div className='grey-blk'>
                                              <span>{bracketD?.schedule?.team2_score}</span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className='team-name'>
                                          <span>
                                            {bracketD?.name} - {fieldName(bracketD?.field_id)} - {locationName(bracketD?.location_id)}
                                          </span>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ))}
                            </div>
                          ) : (
                            <div className={`col-blk level-${i + 1}`} key={`${roundD.round}_${i}`}>
                              {roundD.newBracket &&
                                Object.keys(roundD.newBracket).map((key, index) => (
                                  <div className={`inner-blk no-after ${roundD.newBracket[key].length === 1 ? "single-blk" : ""}`} key={`${key}-${index}-${roundD.round}`}>
                                    {roundD.newBracket[key].map((last) => (
                                      <div className={`content-blk before`} key={last.name}>
                                        <div className='date-blk'>
                                          <span className='date'>{!last?.show_tbd_date ? (!TBD.value.includes(last?.time) ? TBD.name : "") : getDateFormat(last?.date)}</span>
                                          <span className='time'>{TBD.value.includes(last?.time) ? TBD.name : last?.time}</span>
                                        </div>
                                        <div className={`name-blk ${!isTBD ? "cursor-pointer" : ""}`}>
                                          <div className='row'>
                                            {last?.schedule &&
                                              ((e) => {
                                                if (e.team1_id) {
                                                  return (
                                                    <div className='name'>
                                                      <span>{teamName(e.team1_id)}</span>
                                                    </div>
                                                  );
                                                } else {
                                                  return (
                                                    <div className='name'>
                                                      <span>{e.note1 ? e.note1 : "--"}</span>
                                                    </div>
                                                  );
                                                }
                                              })(last.schedule)}
                                            <div className='grey-blk'>
                                              <span>{last?.schedule?.team1_score}</span>
                                            </div>
                                          </div>
                                          <div className='row'>
                                            {last?.schedule &&
                                              ((e) => {
                                                if (e.team2_id) {
                                                  return (
                                                    <div className='name'>
                                                      <span>{teamName(e.team2_id)}</span>
                                                    </div>
                                                  );
                                                } else {
                                                  return (
                                                    <div className='name'>
                                                      <span>{e.note2 ? e.note2 : "--"}</span>
                                                    </div>
                                                  );
                                                }
                                              })(last.schedule)}
                                            <div className='grey-blk'>
                                              <span>{last?.schedule?.team2_score}</span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className='team-name'>
                                          <span>
                                            {last?.name} - {fieldName(last?.field_id)} - {locationName(last?.location_id)}
                                          </span>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                ))}
                            </div>
                          )}
                        </>
                      ))}
                  </>
                </div>
              )}

              {roundData && roundData.length > 2 && finalRound.length > 0 && <div className={`champion-blk ${roundData.length === 3 ? "no-after" : ""}`}></div>}

              {roundData.length > 0 && roundData[3] && (
                <div className='rightBrackets-blk' id='forth-division'>
                  <div className='division-label right'>
                    <strong>Division - {roundData[3].name}</strong>
                  </div>
                  {roundData[3].bracketData.length > 0 &&
                    roundData[3].bracketData
                      .slice(0)
                      .reverse()
                      .map((roundD, i) =>
                        i !== 0 ? (
                          <div className={`col-blk level-${roundData[1].bracketData.length - i}`} key={`${roundData[1].name}-${roundD.round}`}>
                            {roundD.newBracket &&
                              Object.keys(roundD.newBracket).map((key, index) => (
                                <div className={`inner-blk  ${roundD.newBracket[key].length === 1 ? "single-blk single-cell" : `${!roundD.newBracket[key].length ? "empty-cell" : ""}`}`} key={`${key}-${index}`}>
                                  {roundD.newBracket[key].map((bracketD) => (
                                    <div className={`content-blk ${i && (bracketD.schedule.note1 || bracketD.schedule.note2) ? "after" : ""}`} key={bracketD.name}>
                                      <div className='date-blk'>
                                        <span className='date'>{i < roundData[3].bracketData.length - 1 && !bracketD?.show_tbd_date ? (!TBD.value.includes(bracketD?.time) ? TBD.name : "") : getDateFormat(bracketD?.date)}</span>
                                        <span className='time'>{TBD.value.includes(bracketD?.time) ? TBD.name : bracketD?.time}</span>
                                      </div>
                                      <div className={`name-blk ${!isTBD ? "cursor-pointer" : ""}`}>
                                        <div className='row'>
                                          {bracketD?.schedule &&
                                            ((e) => {
                                              if (e.team1_id) {
                                                return (
                                                  <div className='name'>
                                                    <span>{teamName(e.team1_id)}</span>
                                                  </div>
                                                );
                                              } else {
                                                return (
                                                  <div className='name'>
                                                    <span>{e.note1 ? e.note1 : "--"}</span>
                                                  </div>
                                                );
                                              }
                                            })(bracketD.schedule)}
                                          <div className='grey-blk'>
                                            <span>{bracketD?.schedule?.team1_score}</span>
                                          </div>
                                        </div>
                                        <div className='row'>
                                          {bracketD?.schedule &&
                                            ((e) => {
                                              if (e.team2_id) {
                                                return (
                                                  <div className='name'>
                                                    <span>{teamName(e.team2_id)}</span>
                                                  </div>
                                                );
                                              } else {
                                                return (
                                                  <div className='name'>
                                                    <span>{e.note2 ? e.note2 : "--"}</span>
                                                  </div>
                                                );
                                              }
                                            })(bracketD.schedule)}
                                          <div className='grey-blk'>
                                            <span>{bracketD?.schedule?.team2_score}</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='team-name'>
                                        <span>
                                          {bracketD?.name} - {fieldName(bracketD?.field_id)} - {locationName(bracketD?.location_id)}
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ))}
                          </div>
                        ) : (
                          <div className={`col-blk level-${roundData[1].bracketData.length - i}`} key={`${roundData[1].name}-${roundD.round}`}>
                            {roundD.newBracket &&
                              Object.keys(roundD.newBracket).map((key, index) => (
                                <div className={`inner-blk no-before ${roundD.newBracket[key].length === 1 ? "single-blk" : ""}`} key={`${key}-${index}-${roundD.round}`}>
                                  {roundD.newBracket[key].map((last) => (
                                    <div className='content-blk after no-before' key={last.name}>
                                      <div className='date-blk'>
                                        <span className='date'>{!last?.show_tbd_date ? (!TBD.value.includes(last?.time) ? TBD.name : "") : getDateFormat(last?.date)}</span>
                                        <span className='time'>{TBD.value.includes(last?.time) ? TBD.name : last?.time}</span>
                                      </div>
                                      <div className={`name-blk ${!isTBD ? "cursor-pointer" : ""}`}>
                                        <div className='row'>
                                          {last?.schedule &&
                                            ((e) => {
                                              if (e.team1_id) {
                                                return (
                                                  <div className='name'>
                                                    <span>{teamName(e.team1_id)}</span>
                                                  </div>
                                                );
                                              } else {
                                                return (
                                                  <div className='name'>
                                                    <span>{e.note1 ? e.note1 : "--"}</span>
                                                  </div>
                                                );
                                              }
                                            })(last.schedule)}
                                          <div className='grey-blk'>
                                            <span>{last?.schedule?.team1_score}</span>
                                          </div>
                                        </div>
                                        <div className='row'>
                                          {last?.schedule &&
                                            ((e) => {
                                              if (e.team2_id) {
                                                return (
                                                  <div className='name'>
                                                    <span>{teamName(e.team2_id)}</span>
                                                  </div>
                                                );
                                              } else {
                                                return (
                                                  <div className='name'>
                                                    <span>{e.note2 ? e.note2 : "--"}</span>
                                                  </div>
                                                );
                                              }
                                            })(last.schedule)}
                                          <div className='grey-blk'>
                                            <span>{last?.schedule?.team2_score}</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className='team-name'>
                                        <span>
                                          {last?.name} - {fieldName(last?.field_id)} - {locationName(last?.location_id)}
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              ))}
                          </div>
                        )
                      )}
                </div>
              )}
              {/* <Popup open={openModal} closeOnDocumentClick={BACK_DROP_FALSE}>
                <PlayOffUpdate
                  type={'playoff_active_league'}
                  title={'Edit Playoff Bracket'}
                  closeModal={closeModal}
                  leagueId={leagueId}
                  playOffBracket={selectPlayoffBracket}
                  emit={(data) => {
                    updatePlayOffBracket(data)
                  }}
                  matchup={{
                    locationFields: locationField.map((loc) => ({ id: loc.location_id, title: loc.location_name, fields: loc.fields.map(field => ({ id: field?.field_id, title: field?.field_name, parent_id: loc?.location_id })) })),
                    fields: locationField.reduce((prev, curr) => {
                      prev = [...prev, ...curr?.fields?.map((field) => ({ id: field.field_id, title: field.field_name, parent_id: curr?.location_id }))]
                      return prev
                    }, []),
                    teamData: leagueData?.teamData,
                    times: leagueData?.leagueInfo?.time_slots ? leagueData?.leagueInfo?.time_slots.split(',').map(time => ({ title: time })) : [],
                  }}
                />
              </Popup> */}
            </div>
          </div>
        </div>
      )}
      {errorMessage && errorMessage !== "" && (
        <div className='playoff-bracket schedule-create-block'>
          <div className='no-record'>
            <h3></h3>
          </div>
        </div>
      )}
    </>
  );
}

export default withRouter(PlayOffBracket);
