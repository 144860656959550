import React, { useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
const google = window.google;

function AutoPlaceCompleteApi (props) {
    let autocompleteInput = useRef();
    useEffect(() => {
        if(google){
            const autocomplete = new google.maps.places.Autocomplete(autocompleteInput.current,
              { 
                componentRestrictions: { country: "us" },
            });
            const handlePlaceChanged = () => {
                const place = autocomplete.getPlace();
                place.addressVal = autocompleteInput?.current?.value;
                props.getAddress(place);
            }
            autocomplete.addListener('place_changed', handlePlaceChanged);
        }
    // eslint-disable-next-line
    }, []);

    return (
        <div key={props.uniQueKeY || 'uniQueKey-address'}>
            <input ref={autocompleteInput} className='form-control' value={props?.address} id="autocomplete" placeholder=''  onChange={(e) => {if(props.getAddress) {
            props.getAddress(e.target.value);
        }}} type="text"></input>
        </div>
    )
}
export default withRouter(AutoPlaceCompleteApi);

