import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Popup from 'reactjs-popup';
import { config } from '../../config';
import axiosApi from '../../core/interceptor/interceptor';
import './Classified.css';
import CreateClassified from './create/Create';
import SimpleBarReact from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import moment from 'moment';
import { userDetails } from '../../store/reducer/LoggedInUserDetails';
import { useSelector } from 'react-redux';

export default function Classified(props) {
  const [create, setCreate] = useState(false);
  const [classifieds, setClassifieds] = useState([]);
  const isLoggedIn = useSelector(userDetails);
  const history = useHistory();
  const loader = useSelector((state) => state.loader.value);
  useEffect(() => {
    getClassifieds();
  }, []);

  const closeCreateClassified = () => {
    setCreate(false);
    history.push('/classified');
  }

  const getClassifieds = () => {
    axiosApi.get(`${config.apiUrl}/misc/classifieds`, { params: { status: 'approved' } })
      .then((res) => {
        if (res.data.data) {
          setClassifieds([...res.data.data]);
        }
      })
  }

  return (
    <>
      <main className="wrapper-main">
        <section className="classifieds-block">
          <div className="cs-container">
            <div className="cs-row">
              <div className="c-12">
                <div className="classifieds-block-inner">
                  <div className="classifieds-head">
                    <h2>Classifieds</h2>
                  </div>
                  <div className="classifieds-instruction">
                    <p>
                      Looking to join a team or find an extra member? Post a classified to advertise what it is you're looking for. Your contact information will be available to logged in users so they can reach out to respond.
                    </p>
                  </div>
                  {!!isLoggedIn ? <div className="header-btn-block classified-btn">
                    <button className="btn-secondary" onClick={() => { setCreate(o => !o) }}>Create Classified</button>
                  </div> : ''}
                </div>
              </div>
            </div>
            <div className="cs-row">
              <div className="c-12">
                <div className="classifieds-block-post">
                  <div className="classifieds-head">
                    <h2>Current Posts</h2>
                  </div>
                  <div className="classifieds-timetable">
                    <div className="cs-row">
                      {classifieds.length ? classifieds.map((classified, index) => (
                        <div className="c-3" key={index}>
                          <div className="classifieds-timetable-outer ns-cur-post">
                            <div className="classifieds-timetable-inner">
                              <h4 title={classified.title}>{classified.title}</h4>
                              <div className="desc-blk">
                                <SimpleBarReact style={{
                                  maxHeight: 182,
                                  overflow: 'auto',
                                }}>
                                  <p>{classified.description}</p>
                                </SimpleBarReact>
                                <a href={`mailto:${classified?.user?.email}`} target="_blank" rel="noreferrer">{classified?.user?.email}</a>
                              </div>
                            </div>
                            <div className="date-time">
                              <span className="btn-primary" >{classified.display_until ? moment(classified.display_until).format('MM/DD/YYYY hh:mm A') : 'N/A'}</span>
                            </div>
                          </div>
                        </div>
                      )) :
                        !loader && <div className="noRecord-found">
                          <p>No Record Found</p>
                        </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Popup className="createClassified-popup" open={create} onClose={closeCreateClassified} closeOnDocumentClick={true}>
        <CreateClassified closeCreateClassified={() => closeCreateClassified()} onClose={() => { setCreate(o => !o) }}></CreateClassified>
      </Popup>
    </>
  )
}