export const messages = {
    emptyEmail: 'Please enter email address.',
    invalidEmail: 'Please enter a valid email address.',
    emptyPassword: 'Please enter password.',
    emptyConfirmPassword: 'Please enter confirm password.',
    passwordLength: 'Please enter atleast 6 digit password.',
    confirmPassword: 'Confirm password must match with password.',
    firstName: 'Please enter first name.',
    lastName: 'Please enter last name.',
    birthDate: 'Please enter birth date.',
    cellPhone: 'Please enter cell phone number.',
    cellPhoneInvalid: 'Please enter a valid cell phone number.',
    zip: 'Please enter zip code.',
    shirtSize: 'Please select shirt size.',
    jersey: 'Please enter jersey.',
    notAllowedImg: 'Please select valid image.',
    imgLimit: 'Selected image is too large.',
    classifiedTitle: 'Classified title is required.',
    classifiedDescription: 'Classified description is required.',
    classfiedLastDate: 'Display until date is required.',
    teamName: 'Please enter team name.',
    fullName: 'Please enter your full name.',
    message: 'Please enter a message.',
    email_taken: 'Email already taken. Please try with a different email.',
    disclaimer: 'Please select player disclaimer.',
    captchaSelect: 'Please select captcha.',
    selectPolicies: 'You must accept the policies in order to register.',
    covidPolicies: 'Please select covid polices.',
    classfiedLastTime: 'Display until time is required.',
    phoneLength: 'Please enter a valid phone number.',
    duplicatename: 'Duplicate team name.',
    phoneValidation: 'Please check that you have validated the Cell Phone number provided above.',
    emailPhoneValidation: 'Please check that you have validated the Cell Phone number and Email provided above.',
    maxlength: 'The team name cannot be longer than 150 characters.'
}