import React, { forwardRef, useState, useEffect } from 'react';
import './Header.css';
import { Link, NavLink, useHistory } from 'react-router-dom';
import logo from '../../../assets/images/logo-main.png';
import { Popup } from 'reactjs-popup';
import Login from '../login/Login';
import Signup from '../signup/signup';
import defaultImage from "../../../assets/images/user-default-image.png";
import { useSelector, useDispatch } from 'react-redux';
import { userDetails } from '../../../store/reducer/LoggedInUserDetails';
import { config, REGISTERED_THROUGH, Disclaimers } from '../../../config';
import axiosApi from '../../../core/interceptor/interceptor';
import { setSports } from '../../../store/reducer/SportsList';
import { isMobile } from "react-device-detect";

function Header(props, ref) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [login, setLoginOpen] = useState(false);
  const [signup, setSignupOpen] = useState(false);
  const [toggle, setToggle] = useState(false);
  const loader = useSelector((state) => state.loader.value);
  const [notificationBanner, setNotificationBanner] = useState(null);
  const [activeTeam, setActiveTeam] = useState(false);
  const [toggleNavClass, setToggleNavClass] = useState(false);
  const [sportList, setSportList] = useState([]);
  /**** Close Login Modal ****/
  const closeLoginModal = (resp = false) => {
    setLoginOpen(false);
  };

  /**** Close Signup Modal ****/
  const closeSignupModal = (resp = false) => {
    setSignupOpen(false);
  };

  const handleToggle = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    getNotificationBanner();
    const interval = setInterval(() => {
      getNotificationBanner();
    }, 3600000);
    getSportsIds();
    return () => {
      clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    return history.listen((location) => {
      if (location.pathname.includes('/league/team/')) {
        setActiveTeam(true);
      } else {
        setActiveTeam(false);
      }
      // console.log(`You changed the page to: ${location.pathname}`) 
    })
  }, [history])

  const getSportsIds = () => {
    axiosApi.get(`${config.apiUrl}/misc/get-sport-id`)
      .then((res) => {
        if (res.data.data) {
          dispatch(setSports(res.data.data));
          setSportList(res.data.data);
        }
      })
  }
  const getNotificationBanner = () => {
    axiosApi.get(`${config.apiUrl}/misc/disclaimer/${REGISTERED_THROUGH}/${Disclaimers.notification_banner}`)
      .then((res) => {
        if (res && res.data && res.data.data) {
          res.data.data.disclaimer = res.data.data.disclaimer ? res.data.data.disclaimer.replace(/\n/g, '') : '';
          setNotificationBanner(res.data.data.disclaimer);
          if (res.data.data.disclaimer) {
            props.notification(true);
          } else {
            props.notification(true);
          }
        }
      })
  }

  const loggedInUserDetails = useSelector(userDetails);

  return (
    <>
      {/* notification-active */}
      <header ref={ref} id='header' className={`header-main ${notificationBanner && 'notification-active'} ${toggle && 'nav-active'}`}>
        <div className="top-notify">
          {
            notificationBanner &&
            <marquee behavior="scroll" direction="left" scrollamount="7"><p dangerouslySetInnerHTML={{ __html: notificationBanner }}></p></marquee>
          }
        </div>
        <div className="cs-container">
          <div className="row-header">
            <div className="logo-main">
              <Link to="/">
                <img src={logo} alt="" />
              </Link>
            </div>
            <div className="header-right">
              {loggedInUserDetails && <div className="login-profile">
                <div className="login-prsofile-inner">
                  <div className="greet-msg-mob">
                    <p>
                      <span className='mob-only'>Hi,</span>
                      <span className='hide-on-mob'>
                        {
                          function () {
                            const hr = new Date().getHours();
                            if (hr >= 0 && hr < 12) {
                              return 'Good Morning';
                            } else if (hr >= 12 && hr <= 17) {
                              return 'Good Afternoon';
                            } else {
                              return 'Good Evening';
                            }
                          }()
                        },
                      </span> {loggedInUserDetails ? loggedInUserDetails.first_name : 'N/A'}</p>
                    <span className='bl-color' onClick={() => props.openMenu()}> My Account </span>

                  </div>
                  <div className="login-person">
                    <span className='bl-color' onClick={() => props.openMenu()}> {loggedInUserDetails?.profile_photo ? <img src={loggedInUserDetails?.is_old ? loggedInUserDetails?.profile_photo : `${config.api}/${config.imageFolder}/profile/${loggedInUserDetails.profile_photo}`} alt={loggedInUserDetails.profile_photo} /> : <img src={defaultImage} alt='player-img' />} </span>
                  </div>
                </div>
              </div>}
              <button className="nav-hamburger" onClick={() => handleToggle()}>
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>
            <div className={`head-right-block ${toggle && 'active'}`}>
              <nav className="navigation-main">
                <ul>
                  <li>
                    <NavLink exact activeClassName="active" to='/' onClick={() => setToggle(false)}>Home</NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="active" to="/about" onClick={() => setToggle(false)}>About</NavLink>
                  </li>
                  <li className={`has-child ${toggleNavClass ? 'show-sub' : ''}`} onClick={() => setToggleNavClass(prev => !prev)}>
                    <span activeClassName="" to=""><p>Leagues</p></span>
                    <ul className='sub-nav-menu'>
                      {sportList?.active && sportList?.active.length > 0 ? (
                        <li>
                          
                          {isMobile ? (
                            <NavLink className={`sub-nav-link`} to={`/league/sports/active`} onClick={() => setToggle(false)}>
                              Active Leagues
                            </NavLink>
                          ) : (
                            <NavLink className={`sub-nav-link`} to={`/league/team/${sportList?.active[0].id}/active`} onClick={() => setToggle(false)}>
                              Active Leagues
                            </NavLink>
                          )}
                        </li>
                      ) : (
                        <li>
                          <NavLink className={`sub-nav-link`} to={`/league/team/0/active`} onClick={() => setToggle(false)}>
                            Active Leagues
                          </NavLink>
                        </li>
                      )}
                      {sportList?.archived && sportList?.archived.length > 0 && (
                        <li>
                          {isMobile ? (
                            <NavLink className={`sub-nav-link`} to={`/league/sports/archived`} onClick={() => setToggle(false)}>
                            Archived Leagues
                            </NavLink>
                          ) : (
                            <NavLink className={`sub-nav-link`} to={`/league/team/${sportList?.archived[0].id}/archived`} onClick={() => setToggle(false)}>
                              Archived Leagues
                            </NavLink>
                          )}
                        </li>
                      )}

                      {sportList?.sign_up && sportList?.sign_up.length > 0 ? (
                        <li>
                          {isMobile ? (
                            <NavLink className={`sub-nav-link`} to={`/league/sports/sign_up`} onClick={() => setToggle(false)}>
                            Registration
                            </NavLink>
                          ) : (
                            <NavLink className={`sub-nav-link`} to={`/league/team/${sportList?.sign_up[0].id}/sign_up`} onClick={() => setToggle(false)}>
                              Registration
                            </NavLink>
                          )}
                        </li>
                      ) : (
                        <li>
                          <NavLink className={`sub-nav-link`} to={`/league/team/0/sign_up`} onClick={() => setToggle(false)}>
                            Registration
                          </NavLink>
                        </li>
                      )}
                    </ul>
                  </li>
                  <li>
                    <NavLink activeClassName="active" to="/classified" onClick={() => setToggle(false)}>Classifieds</NavLink>
                  </li>
                  <li>
                    <NavLink activeClassName="active" to="/contact" onClick={() => setToggle(false)}>Contact</NavLink>
                  </li>
                </ul>
              </nav>
              {(!loggedInUserDetails && !loader) ? <div className="header-btn-block">
                <button className="btn-primary" onClick={() => setSignupOpen(o => !o)}>Sign up</button>
                <button className="btn-secondary" onClick={() => setLoginOpen(o => !o)}>Login</button>
              </div> :
                (
                  loggedInUserDetails &&
                  <div className="login-profile">
                    <div className="login-prsofile-inner">
                      <div className="login-profile-name">
                        <span>{
                          function () {
                            const hr = new Date().getHours();
                            if (hr >= 0 && hr < 12) {
                              return 'Good Morning';
                            } else if (hr >= 12 && hr <= 17) {
                              return 'Good Afternoon';
                            } else {
                              return 'Good Evening';
                            }
                          }()
                        }, {loggedInUserDetails ? loggedInUserDetails.first_name : 'N/A'}</span>
                        <Link className="anchor-link" to='/my-account'>My Account</Link>
                      </div>
                      <div className="login-person">
                        {loggedInUserDetails?.profile_photo ? <img src={loggedInUserDetails?.is_old ? loggedInUserDetails?.profile_photo : `${config.api}/${config.imageFolder}/profile/${loggedInUserDetails.profile_photo}`} alt={loggedInUserDetails.profile_photo} /> : <img src={defaultImage} alt='player-img' />}
                      </div>
                    </div>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </header>
      <Popup open={login} onClose={closeLoginModal} closeOnDocumentClick={true}>
        <Login closeLoginModal={() => closeLoginModal()} login={true} openSignUp={() => setSignupOpen(true)}></Login>
      </Popup>
      <Popup open={signup} onClose={closeSignupModal} closeOnDocumentClick={false}>
        <Signup closeSignupModal={() => closeSignupModal()} openLogin={() => setLoginOpen(true)}></Signup>
      </Popup>
    </>
  );
}
export default forwardRef(Header);