import React, { useEffect, useState } from 'react';
import axiosApi from '../../../core/interceptor/interceptor';
import { config, REGISTERED_THROUGH } from '../../../config';
import './IndividualRegistration.css';
import backArrow from "../../../assets/images/sidearrow.svg";
import { Link, withRouter } from 'react-router-dom';
import visa from "../../../assets/images/visa-card.png";
import mastercard from "../../../assets/images/master-card.png";
import { useHistory, useParams } from 'react-router';
import Popup from 'reactjs-popup';
import Checkout from '../Checkout/Checkout';
import ErrorInput from '../../common/ErrorInput';
import Policy from '../../common/Policy/Policy';
import { messages } from "../../../messages";
import stripe from "../../../assets/images/stripe.png";
import { PaymentTeamCreate } from '../../../utils/Utils';
import { userDetails } from '../../../store/reducer/LoggedInUserDetails';
import { useSelector } from 'react-redux';
import backArrowGreen from "../../../assets/icons/backArrowGreen.svg";

function IndividualRegistration(props) {
  const sportId = useParams().sportId;
  const history = useHistory();
  const [leagueData, setLeagueData] = useState(null);
  const [policyData, setPolicyData] = useState({
    policies: false,
    covid_policies: false,
  });
  const [checkout, setCheckoutOpen] = useState(false);
  const leagueId = useParams().id;
  const [policy, setPolicy] = useState({ open: false, type: '' });
  const [formData, setFormData] = useState({
    notes: '',
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [error, setError] = useState({
    policies_team_register: '',
  });
  const [selectedTypePayment, setSelectedTypePayment] = useState('');
  const loggedInUserDetails = useSelector(userDetails);

  useEffect(() => {
    if (leagueId) {
      axiosApi.get(`${config.apiUrl}/leagues/league-details/${leagueId}`)
        .then((response) => {
          if (response.data && response.data.data) {
            const leage_data = response.data.data && Array.isArray(response.data.data.teams) && response.data.data.teams.length >= response.data.data.team_size
            if (leage_data) {
              setSelectedTypePayment(PaymentTeamCreate.save_card);
            }
            setLeagueData(response.data.data);
          }
        })
    }
    //eslint-disable-next-line
  }, []);

  const handleCheckBox = (e) => {
    setPolicyData((previousPolicyData) => ({
      ...previousPolicyData,
      [e.target.name]: !policyData[e.target.name],
    }))
  }

  const openCheckoutModal = (payment_type) => {
    // Set flag for add card only
    if (payment_type) {
      setFormData((previousFormData) => ({
        ...previousFormData,
        payment_type
      }))
    }

    setFormSubmitted(true);
    if (handleValidation()) {
      setCheckoutOpen(true);
    }
  }

  const handleFormChange = (e) => {
    setFormData((previousFormData) => ({
      ...previousFormData,
      [e.target.name]: e.target.value,
    }))
  }

  const handleValidation = () => {
    let errArray = {};
    let formIsValid = true;
    if (!policyData.policies || !policyData.covid_policies) {
      formIsValid = false;
      errArray['policies_team_register'] = messages && messages.selectPolicies;
    }
    setError(errArray);
    return formIsValid;
  }

  useEffect(() => {
    handleValidation();
    // eslint-disable-next-line
  }, [policyData.policies, policyData.covid_policies]);


  const saveTeam = () => {
    setFormSubmitted(true);
    if (handleValidation()) {
      if (policyData.policies && policyData.covid_policies) {
        axiosApi.post(`${config.apiUrl}/leagues/create-checkout-session/${leagueData.id}?type=individual_registration`, {
          ...formData, registered_through: REGISTERED_THROUGH, email: loggedInUserDetails?.email, payment_type: selectedTypePayment
        }).then(() => {
          history.push(`/my-account`);
        }).catch((err) => {
          console.error(err);
        }).finally(() => {
          setFormSubmitted(false);
        });
      }
    }
  }

  return (
    <>
      <section className="main-side no-sidebar">
        <div className="main-side-container">
          <div className="main-top-headings pb-0">
            <div className="league-back-link">
              {props &&
                props.location &&
                props.location.state &&
                props.location.state?.from === "indv" ? (
                <Link
                  to={{
                    pathname: `/league/team/${leagueData?.sport_id}/individual`,
                    state: {
                      from: "indv",
                    },
                  }}
                >
                  <img src={backArrowGreen} alt="" />
                  Back to League Info
                </Link>
              ) : (
                <Link to={`/league/league-details/${leagueId}/${sportId}`}>
                  <img src={backArrowGreen} alt="" />
                  Back to League Info
                </Link>
              )}
            </div>
          </div>
          <div className="main-side-body">
            <div className="league-detail-header"><h2 className="league-detail-heading">Individual Registration for <span>
              {leagueData && leagueData.sport && leagueData.sport.title} -{" "}
              {leagueData && leagueData.name}
            </span></h2></div>
            <div className="league-rules reg-checkout ind-reg-checkout">
              <div className="league-rules-body">
                <div className="cs-fee-info">
                  {/* <div className="input-blk">
										<label>Custom Team Option:</label>
										<input className="form-control" type='text' name='custom_option' value={formData['custom_option']} onChange={(e) => handleFormChange(e)} />
									</div> */}
                  <div className="cs-fee-info-item">
                    <strong>League Fees Due: </strong>
                    <p>
                      ${leagueData?.indv_reg_fees || "0.00"}
                    </p>
                  </div>
                  <div className="cs-fee-info-item">
                    <strong>Stripe Fee Due: </strong>
                    <p>
                      ${(
                        (+leagueData?.indv_reg_fees + 0.3) / (1 - 0.029) -
                        +leagueData?.indv_reg_fees
                      ).toFixed(2) || "0.00"}
                    </p>
                  </div>
                  <div className="cs-fee-info-item mb total-ind-fee">
                    <strong>Total Fee Due: </strong>
                    <p>
                      ${((+leagueData?.indv_reg_fees + 0.3) / (1 - 0.029)).toFixed(
                        2
                      ) || "0.00"}
                    </p>
                  </div>
                  <div className="input-blk">
                    <input
                      className="form-control"
                      type="text"
                      name="notes"
                      placeholder="Notes"
                      value={formData["notes"]}
                      onChange={(e) => handleFormChange(e)}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
            <div className="league-rules reg-checkout">
              <div className="league-rules-body">
                <p>
                  <strong>We Accept</strong>
                </p>
                <div className="img-payment-cards duo-cards">
                  <img src={visa} alt="Payments" />
                  <img src={mastercard} alt="master-card" />
                </div>
                <div className="understand-blk">
                  <div className="checkbox-policy-blk">
                    <label className="custom-checkbox">
                      <input
                        type="checkbox"
                        name="policies"
                        value={policyData.policies}
                        onChange={(e) => handleCheckBox(e)}
                      />
                      <span>I have read and fully understand the</span>
                    </label>
                    <span
                      className="link"
                      onClick={() =>
                        setPolicy({
                          open: true,
                          type: "cancelation",
                        })
                      }
                    >
                      Cancellation and Registration Policies
                    </span>
                  </div>
                  <div className="checkbox-policy-blk">
                    <label className="custom-checkbox">
                      <input
                        type="checkbox"
                        name="covid_policies"
                        value={policyData.covid_policies}
                        onChange={(e) => handleCheckBox(e)}
                      />
                      <span>I have read and fully understand the</span>
                    </label>
                    <span
                      className="link"
                      onClick={() =>
                        setPolicy({
                          open: true,
                          type: "covid19",
                        })
                      }
                    >
                      Covid-19 Policies
                    </span>
                    <ErrorInput
                      formSubmitted={formSubmitted}
                      error={error["policies_team_register"]}
                    />
                  </div>
                </div>
                {leagueData &&
                  Array.isArray(leagueData.teams) && leagueData.teams.length && leagueData.teams.filter(x => x.status === 'active').length >= leagueData.team_size ? (
                  <>
                    <div className="radio-btn-box">
                      <label className="radio-container">
                        <input
                          type="radio"
                          name="radio"
                          value={PaymentTeamCreate.save_card}
                          checked={
                            selectedTypePayment === PaymentTeamCreate.save_card
                          }
                          onChange={() =>
                            setSelectedTypePayment(PaymentTeamCreate.save_card)
                          }
                        />
                        <span className="checkmark"></span>
                        Pay by Card
                      </label>
                      <label className="radio-container">
                        <input
                          type="radio"
                          name="radio"
                          value={PaymentTeamCreate.cheque_cash}
                          checked={
                            selectedTypePayment ===
                            PaymentTeamCreate.cheque_cash
                          }
                          onChange={() =>
                            setSelectedTypePayment(
                              PaymentTeamCreate.cheque_cash
                            )
                          }
                        />
                        <span className="checkmark"></span>
                        Pay by Cash / Check
                      </label>
                    </div>
                    <div className="paypal-blk">
                      <button
                        className="btn paypal-btn"
                        onClick={() => {
                          if (selectedTypePayment) {
                            if (
                              selectedTypePayment ===
                              PaymentTeamCreate.save_card
                            ) {
                              openCheckoutModal(selectedTypePayment);
                            } else {
                              saveTeam();
                            }
                          } else {
                            openCheckoutModal(null);
                          }
                        }}
                      >
                        Save
                      </button>
                    </div>
                    {
                      selectedTypePayment === PaymentTeamCreate.save_card &&
                      <div className="special-line">
                        Your card will only be charged if you are added to the league.
                      </div>
                    }
                  </>
                ) : (
                  <div className="paypal-blk">
                    <span>Checkout With Stripe</span>
                    <button
                      className="btn paypal-btn"
                      onClick={() => openCheckoutModal(null)}
                    >
                      Pay Now
                    </button>
                    <span className="secure">
                      Secure checkout provided by stripe - no signup required
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="note-info">
              <p className="note-info-heading">
                <strong>
                  Important Information
                </strong>
              </p>
              {leagueData &&
                Array.isArray(leagueData.teams) &&
                leagueData.teams.length >= leagueData.team_size ? (
                <>
                  <p>
                    Since all leagues are completely occupied, you will be
                    added to the wait list after completing this
                    registration.
                  </p>
                  <p>
                    The admin will review your registration and will take a
                    decision on its approval.
                  </p>
                  <p>
                    During the time your registration is not approved, no
                    charges will be deducted from your card. After the admin
                    approves your registration then only the amount is
                    deducted from your saved card details.
                  </p>
                </>
              ) : (
                <p>
                  After completing registration, please verify that you have
                  registered for the correct league. You'll be able to invite
                  members through the "My Teams" section of your profile.
                </p>
              )}
            </div>
          </div>
        </div>
        <Popup className="stripePayment" open={checkout || policy?.open} onClose={() => {
          setCheckoutOpen(false);
          setPolicy({ open: false, type: '' })
        }} closeOnDocumentClick="false">
          {
            checkout ? <Checkout closeModal={() => {
              setCheckoutOpen(false);
              setPolicy({ open: false, type: '' });
            }} leagueData={leagueData} type='individual_registration' teamData={formData} pay={((+leagueData?.indv_reg_fees + 0.30) / (1 - 0.029)).toFixed(2) || '0.00'} />
              : <Policy type={policy?.type} closeModal={() => setPolicy({ open: false, type: '' })} />
          }
        </Popup>
      </section>
    </>
  )
}
export default withRouter(IndividualRegistration);