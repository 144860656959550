import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { config } from "../../config";
import axiosApi from "../../core/interceptor/interceptor";

export default function InvitePlayer() {
	const details = {
		accepted: useParams().accepted,
		token: useParams().token,
	}
	const [data, setData] = useState([]);
	const [checkValidate, setCheckValidate] = useState(false);
	const history = useHistory();

	useEffect(() => {
		axiosApi.put(`${config.apiUrl}/misc/validate-token/${details.token}`).then((res) => {
			setData(res.data.data);
			setCheckValidate(true);
		}, (err) => {
			history.push('/');
		});
  // eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (checkValidate) {
			if (details.accepted === 'true') {
				if (data.player_id) {
					axiosApi.post(`${config.apiUrl}/misc/add-player/${data.id}`, {
						team_id: data.team_id,
						user_id: data.player_id,
						email: data.email,
						policies: true,
						covid_policies: true,
					}).then((res) => {
						history.push({
							pathname: '/',
							state: {
								status: 'login',
								email: data?.email
							}
						});
					})
				} else if (data !== null) {
					axiosApi.put(`${config.apiUrl}/misc/invite-player/${data.id}`)
						.then((res) => {
							console.log(res);
						}, (err) => {
							history.push(`/?signup=true&invitePlayerId=${data.id}`, {
								status: 'signup',
								email: data?.email
							});
						})
				}
			} else {
				axiosApi.delete(`${config.apiUrl}/misc/delete-invitation/${data.id}`)
					.then(() => {
						history.push({
							pathname: '/',
							state: {
								status: 'Invalid Token'
							}
						});
					}, (err) => {
						console.error(err);
					})
			}
		}
    // eslint-disable-next-line
	}, [data, checkValidate]);

	return <>
		<div>Please wait.....</div>
	</>
}