import React, { useEffect } from "react";
import { useHistory } from "react-router";

export default function PaymentGuard({ Component }) {
  const history = useHistory();
  const token = localStorage.getItem('token');
  useEffect(() => {
    if(!token) {
        history.push('/');
    }
    //eslint-disable-next-line
  }, []);

  return <Component />
}