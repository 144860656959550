import React , { useEffect } from 'react';
import { Route, withRouter } from 'react-router';
import League from '../../component/league/League';
import SportLeagues from '../../component/league/Leagues/Leagues';
import LeagueList from './List/List';
import LeagueDetails from './LeagueDetails/LeagueDetails';
import PaymentGuard from '../../core/guard/PaymentGuard';
import IndividualRegistration from './IndividualRegistration/IndividualRegistration';
import TeamRegistration from '../../component/league/team/Team';
import ActiveLeagueList from './List/ActiveList';
import LeagueSports from './LeagueSport/LeagueSports';
function LeagueLayout(props) {

  return <>
    {/* <Route path={`${props.match.path}/team`} exact={true}>
      <League />
    </Route> */}
    <Route path={`${props.match.path}/team/:id/:type`} exact={true}>
      <League />
    </Route>
    {/* <Route path={`${props.match.path}/individual`}>
      <LeagueList />
    </Route>
    <Route path={`${props.match.path}/active`}>
      <ActiveLeagueList type={'active'} />
    </Route>
    <Route path={`${props.match.path}/archive`}>
      <ActiveLeagueList type={'archive'} />
    </Route> */}
    <Route exact={true} path={`${props.match.path}/sports/:type`}>
      <LeagueSports />
    </Route>
    <Route exact={true} path={`${props.match.path}/league-details/:id`}>
      <LeagueDetails />
    </Route>
    <Route exact={true} path={`${props.match.path}/league-details/:id/:sportId`}>
      <LeagueDetails />
    </Route>
    <Route exact={true} path={`${props.match.path}/individual-registration/:id`}>
      <PaymentGuard Component={IndividualRegistration} key='guard-team-registration' />
    </Route>
    <Route exact={true} path={`${props.match.path}/team-registration/:id/:sportId`}>
      <PaymentGuard Component={TeamRegistration} kay='guard-indv-registration' />
    </Route>
    <Route exact={true} path={`${props.match.path}/sport/:id`}>
      <SportLeagues />
    </Route>
  </>
}

export default withRouter(LeagueLayout);