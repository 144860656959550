import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './Footer.css';
import logo from '../../../assets/images/logo-footer.png';
import { Link } from 'react-router-dom';
import moment from 'moment';

export default function Footer() {
  const search = useLocation();
  const [midFooter, setMidFooter] = useState(true);
  const currentYear = moment().year();
  useEffect(() => {
    const path = search.pathname.split('/');
    if (path.includes('my-account') || path.includes('league')) {
      setMidFooter(false);
    } else {
      setMidFooter(true);
    }
  }, [search.pathname])
  return (
    <>
      <footer className={`footer-main ${midFooter ? 'pl-0' : ''}`}>
        <div className="cs-container">
          <div className="footer-logo">
            <img src={logo} alt="" />
          </div>
          <div className="nav-footer">
            <ul>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li>
                <Link to="/">My Cleveland Plays</Link>
              </li>
              <li>
                <Link to="/league">Leagues</Link>
              </li>
            </ul>
          </div>
          {window.location.href ===`${window.location.origin}/contact` && <div className="email-blk">
            <span className="email">Brains Mulberrys Email: Mulberrys269@yahoo.com</span> <p>|</p> 
            <span className="email">Cleveland Plays: Whiskeyisland269@yahoo.com</span>
          </div>}
          <div className="copyright-block">
            &copy; {currentYear} Cleveland Plays Incorporated
            <p>All Rights Reserved</p>
          </div>
        </div>
      </footer>
    </>
  );
}