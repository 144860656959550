import React, { useState, useEffect, useMemo, Fragment } from 'react';
import './signup.css';
import cross from '../../../assets/icons/cross-icon.svg';
import main from '../../../assets/images/logo-main.png';
import upload from '../../../assets/images/upload-img.png';
import { Link, useLocation } from 'react-router-dom';
import axiosApi from '../../../core/interceptor/interceptor';
import { config, Disclaimers, REGISTERED_THROUGH } from '../../../config';
import { messages } from '../../../messages';
import InputMask from 'react-input-mask';
import { phoneNumberMask, maskingToPlainNumber, validateRequiredFields, validateEmail } from '../../../utils/Utils';
import ErrorInput from '../../common/ErrorInput';
import DatePicker from 'react-date-picker';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../store/reducer/LoggedInUserDetails';
import _ from 'lodash';
import CustomSelect from '../../../utils/CustomSelect';
import AutoAddressComplete from '../../../utils/AutoAddressComplete';
import ReCAPTCHA from "react-google-recaptcha";
import SimpleBarReact from 'simplebar-react';
import { MiniLoader } from '../../../utils/Loader';
import { isValidPhoneNumber } from 'libphonenumber-js';

export default function Signup(props) {

	const [tabCount, setTabCount] = useState(1);
	const img = <img src={upload} alt='upload-img' />;
	const [selectedFile, setSelectedFile] = useState('');
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [errors, setErrors] = useState({
		email: '', password: '', confirmPassword: '', first_name: '', last_name: '', gender: '', birth_date: '', cell_phone: '', zip: '', shirt_size: '', disclaimer: '', profile_photo: '', captcha: '', phone_validation: '',
	});
	const [emailExists, setEmailExist] = useState(false);
	const [stateList, setStateList] = useState([]);
	const dispatch = useDispatch();
	const [captcha, setCaptcha] = useState('');
	const captchaSitekey = config.captchaSitekey;
	const [formData, setFormData] = useState({
		email: '',
		password: '',
		confirmPassword: '',
		first_name: '',
		middle_name: '',
		last_name: '',
		gender: 'male',
		birth_date: '',
		cell_phone: '',
		address: '',
		secondary_address: '',
		city: '',
		state_id: null,
		zip: '',
		shirt_size: null,
		// jersey: '',
		profile_photo: '',
		text_notification: false,
		email_notification: false,
		disclaimer: false,
		state_name: '',
	});
	const [isPhoneValidated, setIsPhonevalidated] = useState(false);
	const [showDisclaimer, setShowDisclaimer] = useState(false);
	const [disclaimer, setDisclaimer] = useState('')
	const [showChangeImage, setShowChangeImage] = useState(false);
	const search = useLocation().search;
	const invitePlayerId = new URLSearchParams(search).get('invitePlayerId');
	const [checkingEmail, setCheckingEmail] = useState(false);
	const [isStateList, setIsStateList] = useState(false);

	useEffect(() => {
		handleValidation();
		//eslint-disable-next-line
	}, [formData, emailExists, captcha, isPhoneValidated])

	useEffect(() => {
		if (props.signupEmail) {
			setFormData(prev => ({ ...prev, email: props.signupEmail }));
		}
	}, [props?.signupEmail]);

	useEffect(() => {
		getStateList();
	}, []);


	const getStateList = () => {
		axiosApi.get(`${config.apiUrl}/misc/states`)
			.then((res) => {
				setStateList(res.data.data);
				setIsStateList(true);
			}, (err) => {
				console.error(err);
				setIsStateList(true);
			})
	}

	const debouncedCheckEmailExistance = useMemo(
		() => _.debounce((email, id) => checkEmail(email, id), 1500),
		[],
	);

	const checkEmail = (email, id) => {
		setCheckingEmail(true);
		axiosApi.post(`${config.apiUrl}/misc/check-email-existance`, { email, id })
			.then((res) => {
				setEmailExist(!!res.data.data);
				setCheckingEmail(false);
			})
			.catch((err) => {
				console.error(err);
				setCheckingEmail(false);
			});
	}

	useEffect(() => {
		if (validateEmail((formData.email || '').trim())) {
			debouncedCheckEmailExistance((formData.email || '').trim(), formData.id);
		}
		//eslint-disable-next-line
	}, [formData.email]);

	const closeSignupModal = (resp = false) => {
		if (showDisclaimer) {
			setCaptcha('');
			setFormSubmitted(true);
			setShowDisclaimer(false);
		} else {
			if (props.closeSignupModal) {
				props.closeSignupModal(resp || false);
			}
		}
	}

	const onChange = (value) => {
		setCaptcha(value);
	}

	const handleSubmit = (event) => {
		setFormSubmitted(true);
		event.preventDefault();
		if (handleValidation()) {
			let data = new FormData();
			data.append('email', (formData.email || '').trim());
			data.append('password', formData.password);
			data.append('first_name', (formData.first_name || '').trim());
			data.append('middle_name', (formData.middle_name || '').trim());
			data.append('last_name', (formData.last_name || '').trim());
			data.append('gender', (formData.gender === 'male' ? 'M' : 'F'));
			data.append('birth_date', formData.birth_date);
			data.append('cell_phone', maskingToPlainNumber(formData.cell_phone));
			data.append('address', (formData.address || '').trim());
			data.append('secondary_address', (formData.secondary_address || '').trim());
			data.append('city', (formData.city || '').trim());
			if (formData.state_id) {
				data.append('state_id', formData.state_id);
			}
			data.append('zip', (formData.zip || '').trim());
			data.append('shirt_size', formData.shirt_size);
			// data.append('jersey', (formData.jersey || '').trim());
			data.append('profile_photo', formData.profile_photo);
			data.append('text_notification', formData.text_notification);
			data.append('email_notification', formData.email_notification);
			data.append('registered_through', 'C');
			//	data.append('captcha', captcha);
			if (selectedFile && selectedFile.name) {
				data.append("file", selectedFile, selectedFile.name);
			}
			axiosApi.post(`${config.apiUrl}/auth/register`, data).then((res) => {
				if (res.data.token) {
					localStorage.setItem('token', res.data.token);
					axiosApi.get(`${config.apiUrl}/user/me`)
						.then((res) => {
							dispatch(setUser(res.data.data));
						})
					if (invitePlayerId) {
						hasInvitation(res.data.data.id);
					}
				}
				props.closeSignupModal(false);
			});
		}
	}

	const hasInvitation = (id) => {
		axiosApi.get(`${config.apiUrl}/misc/invite-player/${invitePlayerId}`)
			.then((res) => {
				handleInvitation(res.data.data, formData.email, id);
			}, (err) => {
				console.error(err);
			})
	}

	const handleInvitation = (data, email, id) => {
		if (data && !data.status) {
			axiosApi.post(`${config.apiUrl}/misc/add-player/${data.id}`, {
				team_id: data.team_id,
				user_id: id,
				email: email,
				policies: true,
				covid_policies: true,
			}).then((res) => {
				console.log(res);
			})
		}
	}

	const handleFormChange = (e) => {
		setFormData((previousFormData) => ({
			...previousFormData,
			[e.target.name]: e.target.value,
		}))
	}

	const handleProfileChange = (e) => {
		var file = e.target.files[0];
		if (file) {
			setSelectedFile(file);
			setFormData((previousFormData) => ({
				...previousFormData,
				profile_photo: e.target.files[0].name,
				upload_image: <img src={`${URL.createObjectURL(e.target.files[0])}`} alt='' />,
			}));
		}
	}

	const handleCheckBox = (e) => {
		setFormData((previousFormData) => ({
			...previousFormData,
			[e.target.name]: !formData[e.target.name],
		}))
	}

	const nextButtonHandler = (e) => {
		setCaptcha('');
		setFormSubmitted(true);
		if (handleValidation()) {
			setFormSubmitted(false);
			setTabCount(tabCount + 1)
		}
	}

	const handleValidation = (e) => {
		let errArray = {};
		let formIsValid = true;

		if (validateRequiredFields(formData)) {
			if (!(formData.email || '').trim()) {
				formIsValid = false;
				errArray.email = messages && messages.emptyEmail;
			}
			if (tabCount === 1 && !formData.password) {
				formIsValid = false;
				errArray.password = messages && messages.emptyPassword;
			}
			if (+tabCount === 1 && !formData.confirmPassword) {
				formIsValid = false;
				errArray.confirmPassword = messages && messages.emptyConfirmPassword;
			}
			if (+tabCount === 2 && !(formData.first_name || '').trim()) {
				formIsValid = false;
				errArray.first_name = messages && messages.firstName;
			}
			if (tabCount === 2 && !(formData.last_name || '').trim()) {
				formIsValid = false;
				errArray.last_name = messages && messages.lastName;
			}
			if (tabCount === 2 && !formData.birth_date) {
				formIsValid = false;
				errArray.birth_date = messages && messages.birthDate;
			}
			if (tabCount === 2 && !(formData.cell_phone || '').trim()) {
				formIsValid = false;
				errArray.cell_phone = messages && messages.cellPhone;
			} else if (tabCount === 2 && formData.cell_phone && !isValidPhoneNumber(`+1${formData.cell_phone}`)) {
				formIsValid = false;
				errArray.cell_phone = messages && messages.cellPhoneInvalid;
			}

			if (tabCount === 3 && !formData.disclaimer) {
				formIsValid = false;
				errArray.disclaimer = messages && messages.disclaimer;
			}
		}
		if (tabCount === 1 && formData.email && !validateEmail(formData.email)) {
			setEmailExist(false);
			errArray.email = messages && messages.invalidEmail;
			formIsValid = false;
		}
		if (formData.password && formData.password.length < 6) {
			errArray.password = messages && messages.passwordLength;
			formIsValid = false;
		}
		if (+tabCount === 1 && formData.confirmPassword && formData.confirmPassword !== formData.password) {
			formIsValid = false;
			errArray.confirmPassword = messages && messages.confirmPassword;
		}
		if (tabCount === 2 && formData.cell_phone && formData.cell_phone.replace(/\D/g, '').length < 10) {
			formIsValid = false;
			errArray.cell_phone = messages && messages.phoneLength;
		}
		if (tabCount === 2 && !isPhoneValidated) {
			formIsValid = false;
			errArray.phone_validation = messages && messages.phoneValidation;
		}
		if (tabCount === 3 && selectedFile && !selectedFile.name.match(/\.(jpg|jpeg|png|gif|svg)$/)) {
			errArray.image = messages && messages.notAllowedImg;
			formIsValid = false;
		}
		if (tabCount === 3 && selectedFile && selectedFile.name.match(/\.(jpg|jpeg|png|gif|svg)$/) && selectedFile.size > 5 * 1024 * 1024) {
			errArray.image = messages && messages.imgLimit;
			formIsValid = false;
		}
		if (emailExists) {
			formIsValid = false;
			errArray.email = messages && messages.email_taken;
		}

		if (tabCount === 3 && !captcha) {
			formIsValid = false;
			errArray.captcha = messages && messages.captchaSelect;
		}
		setErrors(errArray);
		return formIsValid;
	}

	const getPlace = (place) => {

		place?.address_components?.forEach(element => {
			if (element?.types.includes('postal_code')) {
				setFormData((prev) => ({
					...prev,
					zip: element.long_name,
				}))
			}
			if (element?.types.includes('administrative_area_level_1') && isStateList) {
				const state = stateList?.find(val => val.name.toLowerCase() === element.long_name.toLowerCase());
				setFormData((prev) => ({
					...prev,
					state_id: state?.id,
					state_name: element.long_name,
				}))
			}
			if (element?.types.includes('administrative_area_level_2')) {
				setFormData((prev) => ({
					...prev,
					city: element.long_name,
				}))
			}
		});
		if (place.addressVal) {
			setFormData((prev) => ({
				...prev,
				address: place.addressVal,
			}))
		} else {
			setFormData((prev) => ({
				...prev,
				address: place,
			}))
		}
	}

	const getSecondaryAddress = (place) => {

		if (place.addressVal) {
			setFormData((prev) => ({
				...prev,
				secondary_address: place.addressVal,
			}))
		} else {
			setFormData((prev) => ({
				...prev,
				secondary_address: place,
			}))
		}
	}

	//disclaimer
	const getDisclaimer = () => {
		setShowDisclaimer(true)
		axiosApi.get(`${config.apiUrl}/misc/disclaimer/${REGISTERED_THROUGH}/${Disclaimers.signUp_disclaimer}`)
			.then((res) => {
				setDisclaimer(res.data.data.disclaimer)
			})
	}

	return (
		<>
			<form onSubmit={handleSubmit} encType="multipart/form-data" autoComplete="off">
				<div className="login-popup">
					<div className={`model-popup-box${showDisclaimer ? ' termConditions-modal' : ''}`}>
						<div className="modal-content">
							<div className="dismiss-popup">
								<div className="close" style={{ cursor: 'pointer' }} onClick={closeSignupModal}><img src={cross} alt="cross-sign" /></div>
							</div>
							<div className="model-popup-inner">
								<div className="pop-up-logo">
									<img src={main} alt="popup-logo" />
								</div>
								{
									!showDisclaimer ? <>
										<div className="breadcrumb-block">
											<ul>
												<li className={'active'} style={{ cursor: 'pointer' }}><span className='link' onClick={() => { setTabCount(1) }}>Login Info</span></li>
												<li className={tabCount === 2 || tabCount === 3 ? 'active' : ''} style={{ cursor: 'pointer' }}><span className='link' onClick={() => {
													tabCount === 1 ? nextButtonHandler() :
														setTabCount(2);
												}}>User Info</span></li>
												<li className={tabCount === 3 ? 'active' : ''} style={{ cursor: 'pointer' }}><span className='link' onClick={() => { nextButtonHandler() }}>Leagues Info</span></li>
											</ul>
										</div>
										{tabCount === 1 && <>
											<div className="fleid-block-outer">
												<div className="fleids-block input-field-email">
													<label>Email Address <span className="required">*</span></label>
													<div className="email-check-wrapper">
														<input type="email" className="form-control" name='email' value={formData['email']} onChange={(e) => { handleFormChange(e) }} />
														{checkingEmail && <div className="spinner"><MiniLoader /></div>}
													</div>
													{(formSubmitted || errors["email"] === messages.email_taken) && <span className="error">{errors["email"]}</span>}
												</div>
												<div className="fleids-block">
													<label>Password <span className="required">*</span></label>
													<input type="password" className="form-control" name='password' value={formData['password']} onChange={(e) => { handleFormChange(e) }} />
													<ErrorInput formSubmitted={formSubmitted} error={errors['password']} />
												</div>
												<div className="fleids-block">
													<label>Confirm Password <span className="required">*</span></label>
													<input type="password" className="form-control" name='confirmPassword' value={formData['confirmPassword']} onChange={(e) => { handleFormChange(e) }} />
													<ErrorInput formSubmitted={formSubmitted} error={errors['confirmPassword']} />
												</div>
											</div>
											<div className="btn-block action-modal">
												<button type="button" onClick={() => { nextButtonHandler() }} className="btn btn-fluid btn-primary">Next</button>
											</div>
											<div className="already-login">
												<span>Already have an account? <Link to="#" onMouseDown={() => { closeSignupModal(); props.openLogin(); }}>Login</Link></span>
											</div>
										</>}

										{tabCount === 2 && <>
											<div className="fleid-block-outer">
												<div className="cs-row">
													<div className="c-8">
														<div className="fleids-block">
															<label>First Name <span className="required">*</span></label>
															<input type="text" className="form-control" name='first_name' value={formData['first_name']} onChange={(e) => { handleFormChange(e) }} />
															<ErrorInput formSubmitted={formSubmitted} error={errors['first_name']} />
														</div>
													</div>
													<div className="c-4">
														<div className="fleids-block">
															<label>Middle Initial</label>
															<input type="text" className="form-control" name='middle_name' value={formData['middle_name']} onChange={(e) => { handleFormChange(e) }} />
														</div>
													</div>
													<div className="c-8">
														<div className="fleids-block">
															<label>Last Name <span className="required">*</span></label>
															<input type="text" className="form-control" name='last_name' value={formData['last_name']} onChange={(e) => { handleFormChange(e) }} />
															<ErrorInput formSubmitted={formSubmitted} error={errors['last_name']} />
														</div>
													</div>
													<div className="c-4">
														<div className="fleids-block">
															<label>Gender <span className="required">*</span></label>
															<div className="custom-select">
																<select className="form-control" name='gender' value={formData['gender']} onChange={(e) => { handleFormChange(e) }}>
																	<option value="male">Male</option>
																	<option value="female">Female</option>
																</select>
															</div>
														</div>
													</div>
													<div className="c-12">
														<div className="fleids-block">
															<label>Birthday <span className="required">*</span></label>
															<DatePicker className="form-control" maxDate={new Date()} value={formData['birth_date']} onChange={(value) => {
																handleFormChange({
																	target: {
																		value,
																		name: 'birth_date',
																	}
																})
															}} />
															<ErrorInput formSubmitted={formSubmitted} error={errors['birth_date']} />
														</div>
													</div>
													<div className="c-12">
														<div className="fleids-block">
															<label>Cell Phone <span className="required">*</span></label>
															<InputMask className="form-control" mask={phoneNumberMask} name="cell_phone" value={formData['cell_phone']} onChange={(e) => { handleFormChange(e) }} ></InputMask>
															<ErrorInput formSubmitted={formSubmitted} error={errors['cell_phone']} />
														</div>
													</div>
													<div className="c-12">
														<div className="fleids-block">
															<label>Address</label>
															{isStateList && (stateList.length ? 
															<input type="text" className="form-control" name='address' value={formData['address']} onChange={(e) => { handleFormChange(e) }} />
																:
																<input type="text" className="form-control" name='address' value={formData['address']} onChange={(e) => { handleFormChange(e) }} />
																)
															}
														</div>
													</div>
													<div className="c-12">
														<div className="fleids-block">
															<label>Secondary Address</label>
															<input type="text" className="form-control" name='secondary_address' value={formData['secondary_address']} onChange={(e) => { handleFormChange(e) }} />
														</div>
													</div>
													<div className="c-4 half-blk">
														<div className="fleids-block">
															<label>City</label>
															<input type="text" className="form-control" name='city' value={formData['city']} onChange={(e) => { handleFormChange(e) }} />
														</div>
													</div>
													<div className="c-4 half-blk">
														<div className="fleids-block">
															<CustomSelect
																required={false}
																label={'State'}
																data={stateList}
																placeholder={formData.state_name ? formData.state_name : 'Select State'}
																onSelect={(e) => e && handleFormChange({
																	target: {
																		name: 'state_id',
																		value: formData.state_id ? formData.state_id : e.id,
																	}
																})}
																selector={'name'}
															/>
														</div>
													</div>
													<div className="c-4 half-blk">
														<div className="fleids-block">
															<label>Zip</label>
															<input type="text" className="form-control" name='zip' value={formData['zip']} onChange={(e) => { handleFormChange(e) }} />
														</div>
													</div>
												</div>
											</div>
											<div className="feature-check-block">
												<div className="checkbox-control">
													<label className="custom-checkbox">
														<input type="checkbox" name='phone_validation' onChange={(e) => setIsPhonevalidated(e.target.checked)} />
														<span>I have validated my cell phone number and it is correct.</span>
													</label>
													<ErrorInput formSubmitted={formSubmitted} error={errors['phone_validation']} />
												</div>
											</div>
											<div className="login-filed-btn user-info-btn">
												<button type="button" onClick={() => { setTabCount(tabCount - 1) }} className=" btn btn-gray">Back</button>
												<button type="button" onClick={() => { nextButtonHandler() }} className=" btn btn-primary">Next</button>
											</div>
										</>}
										{tabCount === 3 && <>
											<div className="fleid-block-outer">
												<div className="cs-row">
													<div className="c-8">
														<div className="fleids-block">
															<label>Shirt Size</label>
															<div className="custom-select">
																<select className="form-control" name='shirt_size' value={formData['shirt_size']} onChange={(e) => { handleFormChange(e) }}>
																	<option value='null'>Select Shirt Size</option>
																	<option value='S'>S</option>
																	<option value='M'>M</option>
																	<option value='L'>L</option>
																	<option value='XL'>XL</option>
																	<option value='XXL'>XXL</option>
																</select>
															</div>
														</div>
													</div>
													{/* <div className="c-4">
														<div className="fleids-block">
															<label>Jersey #</label>
															<input type="text" className="form-control" name='jersey' value={formData['jersey']} onChange={(e) => { handleFormChange(e) }} />
															<ErrorInput formSubmitted={formSubmitted} error={errors['jersey']} />
														</div>
													</div> */}
													<div className="c-12">
														<div className="fleids-block">
															<label>Profile Photo</label>
															<div className="fild-upload-outer">
																<div className="upload-img">
																	<input id='profile_pic' type="file" name='profile_photo' onChange={(e) => { handleProfileChange(e) }} accept="image/*" />
																	<span className="error">{formSubmitted && errors["email"]}</span>
																	{!formData.upload_image ? <div className="attachment-blk"><figure>
																		{img}
																	</figure>
																		<p>Upload A Photo</p>
																	</div>
																		: <div className="uploaded-Imgblk" onMouseEnter={() => setShowChangeImage(true)} onMouseLeave={() => setShowChangeImage(false)}>
																			{(showChangeImage || !upload) &&
																				<div className="hover-blk">
																					<label htmlFor='profile_pic'>
																						<img src={upload} alt="upload" />
																					</label>
																				</div>}
																			{formData.upload_image}</div>}
																	<ErrorInput formSubmitted={formSubmitted} error={errors['profile_photo']} />
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="feature-check-block">
													<label className="custom-checkbox">
														<input type="checkbox" name='text_notification' checked={formData['text_notification']} onChange={(e) => { handleCheckBox(e) }} />
														<span>Receive text messages alerts, updates and announcements</span>
													</label>
													<label className="custom-checkbox">
														<input type="checkbox" name='email_notification' checked={formData['email_notification']} onChange={(e) => { handleCheckBox(e) }} />
														<span>Receive email news, updates and announcements</span>
													</label>
													<div className="checkbox-control">
														<label className="custom-checkbox">
															<input type="checkbox" name='disclaimer' checked={formData['disclaimer']} onChange={(e) => { handleCheckBox(e) }} />
															<span>I have read and fully understand the <span onClick={() => getDisclaimer()} target="_blank" rel="noreferrer noopener"><span className='link'>Player Disclaimer</span></span></span>
														</label>
														<ErrorInput formSubmitted={formSubmitted} error={errors['disclaimer']} />
													</div>
												</div>
												<div className="fleids-block">
													<ReCAPTCHA sitekey={captchaSitekey} onChange={onChange} />
													<ErrorInput formSubmitted={formSubmitted} error={errors['captcha']} />
												</div>
											</div>
											<div className="login-filed-btn user-info-btn">
												<button type="button" onClick={() => { setTabCount(tabCount - 1) }} className=" btn btn-gray">Back</button>
												<button className=" btn btn-secondary" type='submit'>Create Account</button>
											</div>
										</>}
									</>
										: <>
											<div className="termConditions-blk">
												<h3>Terms & conditions</h3>
												<SimpleBarReact className="scroll-wrapper">
													<div dangerouslySetInnerHTML={
														{
															__html: disclaimer
														}
													}></div>
												</SimpleBarReact>
												<button className="btn-primary" type="button" onClick={(e) => { e.preventDefault(); setCaptcha(''); setShowDisclaimer(false); }}>
													Back
												</button>
											</div>
										</>
								}
							</div >
						</div >
					</div >
				</div >
			</form >
		</>
	);
}