import React, { useEffect, useState } from 'react';
import axiosApi from '../../../core/interceptor/interceptor';
import { config, HOSTED_BY } from '../../../config';
import InfiniteScroll from 'react-infinite-scroll-component';
import backArrow from "../../../assets/images/sidearrow.svg";
import "./Leagues.css";
import { useParams, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import moment from 'moment';
import debounce from "lodash.debounce";
import { toTitleCase } from '../../../utils/Utils';
import { useSelector } from 'react-redux';
import searchIcon from '../../../assets/images/search-icon.png';

function Leagues(props) {
  const [filter, setFilter] = useState({
    status: 'sign_up',
    limit: 20,
    page: 1,
    search: ''
  });
  const [leagues, setLeagues] = useState([]);
  const [sportOverview, setSportOverview] = useState({});
  const [count, setCount] = useState(0);
  const id = useParams().id;
  const loader = useSelector((state) => state.loader.value);

  useEffect(() => {
    if (id) {
      getLeagues(filter, false);
    }
    //eslint-disable-next-line
  }, [filter.page, filter.status]);

  useEffect(() => {
    axiosApi.get(`${config.apiUrl}/leagues/overview/${id}`).then((response) => {
      if (response && response.data && response.data.data) {
        setSportOverview(response.data.data);
      }
    }).catch(error => {
      console.error(error);
    });
    //eslint-disable-next-line
  }, []);

  const getLeagues = (params, search) => {
    axiosApi.get(`${config.apiUrl}/leagues/all-leagues/${id}`, { params }).then((response) => {
      if (response && response.data && response.data.data && response.data.data.rows) {
        setCount(response.data.data?.count);
        if (search) {
          setLeagues(response?.data?.data?.rows);
        } else {
          setLeagues((prev) => ([...prev, ...response.data.data?.rows]));
        }
      }
    }).catch(error => {
      console.error(error);
    });
  }

  const changeTab = (status) => {
    setFilter((prev) => {
      if (prev.status !== status) {
        setLeagues([]);
      }
      return {
        ...prev,
        status,
        page: 1,
      }
    });
  }
  const searchLeague = (e) => {
    filter.page = 1;
    filter.search = e.target.value;
    getLeagues(filter, true);
  }
  const debouncedSearch = debounce(searchLeague, 800);

  const handleNextRequest = () => {
    if (leagues.length < count) {
      setFilter(prev => ({
        ...prev,
        'page': prev.page + 1,
      }))
    }
  }
  const clearSearchBar = () => {
    document.getElementById('searchBox').value = '';
  }
  return (
    <>
      <section className="leaguesRegistration-view">
        <div className="sports-btn">
          <Link to="/league">
            <button className="btn-primary">
              <img src={backArrow} alt="" />Back to Sports
            </button>
          </Link>
        </div>
        {
          <div className="card-wrapper" >
            <h3>{toTitleCase(sportOverview?.sport)}</h3>
            <p>Select a league to view more info</p>
            <div className="league-tabs-search tab-group-search">
              <ul>
                <li className={filter.status === 'sign_up' ? 'active ' : ''}>
                  <span onClick={() => { changeTab('sign_up'); filter.search = ''; clearSearchBar()}}>
                    Available Leagues ({sportOverview?.sign_up || 0})
                  </span>
                </li>
                <li className={filter.status === 'active' ? 'active' : ''}>
                  <span onClick={() => { changeTab('active'); filter.search = '';clearSearchBar()}}>
                    Active Leagues ({sportOverview?.active || 0})
                  </span>
                </li>
                <li className={filter.status === 'complete' ? 'active' : ''}>
                  <span onClick={() => { changeTab('complete'); filter.search = '';clearSearchBar()}}>
                    League History
                  </span>
                </li>
              </ul>
              <div className="ns-league-search">
                <div className="fleid-block-outer">
                  <div className="fleids-block input-field-search">
                    <div className="search-input-box">
                      <input id='searchBox' type={'text'} autoComplete="off" className="form-control" placeholder='Search...' onChange={(e) => { debouncedSearch(e); }} />
                      <button disabled={true} className="search-btn">
                        <img src={searchIcon} alt="" />
                      </button></div>
                  </div>
                </div>
              </div>
            </div>
            <div id="scrollableDivLeagues" style={{ height: "calc(88vh - 3px)", overflow: "auto" }} className="cardBlk-wrapper">
              {Array.isArray(leagues) && leagues.length ? <InfiniteScroll
                dataLength={leagues.length}
                hasMore={true}
                next={() => handleNextRequest()}
                scrollableTarget="scrollableDivLeagues"
                endMessage={
                  <p>End</p>
                }>
                {leagues.length &&
                  leagues.map(league => {
                    return <div className="card-inner">
                      <h3>{toTitleCase(league?.name)}</h3>
                      <ul>
                        <li>
                          <span className="label">Sport:</span>
                          <span className="value">{toTitleCase(league?.sport?.title)}</span>
                        </li>
                        <li>
                          <span className="label">Level of play:</span>
                          <span className="value">{toTitleCase(league?.play_level)}</span>
                        </li>
                        {league.status === 'sign_up' &&
                          <li>
                            <span className="label">Registration deadline:</span>
                            <span className="value">{league?.reg_deadline ? moment(league?.reg_deadline, 'YYYY-MM-DD').format('MM/DD/YYYY') : 'N/A'}</span>
                          </li>
                        }
                        {league.status === 'sign_up' &&
                          <li>
                            <span className="label">Start Date:</span>
                            <span className="value">{league?.start_date ? moment(league?.start_date, 'YYYY-MM-DD').format('MM/DD/YYYY') : 'N/A'}</span>
                          </li>
                        }
                        <li>
                          <span className="label">League Length:</span>
                          <span className="value">{league?.no_of_weeks || 0} Week{league?.no_of_weeks > 1 ? 's' : ''}</span>
                        </li>
                        <li>
                          <span className="label">Hosted by:</span>
                          <span className="value">{league?.hosted_by === HOSTED_BY ? 'Cleveland' : 'Mulberry'}</span>
                        </li>
                        {
                          (() => {
                            if (filter?.status === 'sign_up') {
                              const remaining_spots = league?.team_size - league?.teams;
                              if (remaining_spots <= 5) {
                                return <>
                                  <li>
                                    <span className="label">Available spots (Booked/Total):</span>
                                    <span className="value">{`${(() => {
                                      if (filter?.status === 'sign_up') {
                                        const remaining_spots = league?.team_size - league?.teams;
                                        if (remaining_spots <= 0) {
                                          return `${league?.team_size}/${league?.team_size} - No spot available.`;
                                        }
                                        return `${league?.teams}/${league?.team_size}`;
                                      }
                                      return league?.teams;
                                    })()}`}</span>
                                    {remaining_spots <= 0 && <div className="mt-5 warning">If you register, you'll be added to the wait list.</div>}
                                  </li>
                                </>
                              }
                              return <></>;
                            }
                            return <>
                              <li>
                                <span className="label">Total teams played:</span>
                                <span className="value">{`${league?.teams || 0}`}</span>
                              </li>
                            </>
                          })()
                        }
                      </ul>
                      <Link to={`/league/league-details/${league.id}`}>
                        <button className="btn btn-primary">
                          More Info
                        </button>
                      </Link>
                    </div>

                  })
                }
              </InfiniteScroll>
                :
                !loader && <div className="noRecord-found">
                  <p>No Record Found</p>
                </div>
              }
            </div>
          </div>
        }
      </section>
    </>
  )
}

export default withRouter(Leagues);