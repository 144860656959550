import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import arrowRight from '../../../assets/icons/arrowRight.svg';
import { config } from '../../../config';
import axiosApi from '../../../core/interceptor/interceptor';

export default function LeagueUpcomingHistory({ match, isHistory }) {
    const [upcomingMatches, setUpcomingMatches] = useState([]);
    const [leagueHistory, setLeagueHistory] = useState([]);
    const [teams, setTeams] = useState([]);
    useEffect(() => {
        axiosApi.get(`${config.apiUrl}/team/Player`).then((res) => {
          setTeams(res.data.data);
        });
    }, []);
    useEffect(() => {
        if (Array.isArray(teams) && teams.length) {
            const matches = [];
            const comingSoon = [];
            let tmp_upcoming = [];
            const leageu_his = [];
            if (Array.isArray(teams)) {
                teams.forEach((team, index) => {
                    if (!isHistory) {
                        if (
                            team?.league?.status === "active" &&
                            Array.isArray(team.league?.league_schedules)
                        ) {
                            team.league.league_schedules.forEach((schedule, cc) => {
                                if (!schedule.winner_id && schedule?.field) {
                                    if (schedule?.field && schedule?.field?.id) {
                                        tmp_upcoming.push({
                                            key: cc,
                                            name: team.league.name,
                                            leagueId: team.league.id,
                                            team1: team.name,
                                            team2: schedule?.team1?.id === team.id
                                                ? schedule?.team2?.name
                                                : schedule?.team1?.name,
                                            location: `${schedule?.field?.location?.title} - ${schedule?.field?.title}`,
                                            time: schedule.time,
                                            date: schedule.date,
                                            notification: team?.league?.sport?.notification,
                                        });
                                    }
                                }
                            });
                        }

                        if (
                            team?.league?.status === "active" &&
                            Array.isArray(team.league?.league_playoffs)
                        ) {
                            team.league.league_playoffs.forEach((schedule, cc) => {
                                if (!schedule.winner_id && schedule?.field) {
                                    if (schedule?.field && schedule?.field?.id) {
                                        tmp_upcoming.push({
                                            key: cc,
                                            name: team.league.name,
                                            leagueId: team.league.id,
                                            team1: team.name,
                                            team2: schedule?.team1?.id === team.id
                                                ? schedule?.team2?.name
                                                : schedule?.team1?.name,
                                            location: `${schedule?.field?.location?.title} - ${schedule?.field?.title}`,
                                            time: schedule.time,
                                            date: schedule.date,
                                            notification: team?.league?.sport?.notification,
                                            playoff: true,
                                        });
                                    }
                                }
                            });
                        }
                        const currentDate = moment();
                        const startDate = moment(team.league.start_date);
                        if (team?.status === 'active' && ['sign_up', 'need_schedule'].includes(team?.league?.status) && !team.league?.league_schedules.length && startDate.isSameOrAfter(currentDate, 'day')) {
                            comingSoon.push(
                                <div className="league-card um-cards" key={`upcoming-matches-${index}-coming-soon`}>
                                    <div className="league-card-header">
                                        <h3>{team?.league?.name}</h3>
                                    </div>
                                    <div className="league-card-body">
                                    <div className="league-card-items">
                                        <div className="league-card-item">
                                            <p className="legue-detail-title">Team:</p>
                                            <p className="league-detail-info normal">{team.name}</p>
                                        </div>
                                        <div className="league-card-item">
                                            <p className="legue-detail-title">Members:</p>
                                            <p className="league-detail-info green">{team.players.length}</p>
                                        </div>
                                        <div className="league-card-item">
                                            <p className="legue-detail-title">League length:</p>
                                            <p className="league-detail-info normal">{team.league.no_of_weeks}</p>
                                        </div>
                                        <div className="league-card-item">
                                            <p className="legue-detail-title">Start Date:</p>
                                            <p className="league-detail-info">{moment(team.league.start_date).format("MMMM Do, YYYY")}</p>
                                        </div>
                                        <div className="league-card-item">
                                            <p className="legue-detail-title">When</p>
                                            <p className="league-detail-info green">
                                                Schedule Coming Soon
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                    <div className="league-card-actions">
                                        <Link className="btn btn-dark-gray po-btn" to={{
                                            pathname: `${match.url}/league-details/${team?.league?.id}`,
                                            state: {
                                                from: 'account',
                                            }
                                        }}>
                                            League Info
                                            <img src={arrowRight} alt="" />
                                        </Link>
                                    </div>
                                </div>
                            )
                        }
                    }

                    if (isHistory && team?.league?.status === 'complete') {
                        leageu_his.push(
                            <div className="league-card" key={`upcoming-matches-${index}`}>
                                <div className="league-card-header">
                                    <h3>{team.league.name}</h3>
                                </div>
                                <div className="league-card-body border-bottom-unset">
                                    <div className="league-card-items">
                                        <div className="league-card-item">
                                            <p className="legue-detail-title">Team:</p>
                                            <p className="league-detail-info green">{team.name}</p>
                                        </div>
                                        <div className="league-card-item">
                                            <p className="legue-detail-title">Members:</p>
                                            <p className="league-detail-info green">{team.players.length}</p>
                                        </div>
                                        <div className="league-card-item">
                                            <p className="legue-detail-title">League length:</p>
                                            <p className="league-detail-info normal">{team.league.no_of_weeks}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="league-card-actions">
                                    <Link className="btn btn-dark-gray" to={{
                                        pathname: `${match.url}/league-details/${team.league.id}`,
                                        state: {
                                            from: 'history',
                                        }
                                    }}>
                                        League Info
                                        <img src={arrowRight} alt="" />
                                    </Link>
                                </div>
                            </div>
                        )
                    }
                });
            }
            tmp_upcoming = tmp_upcoming.sort((a, b) => {
                return a.date && a.time && moment(`${a.date} ${a.time}`, "YYYY-MM-DD HH:mm:ss")[isHistory ? 'isAfter' : 'isBefore'](
                    moment(`${b.date} ${b.time}`, "YYYY-MM-DD HH:mm:ss")
                )
                    ? -1
                    : 1;
            });
            tmp_upcoming.forEach((up, index) => {
                matches.push(
                    <div className={`league-card um-cards league-um-cards-rs ${up && up.playoff ? 'playoff-match' : ''}`} key={`upcoming-matches-${index}-${up.key}`}>
                        <div className="league-card-header">
                            {up && up.playoff && <div className="play-off">
                                Playoff Match
                            </div>}
                            <h3>{up?.name}</h3>
                        </div>
                        <div className="league-card-body border-bottom-unset">
                            <div className="league-card-items">
                                <div className="league-card-item card-vs-item">
                                    <div className="card-team-item">{up?.team1 || 'TBD'}</div>
                                    <div className="vs-box">
                                        <p>VS</p>
                                    </div>
                                    <div className="card-team-item">{up?.team2 || 'TBD'}</div>
                                </div>
                                <div className="league-card-item">
                                    <p className="legue-detail-title">Location:</p>
                                    <p className="league-detail-info green">{up.location}</p>
                                </div>
                                <div className="league-card-item">
                                    <p className="legue-detail-title">When</p>
                                    <p className="league-detail-info green">
                                        {up.date && up.time ? `${moment(up.date).format("MMMM Do")} at ${moment(up.time, "HH:mm:ss").format("h:mm A")}` : `${up.playoff ? 'Playoff TBD' : 'TBD'}`}
                                    </p>
                                </div>
                                <div className="league-card-item">
                                    <p className="legue-detail-title">Note</p>
                                    <p className="league-detail-info normal">
                                        {up?.notification?.replace(/(<([^>]+)>)/gi, "")?.trim() ? <span
                                            dangerouslySetInnerHTML={{ __html: up?.notification }}
                                        ></span> : 'N/A'}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="league-card-actions">
                            <Link className="btn btn-dark-gray po-btn" to={{
                                pathname: `${match.url}/league-details/${up.leagueId}`,
                                state: {
                                    from: 'account',
                                }
                            }}>
                                League Info
                                <img src={arrowRight} alt="" />
                            </Link>
                            {
                                up && up.playoff ?
                                    <Link className="btn po-btn" to={{
                                        pathname: `${match.url}/playoff-matches/${up.leagueId}`,
                                        state: {
                                            from: 'account',
                                            data: up.name
                                        }
                                    }}>
                                        Playoff Bracket
                                        <img src={arrowRight} alt="" />
                                    </Link>
                                    :
                                    ''
                            }
                        </div>
                    </div>
                )
            }
            );
            const temp = [...comingSoon, ...matches];
            setUpcomingMatches(temp);
            setLeagueHistory(leageu_his);

        }
        // eslint-disable-next-line
    }, [teams]);

    return <div className="main-side-container">
        <div className="main-top-headings leagues-list-head top-head-1">
            <div className="main-top-tabs"><h3>{isHistory ? 'LEAGUE HISTORY' : 'UPCOMING MATCHES'}</h3></div>
        </div>


        <div className={`main-side-body ${upcomingMatches && upcomingMatches.length ? '' : 'nr-found'}`}>
            {
                isHistory ?
                    <>
                        {leagueHistory?.length ? <div className="league-cards">{leagueHistory}</div> : ''}
                        {!leagueHistory?.length ? <div className="noRecord-found">
                            <p>No Record Found</p>
                        </div> : ''}
                    </>
                    :

                    <>
                        {upcomingMatches?.length ? <div className="league-cards">{upcomingMatches}</div> : ''}
                        {!upcomingMatches?.length ? <div className="noRecord-found">
                            <p>No Record Found</p>
                        </div> : ''}
                    </>
            }
        </div>
    </div>
}