import Popup from "reactjs-popup";
import cross from '../assets/icons/cross-icon.svg';

export default function WarningDialogue(props) {
  return (
    <>
      <Popup closeOnDocumentClick={props.backdrop || false} onClose={() => props.onClose && props.onClose(false)} open={props.isOpen}>
        <div className="login-popup modal warning-popup">
          <div className="model-popup-box">
            <div className="modal-content">
              <div className="dismiss-popup">
                {props && props?.type !== 'session' && <div className="close" onClick={() => props.onClose && props.onClose(false)}><img src={cross} alt="cross-sign" /></div>}
              </div>
              <div className="model-popup-inner">
                <div className="modal-header">
                  <h2>{props.header}</h2>
                </div>
                <div className="modal-body">
                  <div className="warning-message-field">
                    <p>{props.description}</p>
                  </div>
                </div>
                {
                  props && props.type && props.type === 'session' ?
                    <div className="modal-footer d-flex session-out">
                      <button className="btn-primary" onClick={() => props.action && props.action(props.data ? props.data : null)}>OK</button>
                    </div>
                    :
                    <div className="modal-footer d-flex">
                      <button type="button" className="btn-secondary btn-dark-gray" onClick={() => props.onClose && props.onClose(false)}>Cancel</button>
                      <button className="btn-primary" onClick={() => props.action && props.action(props.data ? props.data : null)}>Confirm</button>
                    </div>
                }
              </div>
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
}