import React from 'react';
import './About.css';
import img from '../../assets/images/about-img.jpg';
import { Link } from 'react-router-dom';

export default function About() {
  return (
    <>
      <main className="wrapper-main">
        <section className="about-as-block">
          <div className="cs-container">
            <div className="about-us-banner">
              <img src={img} alt="" />
            </div>
            <div className="about-us-content">
              <h2>About Us</h2>
              <p>Cleveland Plays is a Co-Ed Sport and Social Club geared toward young professionals throughout the Greater Cleveland area. We offer Co-Ed Sports Leagues in football, volleyball, soccer, dodgeball, softball, kickball and more. We also offer euchre leagues and bar games leagues. Cleveland Plays isn't just about sports. With post game gatherings and happy hours at many of Cleveland's most popular night spots, we’re also a great way to make new friends!</p>
              <p>We accept teams for registration and will assist individuals in finding a team on which to play. If you haven’t already, please take a few minutes and check out our website. In our <Link to='/league'>Available Leagues</Link> section you’ll find the sports leagues we're currently running. In the <Link to='/classified'>Classifieds</Link> section you’ll be able to place an “ad” to find a team or a player. go to our <Link to={{ pathname: '/league', state: { from: 'indv'} }}>Individual Registration</Link> section and guarantee yourself a spot on a roster.</p>
              <p>If you have questions please go to our Contact Us section.</p>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}